import { Box } from '@mui/material'
import { Button } from '../../element/Button'
import styled from 'styled-components'

const ItemSearchBar = ({ placeholder, onClick, keyword, setKeyword }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onClick(keyword)
    }
  }
  return (
    <Box display="flex" alignItems="center" gap={3}>
      <Box>
        <p>검색 :</p>
      </Box>

      <Box>
        <StyledInput
          placeholder={placeholder}
          style={{ width: '400px', height: '40px' }}
          onKeyDown={handleKeyDown}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </Box>

      <Button
        width={80}
        height={40}
        borderRadius="10px"
        onClick={() => {
          onClick(keyword)
        }}
      >
        검색
      </Button>
    </Box>
  )
}

export default ItemSearchBar

const StyledInput = styled.input`
  width: 400px;
  height: 40px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  outline: none;
  padding-left: 10px;
  &:focus {
    border: 1px solid blue;
  }
`
