import { instance } from './instance'

export const getPosts = async ({
  page,
  keyword,
  isBoosted,
  brand,
  category,
  isDiscount,
}) => {
  try {
    let query = page && page > 0 ? `page=${page}` : ''
    keyword && (query += `&keyword=${keyword}`)
    typeof isBoosted === 'boolean' && (query += `&isBoosted=${isBoosted}`)
    brand && (query += `&sellerName=${brand}`)
    category?.id && (query += `&categoryId=${category.id}`)
    isDiscount && (query += `&isDiscount=${isDiscount}`)

    const { data } = await instance.get(`/api/post/page/admin?${query}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const hidePosts = async (postIds, isHide) => {
  try {
    let path = postIds.reduce((acc, id) => acc + `postIds=${id}&`, '')
    await instance.delete(`/api/post/hide?${path}isHide=${isHide}`, {
      token: true,
    })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const boostPosts = async (postIds, isBoosted) => {
  try {
    await instance.post(
      `/api/post/boost`,
      {
        postIds,
        isBoosted,
      },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const removePosts = async (postIds) => {
  try {
    let path = postIds.reduce((acc, id) => acc + `postIds=${id}&`, '')
    await instance.delete(`/api/post/admin?${path}`, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const addPost = async (form) => {
  const body = { ...form }
  try {
    await instance.post(`/api/post`, body, { token: true })
  } catch (error) {
    throw error
  }
}

export const getPost = async (id) => {
  try {
    const { data } = await instance.get(`/api/post/detail/${id}`)
    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}

export const editPost = async (id, body) => {
  try {
    await instance.patch(
      `/api/post/admin`,
      {
        postId: id,
        ...body,
      },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

export const getOpponentTag = async () => {
  try {
    const { data } = await instance.get(`/api/post/opponent-tag`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}

export const getExhibitionPosts = async ({
  page,
  keyword,
  isBoosted,
  brand,
  category,
  isDiscount,
  productIds,
}) => {
  try {
    // let query = page && page > 0 ? `page=${page}` : ''
    // keyword && (query += `&keyword=${keyword}`)
    // typeof isBoosted === 'boolean' && (query += `&isBoosted=${isBoosted}`)
    // brand && (query += `&sellerName=${brand}`)
    // category?.id && (query += `&categoryId=${category.id}`)
    // isDiscount && (query += `&isDiscount=${isDiscount}`)
    const query = new URLSearchParams()
    if (page > 0) query.append('page', page)
    if (keyword) query.append('keyword', keyword)
    if (brand) query.append('sellerName', brand)
    if (category?.id !== null) query.append('categoryId', category.id)
    if (isDiscount) query.append('isDiscount', isDiscount)

    const { data } = await instance.post(
      `/api/post/page/admin/promotion?${query}`,
      { productIds },
      { token: true }
    )
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}
