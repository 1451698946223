export const TYPE = [
  { name: 'Notice', value: 'NOTICE' },
  { name: 'Returns', value: 'RETURNS' },
  { name: 'Refunds', value: 'REFUNDS' },
  { name: 'Delivery', value: 'DELIVERY' },
  { name: 'Orders', value: 'ORDERS' },
  { name: 'Payment', value: 'PAYMENT' },
  { name: 'Pricing', value: 'PRICING' },
  { name: 'Account', value: 'ACCOUNT' },
  { name: 'Security', value: 'SECURITY' },
  { name: 'Others', value: 'OTHERS' },
]
