import { Divider, Grid, SvgIcon } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import { useState } from 'react'

import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RejectModal from '../../components/RejectModal'
import SuccessPopup from '../../components/SuccessPopup'
import Navigation from '../../components/common/navigation'
import { PreviewSellerModal } from '../../components/modal/modal'
import SellerTable from '../../components/seller/seller-table'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import { Text } from '../../element/Text'
import DefaultLayout from '../../global/DefaultLayout'
import useSelectModal from '../../hooks/common/modal/useSelectModal'
import useSellerList from '../../hooks/user/useSellerList'
import { MyFormControlLabel } from '../../components/common/MyFormControlLabel'

const SELLER_GRADE_1 = [
  { name: '전체', value: '' },
  { name: 'VIB', value: 'VIB' },
  { name: 'IB', value: 'IB' },
  { name: 'SPECIAL', value: 'SPECIAL' },
  { name: 'SPECIAL-1', value: 'SPECIAL-1' },
  { name: 'BASIC', value: 'BASIC' },
]

const SELLER_GRADE_2 = [
  { name: 'S', value: 'S' },
  { name: 'A', value: 'A' },
  { name: 'B', value: 'B' },
  { name: 'C', value: 'C' },
  { name: 'D', value: 'D' },
  { name: 'E', value: 'E' },
]

export const SELLER_GRADE = [...SELLER_GRADE_1, ...SELLER_GRADE_2]

const APPROVE_STATUS = [
  { name: '전체', value: '' },
  { name: '승인', value: 'APPROVED' },
  { name: '거절', value: 'REJECT' },
  { name: '미승인', value: 'WAITING' },
]

const SellerListPage = () => {
  const [keyword, setKeyword] = useState('')
  const [grade, setGrade] = useState('')
  const [approveStatus, setApproveStatus] = useState('')

  const {
    approve,
    checked,
    clickSearchBtn,
    count,
    sellers,
    setSellers,
    setChecked,
    clickHandler,
    page,
    setPage,
    preview,
    id,
    setPreview,
  } = useSellerList(keyword, approveStatus, grade)

  const [onSuccess, setSuccess] = useState(false)

  const {
    modal: ApproveModal,
    openModal: openApproveModal,
    closeModal: closeApproveModal,
  } = useSelectModal({
    mainTxt: '승인하시겠습니까?',
    leftTxt: '승인',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: () => {
      closeApproveModal()
      approve('APPROVED', checked)
    },
  })

  const [onRejectModal, setRejectModal] = useState(false)

  const handleOpenReject = () => setRejectModal(true)
  const handleCloseReject = () => setRejectModal(false)

  const clickApproveBtn = (checked) => {
    if (checked.length === 0) {
      return
    }

    openApproveModal()
  }

  const clickRejectModalBtn = (reason) => {
    approve('REJECT', checked, reason)
    handleCloseReject()
  }

  return (
    <DefaultLayout isLoggedIn>
      <RejectModal
        open={onRejectModal}
        handleClose={handleCloseReject}
        clickModalBtn={clickRejectModalBtn}
      />

      <ApproveModal />
      <SuccessPopup open={onSuccess} setOpen={setSuccess} />

      <Box sx={boxStyle}>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={1.6}>
            <Text color="#707070" bold>
              셀러 검색
            </Text>
          </Grid>

          <Grid item xs={10}>
            <InputTextField
              width="300px"
              label="아이디 또는 닉네임 입력"
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
            />
          </Grid>

          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <Text color="#707070" bold>
              승인 여부
            </Text>
          </Grid>

          <Grid item xs={10}>
            <RadioGroup row defaultValue="all">
              {APPROVE_STATUS.map(({ name, value }, idx) => (
                <MyFormControlLabel
                  key={idx}
                  value={value}
                  control={
                    <Radio
                      onClick={() => {
                        setApproveStatus(value)
                      }}
                      size="small"
                      value={value}
                      checked={value === approveStatus}
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                  }
                  label={name}
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <Text color="#707070" bold>
              셀러 등급
            </Text>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup row defaultValue="all">
              {SELLER_GRADE_1.map(({ name, value }, idx) => (
                <Box key={idx}>
                  <MyFormControlLabel
                    value={value}
                    control={
                      <Radio
                        onClick={() => {
                          setGrade(value)
                        }}
                        size="small"
                        value={value}
                        checked={value === grade}
                        sx={{
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                    label={name}
                  />
                </Box>
              ))}
            </RadioGroup>

            <RadioGroup row>
              {SELLER_GRADE_2.map(({ name, value }, idx) => (
                <Box key={idx}>
                  <MyFormControlLabel
                    value={value}
                    control={
                      <Radio
                        onClick={() => {
                          setGrade(value)
                        }}
                        size="small"
                        value={value}
                        checked={value === grade}
                        sx={{
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                    label={name}
                  />
                </Box>
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={1.6}></Grid>
          <Grid item xs={10}>
            <Button
              size="large"
              height="50px"
              borderRadius={2}
              onClick={clickSearchBtn}
            >
              <SvgIcon component={SearchIcon} inheritViewBox />
              <Text size="16px" color="white" margin="0 0 0 10px" bold>
                유저 검색
              </Text>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={boxStyle}>
        <Box display="flex">
          <Text size="18px" bold>
            전체 셀러 : {count.all} 명
          </Text>

          <Box width="30px" />

          <Text size="18px" bold>
            승인 셀러 : {count.all - count.reject - count.waiting} 명
          </Text>

          <Box width="30px" />

          <Text size="18px" bold color="red">
            미승인 셀러 : {count.waiting} 명
          </Text>

          <Box width="30px" />

          <Text size="18px" bold>
            거절 셀러 : {count.reject} 명
          </Text>
        </Box>

        <Divider sx={{ height: 16 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
          <Button
            bg="#bacaff"
            color="#003bff"
            bold
            borderRadius={2}
            height="40px"
            onClick={() => {
              clickApproveBtn(checked)
            }}
          >
            <p>선택 승인</p>
          </Button>

          <Box width="10px" />

          <Button
            variant="contained"
            bg="#fff0f0"
            color="#ff0000"
            bold
            borderRadius={2}
            height="44px"
            onClick={() => {
              if (checked.length === 0) {
                return
              }
              handleOpenReject()
            }}
          >
            <p>선택 반려</p>
          </Button>
        </Box>
        <Divider sx={{ height: 16 }} />

        <SellerTable
          sellers={sellers}
          setSellers={setSellers}
          checked={checked}
          setChecked={setChecked}
          approve={approve}
          clickHandler={clickHandler}
          clickApproveBtn={clickApproveBtn}
          handleOpenReject={handleOpenReject}
        />
        <Navigation page={page} setPage={setPage} count={count.search} />
      </Box>

      {preview && (
        <PreviewSellerModal
          id={id}
          open={preview}
          onClose={() => setPreview(false)}
          sellers={sellers}
          clickApproveBtn={clickApproveBtn}
          setPreview={setPreview}
          setChecked={setChecked}
          handleOpenReject={handleOpenReject}
        />
      )}
    </DefaultLayout>
  )
}

export default SellerListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}
