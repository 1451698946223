import { removeNotice } from '../../api/notice'

import SearchIcon from '@mui/icons-material/Search'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import Navigation from '../../components/common/navigation'
import { PreviewFAQModal } from '../../components/modal/modal'
import NoticeTable from '../../components/notice/notice-table'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import useNotice, { TYPE_LIST } from '../../hooks/notice/useNoticeList'

const NoticePage = () => {
  const navigation = useNavigate()

  const {
    typeIdx,
    setTypeIdx,
    search,
    setKeyword,
    notices,
    setNotices,
    clickHandler,
    page,
    setPage,
    count,
    preview,
    setPreview,
    id,
  } = useNotice()

  return (
    <DefaultLayout
      isLoggedIn
      onClickSubHeaderBtn={() => navigation('/notice/add')}
    >
      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            {TYPE_LIST.map(({ name }, idx) => (
              <Button
                key={idx}
                fs="10px"
                height="50px"
                color={
                  isFocus(TYPE_LIST[typeIdx].name, name) ? 'white' : '#707070'
                }
                bg={isFocus(TYPE_LIST[typeIdx].name, name) ? 'black' : 'white'}
                border="1px solid #e4e4e4"
                borderRadius="0"
                onClick={() => {
                  setTypeIdx(idx)
                }}
                sx={{ fontWeight: 'bold' }}
              >
                {name}
              </Button>
            ))}
          </Box>

          <InputTextField
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                search()
              }
            }}
            width="200px"
            placeholder="키워드 입력"
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            icon={
              <Box
                onClick={() => {
                  search()
                }}
                sx={{ display: 'flex' }}
              >
                <SearchIcon />
              </Box>
            }
          />
        </Box>

        <Divider sx={{ height: 40 }} />

        <NoticeTable
          notices={notices}
          setNotices={setNotices}
          clickHandler={clickHandler}
        />
        <Navigation page={page} setPage={setPage} count={count} />
      </Box>
      {preview && (
        <PreviewFAQModal
          id={id}
          open={preview}
          onClose={() => setPreview(false)}
          buttonGroup={
            <>
              <Button
                height="40px"
                bg="#BACAFF"
                color="#003BFF"
                bold
                borderRadius="10px"
                sx={{ marginRight: '20px' }}
                onClick={() => {
                  navigation(`/notice/${id}`)
                }}
              >
                수정
              </Button>

              <Button
                height="40px"
                bg="#fff0f0"
                color="#ff0000"
                bold
                borderRadius="10px"
                onClick={async () => {
                  await removeNotice(id)
                  const filtered = notices.filter((n) => n.id !== id)
                  setNotices([...filtered])
                  setPreview(false)
                }}
              >
                삭제
              </Button>
            </>
          }
        />
      )}
    </DefaultLayout>
  )
}

export default NoticePage

const boxStyle = {
  marginX: '10px',
  paddingX: '160px',
  marginTop: '10px',
  paddingTop: '40px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

const isFocus = (type, comp) => type === comp
