import { useEffect, useState } from 'react'
import { getSellers, updateApproveStatus } from '../../api/seller'

const useSellerList = (keyword, approveStatus, grade) => {
  const [id, setId] = useState('')
  const [preview, setPreview] = useState(false)

  const clickHandler = (id) => {
    setId(id)
    setPreview(true)
  }

  const [checked, setChecked] = useState([])
  const [sellers, setSellers] = useState([])

  const [page, setPage] = useState(1)

  const [countEntity, setCount] = useState({
    all: 0,
    waiting: 0,
    reject: 0,
    search: 0,
  })

  const initSellers = async () => {
    const { data, count, waitingSellerCnt, rejectSellerCnt } = await getSellers(
      page,
      keyword,
      approveStatus,
      grade
    )

    return { data, count, waitingSellerCnt, rejectSellerCnt }
  }

  useEffect(() => {
    ;(async () => {
      const { data, count, waitingSellerCnt, rejectSellerCnt } =
        await initSellers(page, keyword, approveStatus, grade)
      setSellers(data)
      setCount({ ...countEntity, search: count })
      setChecked([])

      if (page === 1 && countEntity.all === 0) {
        setCount({
          all: count,
          search: count,
          waiting: waitingSellerCnt,
          reject: rejectSellerCnt,
        })
      }
    })()
  }, [page])

  const clickSearchBtn = async () => {
    const { data, count } = await initSellers(
      page,
      keyword,
      approveStatus,
      grade
    )
    setSellers(data)
    setCount({ ...countEntity, search: count })
    setChecked([])

    setPage(1)
  }

  const approve = async (approveStatus, checked, rejectReason) => {
    if (checked.length === 0) {
      return
    }
    const { success } = await updateApproveStatus(
      checked.map((o) => o.id),
      approveStatus,
      rejectReason
    )

    if (!success) {
      return
    }

    let changeWaitingToApproveCnt = 0
    let changeWaitingToRejCnt = 0
    let changeApproveToRejCnt = 0
    let changeRejToApproveCnt = 0
    for (const seller of checked) {
      if (seller.approveStatus === approveStatus) {
        continue
      }

      switch (seller.approveStatus) {
        case 'WAITING':
          if (approveStatus === 'APPROVED') {
            ++changeWaitingToApproveCnt
          } else {
            ++changeWaitingToRejCnt
          }
          break
        case 'APPROVED':
          ++changeApproveToRejCnt
          break
        case 'REJECT':
          ++changeRejToApproveCnt
          break
        default:
          throw new Error('error')
      }

      seller.approveStatus = approveStatus
    }

    // waiting -> 승인, 거절
    // 승인 -> 거절
    // 거절 -> 승인
    countEntity.waiting -= changeWaitingToApproveCnt + changeWaitingToRejCnt
    countEntity.reject +=
      changeWaitingToRejCnt + changeApproveToRejCnt - changeRejToApproveCnt

    setSellers([...sellers])
    setChecked([])
    setCount({ ...countEntity })
  }

  return {
    approve,
    checked,
    clickSearchBtn,
    count: countEntity,
    sellers,
    setSellers,
    setChecked,
    clickHandler,
    page,
    setPage,
    preview,
    id,
    setPreview,
  }
}

export default useSellerList
