export const createQueryString = (queryObject) => {
  let query = ''

  for (const [key, value] of Object.entries(queryObject)) {
    if (value || typeof value === 'boolean') {
      query += `${key}=${value}&`
    }
  }

  return query
}
