import { Box, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useEffect } from 'react'

const SuccessPopup = ({ open, setOpen }) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
    return
  }, [open])

  return (
    <Box sx={{ ...style, display: open ? '' : 'none' }}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
      >
        <CheckCircleOutlineIcon
          style={{ fontSize: 50, color: '#00d819' }}
          sx={{ marginRight: '10px' }}
        />

        <p>성공적으로 저장되었습니다.</p>
      </Typography>
    </Box>
  )
}

const style = {
  zIndex: 1000,
  position: 'fixed',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '900px',
  height: '80px',
  bgcolor: '#e4e4e4',
  boxShadow: 24,
  borderRadius: 3,
  p: 2,
}

export default SuccessPopup
