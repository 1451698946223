import { InputAdornment, TextField, styled } from '@mui/material'

export const InputTextField = styled(
  ({ marginTop, marginBottom, icon, placeholder, multiline, ...rest }) => (
    <TextField
      multiline={multiline}
      placeholder={placeholder}
      fullWidth
      InputProps={{
        startAdornment: icon && (
          <InputAdornment position="start">{icon}</InputAdornment>
        ),
        style: {
          fontSize: '14px',
          height: multiline ? '' : 40,
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: '14px',
        },
      }}
      {...rest}
    />
  )
)((props) => ({
  backgroundColor: props.bg,
  width: props.width || '100%',
  marginTop: props.marginTop || '10px',
  marginBottom: props.marginBottom,
  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
  '& label.MuiInputLabel-root[data-shrink=false]': {
    top: '-8px',
  },
}))
