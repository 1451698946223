import {
  Box,
  Divider,
  Grid,
  Button as MButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { InputMultiTextField } from '../../components/common/TextField'
import { PreviewSnap } from '../../components/modal/modal'
import { Button } from '../../element/Button'

import Image from '../../element/Image'
import DefaultLayout from '../../global/DefaultLayout'

import {
  ALL_TAG_MAP,
  GENDER,
  KO_CLIMATE_TAG,
  KO_OCCUPATION_TAG,
  KO_SPECIAL_TAG,
  KO_STYLE_TAG,
} from '../../fixture/snap_tag'

import ProductLocs from '../../components/snap/ProductLocs'
import { CATEGORY_MAP } from './constants'
import useEditPost from './useEditPost'

export const POST_IMAGE_SIZE = 430

const EditPostPage = () => {
  const id = useLocation().pathname.split('/').pop()
  const { loading, post, handler, style, genderStyle, imageRef } =
    useEditPost(id)
  const navigation = useNavigate()

  const modifyPostHandler = async () => {
    const { success } = await handler.modify()

    if (!success) return alert('수정에 실패했습니다.')

    alert('수정에 성공했습니다.')
    navigation(-1)
  }

  return (
    <DefaultLayout isLoggedIn>
      <Stack direction="column">
        <Stack direction="row">
          <Stack
            width="100%"
            direction="column"
            bgcolor="white"
            padding="10px"
            marginX={1}
            alignItems="center"
          >
            <Typography fontWeight={700} fontSize={20}>
              수정
            </Typography>
            <Box height={40} />
            <Box width={480} padidng="25px">
              {/* modify image */}
              <>
                <Typography
                  fontWeight={700}
                  fontSize={20}
                  textAlign="start"
                  width="100%"
                >
                  이미지
                </Typography>
                <Box height={20} />
                <Stack direction="column" alignItems="center">
                  {post?.imgs.map((img, index) => {
                    return (
                      <Box
                        ref={(el) => (imageRef.current[index] = el)}
                        key={img}
                        position="relative"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => e.preventDefault()}
                      >
                        <label htmlFor={img}>
                          <Image
                            src={img}
                            width={POST_IMAGE_SIZE}
                            height={POST_IMAGE_SIZE}
                          />
                        </label>
                        <input
                          type="file"
                          id={img}
                          style={{ display: 'none' }}
                          accept="image/*"
                          onChange={handler.changeImage(index)}
                        />
                        {handler.getProductLocsByUrl(index).map((locs, idx) => {
                          const loc = { x: locs.x, y: locs.y }
                          return (
                            <div
                              key={`${img}_${idx}`}
                              draggable
                              onDragStart={handler.dragStart(locs.pid)}
                              onDragEnd={handler.dragEnd(index)}
                            >
                              <ProductLocs loc={loc} size={POST_IMAGE_SIZE} />
                            </div>
                          )
                        })}
                      </Box>
                    )
                  })}
                </Stack>
              </>
              {/* modify content */}
              <>
                <Typography
                  fontWeight={700}
                  fontSize={20}
                  textAlign="start"
                  width="100%"
                >
                  내용
                </Typography>
                <Box height={20} />
                <InputMultiTextField
                  sx={{ maxWidth: '500px' }}
                  id="outlined-multiline-static"
                  multiline
                  size="small"
                  fullWidth
                  InputProps={{ style: { fontSize: '14px' } }}
                  rows={8}
                  value={post?.content}
                  name="content"
                  onChange={handler.changeValueByName}
                />
              </>

              <Box height={30} />
              {/* modify gender */}
              <>
                <Typography
                  fontWeight={700}
                  fontSize={20}
                  textAlign="start"
                  width="100%"
                >
                  성별
                </Typography>
                <Box height={10} />
                <Divider width="100%" />

                <Box height={10} />
                {/* gender */}
                <Stack direction="row" gap={1}>
                  {GENDER.map(({ kor, val }) => (
                    <MButton
                      key={`GENDER_${kor}`}
                      variant={genderStyle.getVariantStyle(val)}
                      sx={{
                        borderRadius: 3,
                        marginRight: 1,
                        width: '100px',
                        ...genderStyle.getStyle(val),
                      }}
                      onClick={() => handler.changeGender(val)}
                    >
                      {kor}
                    </MButton>
                  ))}
                </Stack>
              </>

              <Box height={30} />
              {/* modify tags */}
              <>
                <Typography
                  fontWeight={700}
                  fontSize={20}
                  textAlign="start"
                  width="100%"
                >
                  스타일
                </Typography>
                <Box height={10} />
                <Divider width="100%" />
                <Box height={10} />
                {/* climate */}
                <Stack direction="row" gap={1}>
                  {KO_CLIMATE_TAG.map((t) => (
                    <MButton
                      key={`TAG_${t}`}
                      variant={style.getVariantStyle(ALL_TAG_MAP[t])}
                      sx={{
                        borderRadius: 3,
                        backgroundColor: style.getBackgroundColor(
                          ALL_TAG_MAP[t]
                        ),
                        color: style.getForegroundColor(ALL_TAG_MAP[t]),
                        borderColor: style.getForegroundColor(ALL_TAG_MAP[t]),
                        marginRight: 1,
                        width: '100px',
                      }}
                      onClick={() => handler.changeTag(ALL_TAG_MAP[t])}
                      disabled={style.getDisable(t)}
                    >
                      {t}
                    </MButton>
                  ))}
                </Stack>
                <Box height={10} />
                {/* occupation */}
                <Stack direction="row" gap={1}>
                  {KO_OCCUPATION_TAG.map((t) => (
                    <MButton
                      key={`TAG_${t}`}
                      variant={style.getVariantStyle(ALL_TAG_MAP[t])}
                      sx={{
                        borderRadius: 3,
                        backgroundColor: style.getBackgroundColor(
                          ALL_TAG_MAP[t]
                        ),
                        color: style.getForegroundColor(ALL_TAG_MAP[t]),
                        borderColor: style.getForegroundColor(ALL_TAG_MAP[t]),
                        marginRight: 1,
                        width: '100px',
                      }}
                      onClick={() => handler.changeTag(ALL_TAG_MAP[t])}
                      disabled={style.getDisable(t)}
                    >
                      {t}
                    </MButton>
                  ))}
                </Stack>
                {/* special */}
                <Box height={10} />
                <Stack direction="row" gap={1}>
                  {KO_SPECIAL_TAG.map((t) => (
                    <MButton
                      key={`TAG_${t}`}
                      variant={style.getVariantStyle(ALL_TAG_MAP[t])}
                      sx={{
                        borderRadius: 3,
                        backgroundColor: style.getBackgroundColor(
                          ALL_TAG_MAP[t]
                        ),
                        color: style.getForegroundColor(ALL_TAG_MAP[t]),
                        borderColor: style.getForegroundColor(ALL_TAG_MAP[t]),
                        marginRight: 1,
                        width: '100px',
                      }}
                      onClick={() => handler.changeTag(ALL_TAG_MAP[t])}
                      disabled={style.getDisable(t)}
                    >
                      {t}
                    </MButton>
                  ))}
                </Stack>
                {/* style */}
                <Box height={10} />
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {KO_STYLE_TAG.map((t) => (
                    <MButton
                      key={`TAG_${t}`}
                      variant={style.getVariantStyle(ALL_TAG_MAP[t])}
                      sx={{
                        borderRadius: 3,
                        backgroundColor: style.getBackgroundColor(
                          ALL_TAG_MAP[t]
                        ),
                        color: style.getForegroundColor(ALL_TAG_MAP[t]),
                        borderColor: style.getForegroundColor(ALL_TAG_MAP[t]),
                        marginRight: 1,
                        width: '100px',
                      }}
                      onClick={() => handler.changeTag(ALL_TAG_MAP[t])}
                      disabled={style.getDisable(t)}
                    >
                      {t}
                    </MButton>
                  ))}
                </Stack>
              </>
              <Box height={30} />
              {/* modify model */}
              <>
                <Typography
                  fontWeight={700}
                  fontSize={20}
                  textAlign="start"
                  width="100%"
                >
                  모델 정보
                </Typography>
                <Box height={10} />
                <Divider width="100%" />
                <Box height={10} />
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    paddingY={1}
                    container
                  >
                    <Grid item xs={2} display="flex" alignItems="center">
                      <Typography color="#707070" fontSize={14}>
                        키
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        sx={{
                          padding: 0,
                          '& ::placeholder': {
                            fontSize: 14,
                            color: '#707070',
                            opacity: 1,
                          },
                        }}
                        size="small"
                        placeholder="cm 단위로 입력 해 주세요 :)"
                        value={post.modelHeight === 0 ? '' : post.modelHeight}
                        onChange={handler.changeModelHeight}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    paddingY={1}
                    container
                  >
                    <Grid item xs={2} display="flex" alignItems="center">
                      <Typography color="#707070" fontSize={14}>
                        몸무게
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        sx={{
                          padding: 0,
                          '& ::placeholder': {
                            fontSize: 14,
                            color: '#707070',
                            opacity: 1,
                          },
                        }}
                        size="small"
                        placeholder="kg 단위로 입력 해 주세요 :)"
                        value={post.modelWeight === 0 ? '' : post.modelWeight}
                        onChange={handler.changeModelWeight}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {post.modelSizes.map((m) => {
                    const [key, value] = m.split(':')
                    return (
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        key={m.split(':')[0]}
                        paddingY={1}
                        container
                      >
                        <Grid item xs={2}>
                          <Typography color="#707070" fontSize={14}>
                            {CATEGORY_MAP[key.toUpperCase()] ?? key}
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            sx={{
                              padding: 0,
                              '& ::placeholder': {
                                fontSize: 14,
                                color: '#707070',
                                opacity: 1,
                              },
                            }}
                            size="small"
                            placeholder="태그하신 상품이 없으면 공란으로 남겨주세요!"
                            value={value}
                            onChange={handler.changeModelSizes(key)}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </>
            </Box>
          </Stack>
          {/* Preview */}
          <Stack
            width="100%"
            alignItems="center"
            direction="column"
            bgcolor="white"
            padding="10px"
            marginX={1}
          >
            <Typography fontWeight={700} fontSize={20} textAlign="center">
              미리보기
            </Typography>
            <Box height={40} />
            {!loading && (
              <Box width="480px" padding="25px">
                <PreviewSnap post={post} isViewLoc />
              </Box>
            )}
          </Stack>
        </Stack>
        <Box height={10} />
        <Stack direction="column" paddingX={1}>
          <Stack
            width="100%"
            direction="row"
            bgcolor="white"
            alignItems="center"
            padding={2}
            gap={1}
          >
            <Button
              size="large"
              variant="contained"
              onClick={modifyPostHandler}
              sx={{
                backgroundColor: 'black',
                fontWeight: 'bold',
                borderRadius: 2,
              }}
            >
              수정
            </Button>
            <Button
              size="large"
              bg="#fff0f0"
              color="#ff0000"
              bold
              borderRadius={2}
              height="44px"
              onClick={() => {
                navigation(-1)
                window.scrollTo(0, 0)
              }}
            >
              취소
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </DefaultLayout>
  )
}

export default EditPostPage
