import { createContext, useState } from 'react'

const initProductSearch = {
  category: { id: null, name: '', korName: '', children: [], parent: null },
  approvedStatus: '',
  sellStatus: '',
  keyword: '',
  brand: '',
}

export const ProductSearchContext = createContext()

export const ProductSearchProvider = ({ children }) => {
  const [option, setOption] = useState(initProductSearch)

  return (
    <ProductSearchContext.Provider value={{ option, setOption }}>
      {children}
    </ProductSearchContext.Provider>
  )
}
