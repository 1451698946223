import { useEffect, useState } from 'react'
import { getNotices } from '../../api/notice'
import { TYPE } from '../../pages/notice/utils'
import { useLocation } from 'react-router-dom'

export const TYPE_LIST = [{ name: 'All', value: '' }, ...TYPE]

const useNoticeList = () => {
  const isForSeller = useLocation().pathname.includes('notice-seller')

  const [typeIdx, setTypeIdx] = useState(0)
  const [notices, setNotices] = useState([])
  const [keyword, setKeyword] = useState('')

  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const [id, setId] = useState('')
  const [preview, setPreview] = useState(false)

  const search = async () => {
    init()
  }

  const clickHandler = (id) => {
    setId(id)
    setPreview(true)
  }

  const init = async () => {
    const { data, count } = await getNotices(
      page,
      keyword,
      isForSeller ? 'SELLER' : TYPE_LIST[typeIdx].value
    )
    setNotices(data)
    setCount(count)
  }

  // init
  useEffect(() => {
    ;(async () => {
      init()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      init()
      setPage(1)
    })()
  }, [typeIdx])

  return {
    typeIdx,
    setTypeIdx,
    search,
    setKeyword,
    notices,
    setNotices,
    clickHandler,
    page,
    setPage,
    count,
    preview,
    setPreview,
    id,
  }
}

export default useNoticeList
