import dayjs from 'dayjs'
import React from 'react'
import { getCategory } from '../../../api/category'
import {
  createConditionCoupon,
  deleteConditionCoupon,
  editConditionCoupon,
  getConditionCoupon,
  getConditionCoupons,
} from '../../../api/reward'
import { getSellers } from '../../../api/seller'
import { generateRandomString } from '../couponList'

export const COUPON_TYPE = {
  global: 'ALL',
  category: 'CATEGORY',
  collection: 'SHOWCASE',
  brand: 'BRAND',
}

export const COUPON_TEXT = {
  [COUPON_TYPE.global]: '전사',
  [COUPON_TYPE.category]: '카테고리',
  [COUPON_TYPE.collection]: '모음전',
  [COUPON_TYPE.brand]: '브랜드',
}

const INIT = {
  name: '',
  count: '',
  discount: '',
  maxDiscount: '',
  code: '',
  type: COUPON_TYPE.global,
  bigCategory: null,
  midCategory: null,
  promotionId: null,
  issueStartAt: dayjs().startOf('day'),
  issueEndAt: dayjs().startOf('day'),
  validStartAt: dayjs().startOf('day'),
  validEndAt: dayjs().startOf('day'),
  brand: null,
}

const useCouponIssuedLogic = () => {
  const [form, setForm] = React.useState(INIT)
  const [id, setId] = React.useState(null)

  const [coupon, setCoupon] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [count, setCount] = React.useState(0)

  const [category, setCategory] = React.useState([])
  const ref = React.useRef([])

  React.useEffect(() => {
    ;(async () => {
      const categories = await getCategory()
      if (!categories.success) return
      setCategory(categories.data.filter((d) => d.children.length !== 0))
      ref.current = categories.data
    })()
  }, [])

  const [brands, setBrands] = React.useState([])

  React.useEffect(() => {
    ;(async () => {
      const { data } = await getSellers(1, null, null, null, 100, true)
      setBrands(data)
    })()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      const coupons = await getConditionCoupons(page)
      if (coupons.success) {
        setCoupon(coupons.data.data)
        setCount(coupons.data.count)
      }
    })()
  }, [page])

  React.useEffect(() => {
    ;(async () => {
      if (!id) {
        setForm(INIT)
      } else {
        const { data } = await getConditionCoupon(id)

        const category = ref.current.filter(
          (c) => c.id === data?.category?.id
        )?.[0]

        const bigCategory = category?.parent?.id ?? category?.id ?? null
        const midCategory = category?.parent !== null ? category?.id : null

        const brand = brands.filter((b) => b.id === data?.seller?.id)?.[0]

        setForm({
          name: data.name,
          count: data.issueTotalCnt,
          discount: data.discountAmount * 100,
          maxDiscount: data.maxDiscountAmount,
          code: data.code,
          type: data.couponType,
          bigCategory,
          midCategory,
          issueStartAt: dayjs(data.issueStartAt).add(-13, 'h'),
          issueEndAt: dayjs(data.issueEndAt).add(-13, 'h').add(1, 'ms'),
          validStartAt: dayjs(data.validStartAt).add(-13, 'h'),
          validEndAt: dayjs(data.validEndAt).add(-13, 'h').add(1, 'ms'),
          promotionId: data?.promotion?.id ?? null,
          brand,
        })
      }
    })()
  }, [id])

  const handleForm = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const handleBrand = (_, value) => {
    const brand = brands.filter((b) => b.name === value)
    setForm((prev) => ({
      ...prev,
      brand: brand?.[0] ?? null,
    }))
  }

  const generateRandomCouponCode = () => {
    const code = generateRandomString(10)
    setForm((prev) => ({ ...prev, code }))
  }

  const handleType = (type) => () => {
    setForm((prev) => ({
      ...prev,
      type,
      bigCategory: type === COUPON_TYPE.category ? prev.bigCategory : null,
      midCategory: type === COUPON_TYPE.category ? prev.midCategory : null,
      brand: type === COUPON_TYPE.brand ? prev.brand : null,
    }))
  }

  const handleCategory = (e) => {
    switch (e.target.name) {
      case 'bigCategory':
        setForm((prev) => ({
          ...prev,
          bigCategory: e.target.value,
          midCategory: null,
        }))
        break
      case 'midCategory':
        setForm((prev) => ({ ...prev, midCategory: e.target.value }))
        break
      default:
        break
    }
  }

  const handleDate = (name) => (e) => {
    setForm((prev) => ({ ...prev, [name]: e }))
  }

  const changeId = (id) => () => {
    setId(id)
  }

  const submit = async () => {
    const body = {
      name: form.name,
      issueTotalCnt: parseInt(form.count),
      discountAmount: parseFloat(form.discount / 100),
      maxDiscountAmount: parseFloat(form.maxDiscount),
      couponType: form.type,
      code: form.code,
      categoryId:
        form.type === COUPON_TYPE.category &&
        (form?.midCategory ?? form?.bigCategory ?? null),
      promotionId: form.promotionId?.trim() || null,
      issueStartAt: makeISOString(form.issueStartAt, 13),
      issueEndAt: makeEndAtISOString(form.issueEndAt, 13),
      validStartAt: makeISOString(form.validStartAt, 13),
      validEndAt: makeEndAtISOString(form.validEndAt, 13),
      sellerId: form.brand?.id || null,
    }

    switch (form.type) {
      case COUPON_TYPE.category:
        if (!body.categoryId) {
          return alert('카테고리를 선택해주세요.')
        }
        delete body.sellerId
        break

      case COUPON_TYPE.brand:
        if (!body.promotionId) {
          return alert('기획전 id를 입력해주세요.')
        }
        delete body.categoryId

        break

      case COUPON_TYPE.collection:
        if (!body.promotionId) {
          return alert('기획전 id를 입력해주세요.')
        }
        delete body.categoryId
        delete body.sellerId
        break
      case COUPON_TYPE.global:
        delete body.categoryId
        delete body.sellerId
        break
      default:
        break
    }

    if (!id) {
      const responseCreate = await createConditionCoupon(body)
      if (responseCreate.success) {
        setPage(1)
        const coupons = await getConditionCoupons(1)
        if (coupons.success) {
          setCoupon(coupons.data.data)
          setCount(coupons.data.count)
        }
        setForm(INIT)
      } else {
        alert('쿠폰 발급 실패')
      }
    } else {
      const responseEdit = await editConditionCoupon({
        ...body,
        couponConditionId: id,
      })
      if (responseEdit.success) {
        const coupons = await getConditionCoupons(page)
        setId(null)
        if (coupons.success) {
          setCoupon(coupons.data.data)
          setCount(coupons.data.count)
        }
      }
    }
  }

  const deleteCoupon = (id) => async () => {
    const find = coupon.filter((c) => c.id === id)?.[0]

    if (!find) return

    const answer = window.confirm(`${find.name} 쿠폰을 삭제하시겠습니까?`)

    if (!answer) return

    const response = await deleteConditionCoupon(id)

    if (response.success) {
      const coupons = await getConditionCoupons(page)
      setId(null)
      if (coupons.success) {
        setCoupon(coupons.data.data)
        setCount(coupons.data.count)
      }
    }
  }

  return {
    value: { form, category, coupon, count, id, brands },
    handler: {
      form: handleForm,
      random: generateRandomCouponCode,
      type: handleType,
      category: handleCategory,
      date: handleDate,
      page: setPage,
      submit,
      id: changeId,
      delete: deleteCoupon,
      brand: handleBrand,
    },
  }
}

export default useCouponIssuedLogic

const makeISOString = (date, hours) => {
  return date.add(hours, 'h').toISOString()
}

const makeEndAtISOString = (date, hours) => {
  return date.add(hours, 'h').add(-1, 'ms').toISOString()
}
