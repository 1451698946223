import 'react-quill/dist/quill.bubble.css'

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import ReactQuill from 'react-quill'
import Image from '../../element/Image'
import DefaultLayout from '../../global/DefaultLayout'
import useNotice from '../../hooks/notice/useNotice'
import { TYPE } from './utils'

const AddNoticePage = () => {
  const {
    handleType,
    handleTitle,
    type,
    title,
    content,
    handleContent,
    handleImgs,
    submit,
    imgs,
    navigation,
    isForSeller,
  } = useNotice('ADD')

  return (
    <DefaultLayout isLoggedIn>
      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>공지사항 등록</Typography>
        <Box sx={{ height: '10px' }} />
        <Divider />
        <Box sx={{ height: '10px' }} />
        <Grid container>
          {!isForSeller && (
            <FormRow
              required
              label="분류"
              input={
                <FormControl sx={{ width: '200px' }}>
                  <InputLabel id="demo-simple-select-label">분류</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    onChange={handleType}
                    value={type}
                  >
                    {TYPE.map((t) => (
                      <MenuItem key={t.value} value={t.value}>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            />
          )}

          <Grid xs={12} item sx={{ height: '20px' }} />
          <FormRow
            required
            label="제목"
            input={
              <TextField
                id="outlined-basic"
                label="제목"
                variant="outlined"
                name="title"
                value={title}
                onChange={handleTitle}
              />
            }
          />
          <Grid xs={12} item sx={{ height: '20px' }} />
          <FormRow
            required
            label="내용"
            input={
              <Box
                sx={{
                  boxSizing: 'border-box',
                  padding: '10px',
                  border: '1px solid #e4e4e4',
                  width: '100%',
                  height: '600px',
                  borderRadius: '6px',
                  overflow: 'auto',
                }}
              >
                <ReactQuill
                  style={{ height: '100%', overflowY: 'auto' }}
                  theme="bubble"
                  modules={MODULE_QUILL}
                  name="content"
                  value={content}
                  onChange={handleContent}
                />
              </Box>
            }
          />
          <Grid xs={12} item sx={{ height: '20px' }} />
          {!isForSeller && (
            <FormRow
              label="이미지"
              input={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '160px',
                      height: '160px',
                      border: '1px solid #e4e4e4',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <label htmlFor="add-notice">
                      <AddIcon
                        sx={{ fontWeight: 700, fontSize: 80, color: '#e4e4e4' }}
                      />
                    </label>
                    <input
                      style={{ display: 'none' }}
                      id="add-notice"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleImgs}
                    />
                  </Box>
                  {imgs.map((img) => (
                    <Image
                      key={img}
                      src={img}
                      alt=""
                      width={160}
                      height={160}
                    />
                  ))}
                </Box>
              }
            />
          )}
        </Grid>
        <Box sx={{ gap: '10px', display: 'flex' }}>
          <Button
            width="56px"
            height="36px"
            variant="contained"
            sx={{
              bgcolor: '#fff0f0',
              color: '#ff7575',
              fontWeight: 700,
              borderRadius: '10px',
            }}
            onClick={() => navigation(-1)}
          >
            취소
          </Button>
          <Button
            width="56px"
            height="36px"
            variant="contained"
            sx={{
              bgcolor: '#000000',
              color: '#ffffff',
              fontWeight: 700,
              borderRadius: '10px',
            }}
            onClick={submit}
          >
            등록하기
          </Button>
        </Box>
      </Box>
    </DefaultLayout>
  )
}

export default AddNoticePage

const FormRow = ({ required, label, input }) => {
  return (
    <>
      <Grid
        item
        xs={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: '20px',
          paddingTop: '20px',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: 14, color: '#707070' }}>
          {label}
        </Typography>
        {required && (
          <Typography sx={{ color: 'red', fontWeight: 700 }}>*</Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        {input}
      </Grid>
      <Grid item xs={4} />
    </>
  )
}

const MODULE_QUILL = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
}
