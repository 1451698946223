import { clearToken, getToken } from './cookie'

import axios from 'axios'
import { clearLocalStorage } from './storage'

const LOCAL = 'http://127.0.0.1:3000'
const DEV = 'https://api.by-snap.com'
const PROD = 'https://server.by-snap.com'

const URL =
  process.env.REACT_APP_ENV === 'dev'
    ? DEV
    : process.env.REACT_APP_ENV === 'local'
    ? LOCAL
    : PROD

export const instance = axios.create({
  baseURL: URL,
  timeout: 15000,
})

instance.interceptors.request.use(
  (config) => {
    if (config.token) {
      const token = getToken()
      if (!token.access) {
        const e = new Error('token not found in cookie')
        e.status = 400
        return Promise.reject(e)
      }
      config.headers['Authorization'] = `Bearer ${token.access}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error?.response?.status && error.response.status === 401) {
      clearToken()
      clearLocalStorage()
      window.location.pathname = '/'
    }

    throw new Error(error)
  }
)
