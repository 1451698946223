import { Box, Modal, Typography } from '@mui/material'
import { Button } from '../element/Button'

const SelectModal = ({
  open,
  handleClose,
  clickModalBtn,
  mainTxt,
  leftTxt,
  rightTxt,
  leftBg,
  rightBg,
}) => {
  const arr = [
    { txt: leftTxt, bg: leftBg, onClick: clickModalBtn },
    { txt: rightTxt, bg: rightBg, onClick: handleClose },
  ]
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={'center'}
        >
          <p>{mainTxt}</p>
        </Typography>

        <Box display={'flex'} justifyContent={'space-around'} marginTop={5}>
          {arr.map(({ txt, bg, onClick }, idx) => (
            <Box key={idx} textAlign={'center'}>
              <Button onClick={onClick} bg={bg} width="150px" height="44px">
                <p>{txt}</p>
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
}

export default SelectModal
