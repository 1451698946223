import { Autocomplete, styled } from '@mui/material'

export const CustomDropDownField = styled(Autocomplete)({
  marginTop: '10px',
  width: '450px',

  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    // 해당 input focus 되었을 때 placeholder text color
    // floating label을 사용할 때 처리 필요하다
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
})
