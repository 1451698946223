import { Box, Divider, Typography } from '@mui/material'
import Navigation from '../../../components/common/navigation'
import CouponIssuedForm from '../../../components/reward/coupon-issued-form'
import CouponIssuedTable from '../../../components/reward/coupon-issued-table'
import DefaultLayout from '../../../global/DefaultLayout'
import useCouponIssuedLogic from './logic'

const CouponIssuedPage = () => {
  const { value, handler } = useCouponIssuedLogic()

  return (
    <DefaultLayout isLoggedIn>
      <Box
        marginX="10px"
        paddingX="20px"
        marginTop="10px"
        paddingTop="16px"
        paddingBottom="40px"
        bgcolor="white"
      >
        <p style={{ fontSize: 18, fontWeight: 700 }}>쿠폰 발급</p>
        <Divider sx={{ height: 16 }} />
        <CouponIssuedForm
          id={value.id}
          form={value.form}
          category={value.category}
          handler={handler}
          brands={value.brands}
        />
      </Box>
      <Box
        marginX="10px"
        paddingX="20px"
        marginTop="10px"
        paddingBottom="40px"
        bgcolor="white"
      >
        <Typography fontWeight={700} paddingY="20px">
          쿠폰 발급 정보
        </Typography>
        <Divider />
        <CouponIssuedTable coupons={value.coupon} handler={handler} />
        <Navigation
          page={value.page}
          setPage={handler.page}
          count={value.count}
        />
      </Box>
    </DefaultLayout>
  )
}

export default CouponIssuedPage
