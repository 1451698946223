import { useState } from 'react'
import SelectModal from '../../../components/SelectModal'

const useSelectModal = ({
  mainTxt,
  leftTxt,
  rightTxt,
  leftBg,
  rightBg,
  submit,
}) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return {
    modal: () => (
      <SelectModal
        open={open}
        mainTxt={mainTxt}
        leftTxt={leftTxt}
        leftBg={leftBg}
        clickModalBtn={submit}
        rightTxt={rightTxt}
        rightBg={rightBg}
        handleClose={() => setOpen(false)}
      />
    ),
    openModal,
    closeModal,
  }
}

export default useSelectModal
