import { Box, Modal, Typography } from '@mui/material'
import { Button } from '../element/Button'
import { Textarea } from '@mui/joy'
import { useState } from 'react'

const RejectModal = ({ open, handleClose, clickModalBtn }) => {
  const [reason, setReason] = useState('')

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={'center'}
        >
          <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
            해당 셀러의 승인을 반려하시겠습니까?
          </p>
          <p style={{ fontSize: '16px' }}>반려 사유에 대해 입력해주세요.</p>
        </Typography>

        <Box height="30px" />

        <Textarea
          minRows={10}
          placeholder="승인 반려 사유를 입력해주세요."
          onChange={(e) => {
            setReason(e.target.value)
          }}
        />

        <Box display={'flex'} justifyContent="end" gap={2} marginTop={5}>
          <Box textAlign={'center'}>
            <Button
              onClick={handleClose}
              width="100px"
              height="44px"
              bg="white"
              color="#707070"
              border="1px solid #707070"
              bold
            >
              취소
            </Button>
          </Box>

          <Box textAlign={'center'}>
            <Button
              onClick={() => {
                clickModalBtn(reason)
              }}
              width="100px"
              height="44px"
              color="red"
              bg="#fff0f0"
              bold
            >
              반려
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '780px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
}

export default RejectModal
