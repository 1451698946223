import { Grid, TextField } from '@mui/material'
import { CustomDropDownField } from './CustomDropdownField'
import { useEffect, useState } from 'react'
import { getSellers } from '../../api/seller'

const SelectBrand = ({ value, setValue }) => {
  const [brands, setBrands] = useState([])

  useEffect(() => {
    ;(async () => {
      const { data } = await getSellers(1, null, null, null, 100, true)
      const brands = data.map((o) => o.name)
      setBrands(brands)
    })()
  }, [])

  const onChange = (_, value) => {
    setValue((prev) => ({
      ...prev,
      brand: value,
    }))
  }

  return (
    <>
      <Grid item xs={1.6}>
        <p
          style={{
            fontSize: '14px',
            color: '#707070',
            fontWeight: 'bold',
          }}
        >
          브랜드
        </p>
      </Grid>
      <Grid item xs={10}>
        <CustomDropDownField
          fullWidth
          disableClearable
          size="small"
          value={value}
          onChange={onChange}
          ListboxProps={{
            style: {
              fontSize: '14px',
            },
          }}
          options={['', ...brands]}
          filterOptions={(options, { inputValue }) => {
            return options.filter(
              (option) =>
                option !== '' &&
                option.toLowerCase().includes(inputValue.toLowerCase())
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              label="브랜드 선택"
            />
          )}
        />
      </Grid>
    </>
  )
}

export default SelectBrand
