import { useEffect, useRef, useState } from 'react'
import { getImagesUrl } from '../../api/image'
import { getOpponentTag, getPost } from '../../api/post'

/**
 * @param {string} id
 */
const usePost = (id) => {
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState({
    imgs: [],
    products: [],
    seller: {},
    tags: [],
    productLocs: [],
    gender: null,
    modelWeight: null,
    modelHeight: null,
    modelSizes: [],
  })
  const [opponentTag, setOpponentTag] = useState([])
  const apiOpponent = useRef()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const { data } = await getPost(id)
        setPost({ ...data.post, tags: data.post.tags.map((t) => t.tag) })

        const { data: tagData } = await getOpponentTag()
        apiOpponent.current = tagData.data

        const checkFilter = tagData.data.filter((t) => {
          return data.post.tags.some((d) => d.tag === t.mainTag.tag)
        })
        setOpponentTag([...new Set(checkFilter)])
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [id])

  /**

   * @param {import('react').ChangeEventHandler<HTMLInputElement>} e
   */
  const changeGender = (gender) => {
    setPost((prev) => {
      if (prev.gender === gender) {
        return { ...prev, gender: null }
      }
      return { ...prev, gender }
    })
  }

  /**

   * @param {import('react').ChangeEventHandler<HTMLInputElement>} e
   */
  const changeValueByName = (e) => {
    setPost((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  /**
   * @param {import('react').ChangeEventHandler<HTMLInputElement>} e
   * @param {number} index
   */
  const changeImage = (index) => async (e) => {
    if (!e.target.files) return

    const newImage = e.target.files[0]

    const formData = new FormData()
    const newImageName = `${post?.seller?.name ?? 'BYSNAP'}.${newImage.type}`

    formData.append('type', 'post')
    formData.append(
      'files',
      new Blob([newImage], { type: newImage.type }),
      newImageName
    )

    const { success, data } = await getImagesUrl(formData)

    if (!success) return

    const newImageList = [...post.imgs]
    newImageList.splice(index, 1, data[0])

    setPost((prev) => ({ ...prev, imgs: newImageList }))
  }

  const changeTag = (tag) => {
    const isIn = post.tags.includes(tag)

    let updated

    if (isIn) {
      updated = post.tags.filter((e) => e !== tag)
      setOpponentTag((prev) => prev.filter((t) => t.mainTag.tag !== tag))
    } else {
      updated = [...post.tags, tag]
      const checkFilter = apiOpponent.current.filter((t) => {
        return tag === t.mainTag.tag
      })
      setOpponentTag((prev) => [...new Set(prev.concat(checkFilter))])
    }

    setPost((prev) => ({ ...prev, tags: updated }))
  }

  /**
   * @param {import('react').ChangeEventHandler<HTMLInputElement>} e
   */
  const changeModelWeight = (e) => {
    const modelWeight = e.target.value
    const decimalRegex = /^\d+(\.\d*)?$/

    if (e.target.value === '') {
      setPost((prev) => {
        return { ...prev, modelWeight: 0 }
      })
    }

    if (decimalRegex.test(modelWeight)) {
      setPost((prev) => {
        return { ...prev, modelWeight }
      })
    }
  }

  /**
   * @param {import('react').ChangeEventHandler<HTMLInputElement>} e
   */
  const changeModelHeight = (e) => {
    const modelHeight = e.target.value
    const decimalRegex = /^\d+(\.\d*)?$/

    if (e.target.value === '') {
      setPost((prev) => {
        return { ...prev, modelHeight: 0 }
      })
    }

    if (decimalRegex.test(modelHeight)) {
      setPost((prev) => {
        return { ...prev, modelHeight }
      })
    }
  }

  /**
   * @param {import('react').ChangeEventHandler<HTMLInputElement>} e
   * @param {string} key
   */
  const changeModelSizes = (key) => (e) => {
    const regex = /^[^\u3131-\u318E\uAC00-\uD7A3]*$/
    if (!regex.test(e.target.value)) {
      return
    }
    let sizes = Array.from(post.modelSizes)

    const index = sizes.findIndex((size) => size.startsWith(key))
    const value = `${key}:${e.target.value}`

    sizes[index] = value

    setPost((prev) => ({
      ...prev,
      modelSizes: sizes,
    }))
  }

  return {
    loading,
    post,
    opponentTag,
    handler: {
      changeImage,
      changeValueByName,
      changeTag,
      changeGender,
      changeModelWeight,
      changeModelHeight,
      changeModelSizes,
    },
  }
}

export default usePost
