import { Box, Checkbox } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { getDate } from '../../utils'

import SelectModal from '../SelectModal'

const heads = [
  '',
  '판매일',
  '판매자',
  '주문번호',
  '상품주문번호',
  '상품명',
  '구매자',
  '반품신청일',
  '반품주문번호',
  '상품판매가',
  '반송비용',
  '쿠폰',
  '환불금액',
  '크레딧',
  '반품상태',
  '상품상태',
  '재판매여부',
  '정산여부',
]

const ReturnListTable = ({
  returns,
  checked,
  setChecked,
  changeStatus,
  calculate,
}) => {
  const [open, setOpen] = React.useState(false)

  const [
    { mainTxt, leftTxt, leftBg, rightBg, rightTxt, submit },
    setModalProps,
  ] = React.useState({
    mainTxt: '',
    leftTxt: '',
    rightTxt: '',
    leftBg: '',
    rightBg: '',
    submit: () => {},
  })

  const openModal = ({
    mainTxt,
    leftTxt,
    rightTxt,
    leftBg,
    rightBg,
    id,
    itemStatus,
    productStatuses,
  }) => {
    setModalProps((prev) => ({
      ...prev,
      mainTxt,
      leftBg,
      leftTxt,
      rightBg,
      rightTxt,
      id,
      itemStatus,
      productStatuses,
      submit: async () => {
        await changeStatus([id], [itemStatus], [productStatuses])
        setOpen(false)
      },
    }))
    setOpen(true)
  }

  return (
    <Table>
      <SelectModal
        open={open}
        mainTxt={mainTxt}
        leftTxt={leftTxt}
        leftBg={leftBg}
        rightTxt={rightTxt}
        rightBg={rightBg}
        clickModalBtn={submit}
        handleClose={() => setOpen(false)}
      />
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {returns.map((returnEntity) => {
          return (
            <React.Fragment key={returnEntity.id}>
              {TableRowHead(returnEntity, calculate)}
              {returnEntity.returnItems.map((returnItem, idx) => {
                return TableRow(
                  returnEntity,
                  returnItem,
                  checked,
                  setChecked,
                  idx,

                  openModal
                )
              })}
            </React.Fragment>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ReturnListTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const P = styled('p')`
  display: inline;
  text-decoration: underline;
  text-decoration-color: currentColor;
  cursor: pointer;
`

const TableRowHead = (returnEntity, calculate) => {
  const FEE_WIDTH = 80

  return (
    <tr
      key={returnEntity.id}
      style={{
        borderBottom: '1px solid #ddd',
        height: '100px',
        backgroundColor: '#ddd',
        fontWeight: 'bold',
      }}
    >
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td>{returnEntity.order.nickname}</Td>
      <Td>{getDate(returnEntity.createdAt)}</Td>
      <Td>{returnEntity.returnNumber}</Td>
      <Td>${returnEntity.refundItemsAmount}</Td>
      <Td width={FEE_WIDTH} style={{ color: 'red' }}>
        -${returnEntity.returnProcessingFee}
      </Td>
      <Td width={FEE_WIDTH} style={{ color: 'red' }}>
        -${returnEntity.refundCouponAmount}
      </Td>
      <Td width={FEE_WIDTH}>
        $
        {(
          returnEntity.refundItemsAmount -
          returnEntity.returnProcessingFee -
          returnEntity.refundCouponAmount -
          returnEntity.refundCreditAmount
        ).toFixed(2)}
      </Td>
      <Td width={FEE_WIDTH}>${returnEntity.refundCreditAmount}</Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td width={100}>
        {getWhetherSettlement(returnEntity.id, returnEntity.status, calculate)}
      </Td>
    </tr>
  )
}

const TableRow = (
  returnEntity,
  returnItem,
  checked,
  setChecked,
  idx,
  openModal
) => {
  return (
    <tr
      key={idx}
      style={{
        borderBottom: '1px solid #ddd',
        height: '100px',
      }}
    >
      <Td width={30}>
        <Box textAlign={'center'}>
          <Checkbox
            checked={checked.map((o) => o.id).includes(returnItem.id)}
            onClick={(e) => {
              if (checked.map((o) => o.id).includes(returnItem.id)) {
                const idx = checked.findIndex((o) => o.id === returnItem.id)
                checked.splice(idx, 1)
              } else {
                checked.push(returnItem)
              }
              setChecked([...checked])
            }}
          />
        </Box>
      </Td>
      <Td>{getDate(returnEntity.order.createdAt)}</Td>
      <Td>{returnItem.orderItem.product.seller.name}</Td>
      <Td>{returnEntity.order.orderNumber}</Td>
      <Td>{returnItem.orderItem.orderItemNumber}</Td>
      <Td>{returnItem.orderItem.product.name}</Td>
      <Td>{returnEntity.order.nickname}</Td>
      <Td>{getDate(returnEntity.createdAt)}</Td>
      <Td>{returnEntity.returnNumber}</Td>
      <Td>${returnItem.orderItem.orderPrice}</Td>
      <Td></Td>
      {/* 미입고 시 미환불 */}
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td width={150}>
        {getStatus(
          returnItem.id,
          returnItem.returnProductStatus,
          returnItem.status,
          openModal
        )}
      </Td>
      <Td width={150}>
        {getProductStatus(
          returnItem.id,
          returnItem.returnProductStatus,
          returnItem.status,
          openModal
        )}
      </Td>
      <Td></Td>
      <Td></Td>
    </tr>
  )
}

const getWhetherSettlement = (id, status, calculate) => {
  const calculateRefund = async () => {
    // modal
    await calculate(id)
  }

  switch (status) {
    case 'REQUEST':
      return 'X'
    case 'ADMIT':
      return (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <p style={{ fontWeight: 'bold' }}>N</p>
          <P style={{ fontWeight: 'bold' }} onClick={calculateRefund}>
            정산처리
          </P>
        </Box>
      )
    case 'DENIED':
      return '거부함'
    case 'SETTLED':
      return 'Y'
    default:
      return ''
  }
}

const getProductStatus = (id, returnProductStatus, status, openModal) => {
  const updateApprove = () => {
    openModal({
      mainTxt: '선택 상품을 판매가능 처리하시겠습니까?',
      leftTxt: '판매가능',
      rightTxt: '취소',
      leftBg: 'black',
      rightBg: 'red',
      id,
      itemStatus: status,
      productStatuses: 'CAN_SELLING',
    })
  }

  const updateReject = () => {
    openModal({
      mainTxt: '선택 상품을 판매불가 처리하시겠습니까?',
      leftTxt: '판매불가',
      rightTxt: '취소',
      leftBg: 'black',
      rightBg: 'red',
      id,
      itemStatus: status,
      productStatuses: 'NOT_SELLING',
    })
  }

  if (status === 'SHIPPING') {
    return ''
  }

  switch (returnProductStatus) {
    case 'DEFAULT':
      return (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <p>검수대기</p>
          <Box display="flex" justifyContent="center" gap={1}>
            <P style={{ color: 'blue' }} onClick={updateApprove}>
              판매가능
            </P>
            <P style={{ color: 'red' }} onClick={updateReject}>
              판매불가
            </P>
          </Box>
        </Box>
      )
    case 'CAN_SELLING':
      return <p>판매가능</p>

    case 'NOT_SELLING':
      return <p style={{ color: 'red' }}>판매불가</p>
    default:
      return ''
  }
}

const getStatus = (id, returnProductStatus, status, openModal) => {
  const updateApprove = () => {
    openModal({
      mainTxt: '선택 상품을 수거완료 처리하시겠습니까?',
      leftTxt: '수거완료',
      rightTxt: '취소',
      leftBg: 'black',
      rightBg: 'red',
      id,
      itemStatus: 'RECEIVED_COMPLETE',
      productStatuses: returnProductStatus,
    })
  }
  const updateReject = () => {
    openModal({
      mainTxt: '선택 상품을 미입고 처리하시겠습니까?',
      leftTxt: '미입고',
      rightTxt: '취소',
      leftBg: 'black',
      rightBg: 'red',
      id,
      itemStatus: 'NOT_IN',
      productStatuses: returnProductStatus,
    })
  }

  const updatePartnerReturn = () => {
    openModal({
      mainTxt: '선택 상품을 파트너스 반품 처리하시겠습니까?',
      leftTxt: '입고',
      rightTxt: '취소',
      leftBg: 'black',
      rightBg: 'red',
      id,
      itemStatus: 'PARTNER_RETURN',
      productStatuses: returnProductStatus,
    })
  }

  // status = 'RECEIVED_COMPLETE'
  switch (status) {
    case 'SHIPPING':
      return (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <p>반품수거중</p>
          <Box display="flex" justifyContent="center" gap={1}>
            <P onClick={updateApprove}>수거완료</P>
            <P onClick={updateReject} style={{ color: 'red' }}>
              미입고
            </P>
          </Box>
        </Box>
      )
    case 'RECEIVED_COMPLETE':
      return (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <p>센터보관완료</p>
          <P style={{ color: '#00A52D' }} onClick={updatePartnerReturn}>
            파트너반송
          </P>
        </Box>
      )
    case 'NOT_IN':
      return <p style={{ color: 'red' }}>미입고</p>
    case 'PARTNER_RETURN':
      return '파트너 반송'
    default:
      return ''
  }
}
