import styled from 'styled-components'
import Image from '../../element/Image'
import { Checkbox } from '@mui/material'

const heads = ['', 'ID', '브랜드', '대표 이미지']

const SellerItemSearchTable = ({ items, checked, setChecked }) => {
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>{head}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const isChecked = checked.map((o) => o.id).includes(item.id)

          return (
            <tr
              key={item.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td width={50}>
                <Checkbox
                  checked={isChecked}
                  onClick={() => {
                    if (isChecked) {
                      const idx = checked.findIndex((o) => o.id === item.id)
                      checked.splice(idx, 1)
                    } else {
                      checked.push(item)
                    }
                    setChecked([...checked])
                  }}
                />
              </Td>
              <Td width={100}>{item.id}</Td>

              <Td width={100}>{item.name}</Td>

              <Td width={150}>
                <Image
                  alt="seller"
                  src={item.profileImg}
                  width="100px"
                  height="100px"
                  border="1px solid #E4E4E4"
                  padding="2px"
                />
              </Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default SellerItemSearchTable

const Table = styled('table')`
  width: 100%;
  border-top: 2px solid black;
`

const Th = styled('th')`
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  height: 48px;
  vertical-align: middle;
  text-align: center;
`

const Td = styled('td')`
  width: ${(props) => props.width}px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`
