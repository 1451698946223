import { Box } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../static/header.png'

const Header = () => {
  const navigation = useNavigate()
  const clickLogo = () => {
    navigation('/')
    window.scrollTo(0, 0)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        sx={{
          backgroundColor: '#ffffff',
          height: 50,
          boxShadow: 'none',
          borderBottom: '1px solid #e4e4e4',
        }}
      >
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ height: 50, justifyContent: 'space-between' }}
        >
          <LogoBox onClick={clickLogo}>
            <img src={logo} alt="logo" style={{ height: '18px' }} />
          </LogoBox>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header

const LogoBox = styled(Box)`
  padding: 16px 0px 10px 20px;
  &:hover {
    cursor: pointer;
  }
`
