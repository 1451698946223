import { Box } from '@mui/material'
import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import Image from '../../element/Image'
import { getResizeUrl } from '../../utils'

const StyledSlider = styled(Slider)``

const ImageSlider = ({ images }) => {
  // const isEmpty = images.filter((i) => i !== '').length

  const settings = {
    // dots: isEmpty ? true : false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 12,
    slidesToScroll: 12,
    swipe: true,
  }

  return (
    <>
      <StyledSlider {...settings}>
        {images.map((img, idx) =>
          img ? (
            <Image
              key={img}
              src={getResizeUrl(img, 320, false)}
              width={100}
              height={100}
            />
          ) : (
            <div
              key={idx}
              style={{
                width: '100px',
                height: '200px',
                display: 'block',
                backgroundColor: '#ffffff',
                border: '1px solid',
              }}
            />
          )
        )}
      </StyledSlider>
      <Box height={30} />
    </>
  )
}

export default ImageSlider
