import styled from 'styled-components'

const Image = ({
  src,
  alt,
  width,
  height,
  borderRadius,
  padding,
  margin,
  onClick,
  cursor,
  border,
  boxSizing,
  hoverStyle,
  onMouseEnter,
  onMouseLeave,
  onError,
}) => {
  return (
    <Img
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      src={src}
      alt={alt}
      style={{
        width,
        height,
        borderRadius,
        padding,
        margin,
        cursor,
        border,
        boxSizing,
      }}
      hoverStyle={hoverStyle}
      onClick={onClick}
      onError={onError}
    />
  )
}

export default Image

const Img = styled('img')(({ hoverStyle }) => ({
  objectFit: 'cover',
  ':hover': hoverStyle,
}))
