import { TextField } from '@mui/material'
import styled from 'styled-components'

export const InputMultiTextField = styled(TextField)({
  marginTop: '10px',
  width: '90%',
  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
  '& label.MuiInputLabel-root[data-shrink=false]': {
    top: '-8px',
  },
})
