import { createContext, useState } from 'react'

export const KeepContext = createContext()

export const KeepProvider = ({ children }) => {
  const [keep, setKeep] = useState({
    post: false,
    product: false,
  })

  return (
    <KeepContext.Provider value={{ keep, setKeep }}>
      {children}
    </KeepContext.Provider>
  )
}
