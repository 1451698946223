import { instance } from './instance'

export const createReserveAlarm = async (body) => {
  const filteredBody = Object.entries(body).reduce((map, cur) => {
    if (cur[1]) {
      map[cur[0]] = cur[1]
    }
    return map
  }, {})
  try {
    const { data } = await instance.post(`/api/alarm/reserve`, filteredBody, {
      token: true,
    })
    return {
      success: true,
      data,
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const updateReserveAlarm = async (id, body) => {
  try {
    const { data } = await instance.put(`/api/alarm/reserve/${id}`, body, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}

export const removeReserveAlarm = async (alarmId) => {
  try {
    await instance.delete(`/api/alarm/reserve/${alarmId}`, {
      token: true,
    })
    return {
      success: true,
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getReserveAlarms = async ({ page, size }) => {
  try {
    const query = {
      page,
      size,
    }

    const queryStr = Object.keys(query)
      .filter((key) => query[key] || typeof query[key] === 'boolean')
      .map((key) => `${key}=${query[key]}`)
      .join('&')

    const { data } = await instance.get(`/api/alarm/reserve?${queryStr}`, {
      token: true,
    })

    return data
  } catch (error) {
    throw error
  }
}
