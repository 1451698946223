import { Divider } from '@mui/material'

import Box from '@mui/material/Box'

import styled from 'styled-components'
import ImageSlider from '../../components/promotion/Slider'
import ItemSearchBar from '../../components/promotion/ItemSearchBar'
import SellerItemSearchTable from '../../components/promotion/SellerItemSearchTable'
import { useEffect, useState } from 'react'
import Navigation from '../../components/common/navigation'
import { Button } from '../../element/Button'
import { getSellers } from '../../api/seller'

const RegisterSeller = ({ setOnRegister, campaign, setCampaign }) => {
  const [items, setItems] = useState([])
  const [checked, setChecked] = useState(campaign.sellers)
  const [option, setOption] = useState({ page: 1, count: 0 })
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    ;(async () => {
      const data = await getSellers(option.page, keyword, 'APPROVED', null, 20)

      setItems(data.data)
      setOption((prev) => ({
        ...prev,
        count: prev.count !== 0 ? prev.count : data.count,
      }))
    })()
  }, [option.page])

  const updateItems = () => {
    const combined = [...campaign.sellers, ...checked]
    const result = Array.from(
      combined.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
    )
    if (result.length > 10) {
      return alert('최대 10개까지 등록가능합니다.')
    }
    setCampaign((prev) => ({ ...prev, sellers: result }))

    setChecked([])
    setOnRegister(null)
  }

  const search = async (keyword) => {
    const data = await getSellers(option.page, keyword, 'APPROVED', null, 20)

    setItems(data.data)
    setOption({ page: 1, count: data.count })
  }

  return (
    <Box sx={boxStyle}>
      <Box display="flex" alignItems="center" gap={3}>
        <SubTitle>상단 브랜드 추가</SubTitle>
      </Box>

      <Divider sx={{ height: 16 }} />

      <Box
        sx={{ marginTop: 3, marginBottom: 1 }}
        display="flex"
        alignItems="center"
        gap={3}
      >
        <SubTitle>추가된 브랜드</SubTitle>
      </Box>

      <ImageSlider images={defaultImage(checked.map((c) => c.profileImg))} />

      <Box height={20} />
      <ItemSearchBar
        placeholder="브랜드 입력"
        onClick={search}
        keyword={keyword}
        setKeyword={setKeyword}
      />

      <Box height={20} />
      <Box width="1100px">
        <SellerItemSearchTable
          items={items}
          checked={checked}
          setChecked={setChecked}
        />

        <Navigation
          page={option.page}
          setPage={(page) => {
            setOption((prev) => ({ ...prev, page: page }))
          }}
          count={option.count}
        />
      </Box>

      <Box display="flex" gap={2}>
        <Button
          borderRadius="10px"
          bg="#FFF0F0"
          color="#F57979"
          onClick={() => {
            setOnRegister(null)
          }}
        >
          취소
        </Button>
        <Button borderRadius="10px" onClick={updateItems}>
          저장하기
        </Button>
      </Box>
    </Box>
  )
}
export default RegisterSeller

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
  color: '#707070',
}

const SubTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: black;
`

const defaultImage = (images) => {
  if (images.length < 12) {
    images = images.concat(new Array(12 - images.length).fill(''))
  }
  return images
}
