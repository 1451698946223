import { getDate, getResizeUrl } from '../../utils'

import styled from 'styled-components'
import { Checkbox } from '@mui/material'
import OverwriteImage from '../../element/overwriteImage'

const heads = [
  '상품번호',
  '상품사진',
  '브랜드',
  '대카테고리',
  '중카테고리',
  '판매가($)',
  '할인가($)',
  '할인율(%)',
  '등록일',
]

const ProductSearchTable = ({ items, checked, setChecked }) => {
  const isAllChecked = items.every((item) =>
    checked.map((o) => o.id).includes(item.id)
  )

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  const filtered = checked.filter(
                    (c) => !items.map((i) => i.id).includes(c.id)
                  )
                  console.log(filtered)
                  setChecked([...filtered])
                } else {
                  const filtered = items.filter(
                    (i) => !checked.map((c) => c.id).includes(i.id)
                  )
                  setChecked((prev) => [...prev, ...filtered])
                }
              }}
            />
          </Th>

          {heads.map((head, idx) => (
            <Th key={idx}>{head}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const isChecked = checked.map((o) => o.id).includes(item.id)

          return (
            <tr
              key={item.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td width={50}>
                <Checkbox
                  checked={isChecked}
                  onClick={() => {
                    if (isChecked) {
                      const idx = checked.findIndex((o) => o.id === item.id)
                      checked.splice(idx, 1)
                    } else {
                      checked.push(item)
                    }
                    setChecked([...checked])
                  }}
                />
              </Td>
              <Td width={100}>{item.productNumber}</Td>
              <Td width={150}>
                <OverwriteImage
                  alt="product"
                  src={getResizeUrl(item.imgs[0], 320, false)}
                  width="100px"
                  height="100px"
                  border="1px solid #E4E4E4"
                  padding="2px"
                  text={item.sellStatus === 'SOLDOUT' ? 'soldout' : ''}
                />
              </Td>
              <Td width={100}>{item.seller.name}</Td>
              <Td width={200}>{item.category.parent?.korName}</Td>
              <Td width={200}>{item.category.korName}</Td>
              <Td width={200}>{item.price}</Td>
              <Td width={200}>{item.discountedPrice}</Td>
              <Td width={200}>{item.discountRateUS}%</Td>
              <Td width={100}>{getDate(item.createdAt)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ProductSearchTable

const Table = styled('table')`
  width: 100%;
  border-top: 2px solid black;
`

const Th = styled('th')`
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  height: 48px;
  vertical-align: middle;
  text-align: center;
`

const Td = styled('td')`
  width: ${(props) => props.width}px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`
