import styled from 'styled-components'

import { getDate } from '../../utils'

const heads = ['주문번호', '상품주문번호', '상품명', '판매일']

const SendingTable = ({ checked }) => {
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {checked.map((item, index) => {
          const {
            order: { orderNumber },
            product: { name },
            orderItemNumber,
            createdAt,
          } = item

          return (
            <tr
              key={item.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td>{orderNumber}</Td>
              <Td>{orderItemNumber}</Td>
              <Td>{name}</Td>
              <Td>{getDate(createdAt)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default SendingTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
  borderTop: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})
