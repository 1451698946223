import { Box, FormControl, MenuItem, Select } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import styled from 'styled-components'
import { Button } from '../../element/Button'
import Image from '../../element/Image'
import { getProfileImgSrc } from '../user/user-table'
import { SELLER_GRADE } from '../../pages/user/sellerList'
import { updateGrade } from '../../api/seller'

const heads = [
  '승인여부',
  '셀러 등급',
  '이메일',
  '셀러명',
  '매니저명',
  '매니저 번호',
  '대표 이미지',
  '스냅 등록수',
  '상품 등록수',
  '사업자 정보',
  '셀러 승인',
  '셀러 반려',
]

const SellerTable = ({
  sellers,
  setSellers,
  checked,
  setChecked,
  clickHandler,
  clickApproveBtn,
  handleOpenReject,
}) => {
  const isAllChecked = sellers.every((seller) =>
    checked.map((o) => o.id).includes(seller.id)
  )

  const btnInfos = [
    { text: '확인', color: '#00a52d', bg: '#baffd5', onClick: () => {} },
    {
      text: '승인',
      color: '#003bff',
      bg: '#bacaff',
      onClick: (seller) => {
        setChecked([seller])
        clickApproveBtn([seller])
      },
    },
    {
      text: '반려',
      color: '#ff0000',
      bg: '#fff0f0',
      onClick: (seller) => {
        setChecked([seller])
        handleOpenReject()
      },
    },
  ]

  const handleGrade = async (seller, grade) => {
    await updateGrade(seller.id, grade)
    seller.grade = grade
    setSellers([...sellers])
  }

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  setChecked([])
                } else {
                  setChecked([...sellers])
                }
              }}
            />
          </Th>
          {heads.map((head, idx) => (
            <Th key={`${head}_${idx}`}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sellers.map((seller, idx) => {
          return (
            <tr
              key={`${seller.id}_${idx}`}
              style={{ borderBottom: '1px solid #ddd', height: '100px' }}
            >
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    checked={checked.map((o) => o.id).includes(seller.id)}
                    onClick={(e) => {
                      if (checked.map((o) => o.id).includes(seller.id)) {
                        const idx = checked.findIndex((o) => o.id === seller.id)
                        checked.splice(idx, 1)
                      } else {
                        checked.push(seller)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Box>
              </Td>

              <Td>{wrapperTxt(seller.approveStatus)}</Td>
              <Td width="80px" height="100px">
                <FormControl sx={{ height: '40px' }}>
                  <Select
                    value={seller.grade}
                    onChange={(e) => {
                      handleGrade(seller, e.target.value)
                    }}
                  >
                    {SELLER_GRADE.map(({ name, value }, idx) => (
                      <MenuItem value={value} key={idx}>
                        <p>{name}</p>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Td>
              <Td style={{ width: '300px' }}>{seller.user.email}</Td>
              <Td style={{ width: '120px' }}>{seller.name}</Td>
              <Td style={{ width: '120px' }}>{seller.managerName}</Td>
              <Td style={{ width: '180px' }}>{seller.managerNumber}</Td>
              <Td style={{ width: '100px' }}>
                <Image
                  alt="seller"
                  src={getProfileImgSrc(seller.profileImg)}
                  width="50px"
                  height="50px"
                />
              </Td>
              <Td>{seller.postsCnt}</Td>
              <Td>{seller.sellingProductsCnt}</Td>
              {btnInfos.map(({ text, color, bg, onClick }, index) => (
                <Td
                  key={`${seller}_${idx}_${index}`}
                  style={{ width: '100px' }}
                >
                  <Button
                    width="56px"
                    height="36px"
                    bg={bg}
                    color={color}
                    bold
                    borderRadius="10px"
                    onClick={(e) => {
                      if (index % 3 === 0) clickHandler(seller.id)
                      else onClick(seller)
                    }}
                  >
                    <p>{text}</p>
                  </Button>
                </Td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default SellerTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const wrapperTxt = (status) => {
  if (status === 'APPROVED') {
    return 'Y'
  } else if (status === 'WAITING') {
    return 'N'
  }
  return 'Reject'
}
