import { instance } from './instance'

export const findPromotion = async (id) => {
  try {
    const { data } = await instance.get(`/api/promotion/${id}/admin`, {
      token: true,
    })
    return { success: true, data: data.data }
  } catch (error) {
    return { success: false }
  }
}

export const createPromotion = async (body) => {
  try {
    delete body.posts
    delete body.products
    delete body.topPosts
    const data = await instance.post(`/api/promotion`, body, {
      token: true,
    })

    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}

export const updatePromotion = async (body) => {
  try {
    delete body.posts
    delete body.products
    delete body.topPosts
    await instance.put(`/api/promotion`, body, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const removePromotion = async (ids) => {
  const query = ids.map((id) => `promotionIds=${id}`).join('&')
  try {
    await instance.delete(`/api/promotion?${query}`, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const setOrderNumber = async (body) => {
  try {
    await instance.post('/api/promotion/order-number', body, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const getPromotions = async (queryObj) => {
  let query = ''
  if (queryObj) {
    query = Object.entries(queryObj)
      .filter((o) => o[1])
      .reduce((pre, cur) => pre + `${cur[0]}=${cur[1]}&`, '')
  }

  try {
    const { data } = await instance.get(`/api/promotion?${query}`, {
      token: true,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const getImgUrls = async (imgFiles) => {
  try {
    const formData = new FormData()
    formData.append('type', 'promotion')
    for (const file of imgFiles) {
      const newFileName = `promotion.${file.name.split('.').pop()}`
      const newFile = new File([file], newFileName, { type: file.type })
      formData.append('files', newFile)
    }

    const ret = await instance.post(`/api/image/uploads`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      token: true,
    })

    return {
      success: true,
      data: ret.data.data.map((o) => o.url),
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}
