import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { removeReserveAlarm } from '../../api/alarm'
import { Button } from '../../element/Button'
import Image from '../../element/Image'
import { getDate } from '../../utils'

const heads = [
  '등록일시',
  '프로모션id',
  '제목',
  '본문',
  '이미지',
  '발송일시',
  '발송여부',
  '수정',
  '삭제',
]

const AlarmListTable = ({ alarms, setAlarms, setOption, setEditId }) => {
  const navigation = useNavigate()
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {alarms.map((alarm) => {
          const {
            id,
            createdAt,
            sendAt,
            isSent,
            title,
            content,
            thumbImg,
            detailId,
          } = alarm

          return (
            <tr
              key={id}
              style={{
                borderBottom: '1px solid #ddd',
                height: '100px',
              }}
            >
              <Td>{getDate(createdAt)}</Td>
              <Td
                style={{ cursor: detailId ? 'pointer' : '' }}
                onClick={() => {
                  detailId && navigation(`/promo/register?id=${detailId}`)
                }}
              >
                {detailId ? detailId : ''}
              </Td>
              <Td>{title}</Td>
              <Td>{content}</Td>
              <Td width={150}>
                <Image
                  alt=""
                  src={thumbImg}
                  width="100px"
                  height="100px"
                  border="1px solid #E4E4E4"
                  padding="2px"
                />
              </Td>
              <Td>{getDate(sendAt, null, -13)}</Td>
              <Td>{isSent ? '발송완료' : ''}</Td>
              <Td style={{ width: '150px' }}>
                <Button
                  height="36px"
                  bg="#bacaff"
                  color="#003bff"
                  bold
                  borderRadius="10px"
                  onClick={() => {
                    setEditId(id)
                  }}
                >
                  <p>수정</p>
                </Button>
              </Td>
              <Td style={{ width: '150px' }}>
                <Button
                  height="36px"
                  bg="#FFF0F0"
                  color="#FF0000"
                  bold
                  borderRadius="10px"
                  onClick={async () => {
                    const ask = window.confirm('알람을 삭제하시겠습니까?')
                    if (!ask) return

                    await removeReserveAlarm(id)
                    const filtered = alarms.filter((alarm) => alarm.id !== id)
                    setAlarms(filtered)
                    setOption((prev) => ({ ...prev, count: prev.count - 1 }))
                  }}
                >
                  <p>삭제</p>
                </Button>
              </Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default AlarmListTable

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled.td`
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  width: ${(props) => props.width || 'auto'};

  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`
