export const MAX_IMAGE_SIZE_MB = 20
export const MAX_IMAGE_SIZE = MAX_IMAGE_SIZE_MB * 1024 * 1024 // 20MB

export const BULK_IMAGE_LENGTH = 200
export const BULK_IMAGE_TOTAL_SIZE_GB =
  (MAX_IMAGE_SIZE_MB * BULK_IMAGE_LENGTH) / 1000

export const SNAP_IMAGE_LENGTH = 10

export const PRODUCT_IMAGE_LENGTH = 10
export const PRODUCT_MORE_IMAGE_LENGTh = 20
