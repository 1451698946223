import styled from 'styled-components'

import { getDate } from '../../utils'
import { useNavigate } from 'react-router-dom'

const PartnersNoticeTable = ({ notices }) => {
  const navigation = useNavigate()
  const clickCell = (id) => {
    navigation(`/notice/${id}?type=seller`)
  }
  return (
    <Table>
      <tbody>
        {notices.map((notice, index) => {
          return (
            <tr
              key={index}
              style={{
                borderBottom: '1px solid #ddd',
                height: 120,
              }}
              onClick={() => {
                clickCell(notice.id)
              }}
            >
              <Td style={{ textAlign: 'left', paddingLeft: '30px' }}>
                {notice.title}
              </Td>

              <Td width="200px">{getDate(notice.createdAt)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default PartnersNoticeTable

const Table = styled('table')({
  width: '100%',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})
