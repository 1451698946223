import 'swiper/css'

import { Box, Divider, Modal, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import CloseIcon from '@mui/icons-material/Close'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'
import { getNotice } from '../../api/notice'
import { getPost } from '../../api/post'
import { getProduct } from '../../api/product'
import { getSeller } from '../../api/seller'
import { Button } from '../../element/Button'
import Image from '../../element/Image'
import useComponentSize from '../../hooks/common/utils/useComponentSize'
import { REAL_TAG_DOT_SIZE } from '../snap/ProductLocs'

export const IModal = ({ open, onClose, buttonGroup, content }) => {
  return (
    <Modal onClose={onClose} open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '480px',
          bgcolor: 'white',
          borderRadius: '20px',
          boxSizing: 'border-box',
          padding: '25px',
          height: '90vh',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
          <Box>{buttonGroup}</Box>
        </Box>
        <Box sx={{ height: '20px' }} />
        {content}
      </Box>
    </Modal>
  )
}

/**
 * Post MODAL
 */

export const PreviewPostModal = ({
  id,
  open,
  onClose,
  posts,
  approve,
  setPreview,
}) => {
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState({
    seller: {},
    products: [],
    modelSizes: [],
  })
  const navigation = useNavigate()

  useEffect(() => {
    ;(async () => {
      setLoading(false)
      try {
        const response = await getPost(id)
        setPost(response.data.post)
        setLoading(true)
      } catch (error) {
        setPost(null)
        setLoading(false)
      }
    })()
  }, [id])

  if (!loading && !post) return <></>

  return (
    <IModal
      key={id}
      open={open}
      onClose={onClose}
      buttonGroup={
        <>
          <Button
            width="56px"
            height="36px"
            bg="#BAFFD5"
            color="#00A52D"
            bold
            borderRadius="10px"
            sx={{ marginRight: '20px' }}
            onClick={() => {
              navigation(`/snap/add/${id}`)
            }}
          >
            수정
          </Button>
          <Button
            height="40px"
            bg="#BACAFF"
            color="#003BFF"
            bold
            borderRadius="10px"
            sx={{ marginRight: '20px' }}
            onClick={() => {
              const find = posts.filter((p) => p.id === id)[0]
              approve('HIDE', [find], !find.isHide)
            }}
          >
            <p>
              {posts.filter((p) => p.id === id)[0].isHide ? '해제' : '가리기'}
            </p>
          </Button>

          <Button
            height="40px"
            bg="#fff0f0"
            color="#ff0000"
            bold
            borderRadius="10px"
            onClick={() => {
              const find = posts.filter((p) => p.id === id)[0]
              approve('DEL', [find])
              setPreview(false)
            }}
          >
            <p>삭제</p>
          </Button>
        </>
      }
      content={<PreviewSnap post={post} />}
    />
  )
}

const IMAGE_SIZE = 430
export const PreviewSnap = ({ post, isViewLoc }) => {
  const [imageIndex, setImageIndex] = useState(0)
  const { products, seller, productLocs } = post

  const uniqueProducts = []
  const uniqueId = []

  for (let product of products) {
    if (!uniqueId.includes(product.id)) {
      uniqueId.push(product.id)
      uniqueProducts.push(product)
    }
  }

  const currentTag = productLocs
    ?.filter((l) => l.imgIndex === imageIndex)
    .map((l) => {
      const product = uniqueProducts.filter((p) => p.id === l.pid).pop()
      return { ...l, product }
    })

  const handler = {
    swipe: (s) => setImageIndex(s.snapIndex),
  }

  return (
    <Box>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <Image
          alt="seller profile"
          src={seller?.profileImg ?? ''}
          width={36}
          height={36}
          borderRadius={36}
        />
        <Box sx={{ width: '20px' }} />
        <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
          {seller.name}
        </Typography>
      </Box>
      <Box sx={{ height: 10 }} />
      <Swiper onSlideChange={handler.swipe}>
        {post?.imgs?.map((img, index) => {
          return (
            <SwiperSlide key={img}>
              <Box width={IMAGE_SIZE} height={IMAGE_SIZE} position="relative">
                <Image
                  src={post.imgs?.[index] ?? ''}
                  alt="snap"
                  width={IMAGE_SIZE}
                  height={IMAGE_SIZE}
                  position="absolute"
                />
                {isViewLoc &&
                  currentTag?.map((loc) => {
                    return (
                      <PreviewProductLocs loc={loc} key={`${loc.pid}_loc`} />
                    )
                  })}
              </Box>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Box
        sx={{
          margin: '15px',
          borderTop: '1px solid #e4e4e4',
          borderBottom: '1px solid #e4e4e4',
          boxSizing: 'border-box',
        }}
      >
        <Stack direction="column" padding="10px">
          <Typography fontSize={14} fontWeight={500}>
            Product Info.
          </Typography>
          {uniqueProducts?.map((product) => (
            <Box
              key={`${product.id}_product`}
              sx={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Image
                src={product.imgs?.[0]}
                alt="product"
                width={50}
                height={50}
                border="1px solid #e4e4e4"
                borderRadius={10}
              />
              <Box sx={{ width: '10px' }} />
              <Box>
                <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
                  {product?.name}
                </Typography>
                <Typography sx={{ fontWeight: 700 }}>
                  ${product?.price}
                </Typography>
              </Box>
            </Box>
          ))}
          <PostModelInfo post={post} />
        </Stack>
      </Box>
      <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
        <span style={{ fontWeight: 700 }}>{seller?.name} </span>
        {post?.content}
      </Typography>
    </Box>
  )
}

const PostModelInfo = ({ post }) => {
  const modelSizes = []
  for (const size of post.modelSizes) {
    const convert = size.split(':')
    if (convert[1] === '') continue
    modelSizes.push(`${convert[0].toLowerCase()} : ${convert[1]}`)
  }

  for (let i = 0; i < modelSizes.length - 1; i++) {
    modelSizes[i] = `${modelSizes[i]}ㅤ·ㅤ`
  }

  if (
    (post.modelHeight === null || post.modelHeight === 0) &&
    (post.modelWeight === null || post.modelWeight === 0) &&
    modelSizes.length === 0
  ) {
    return <></>
  }

  const getFeet = () => {
    if (post.modelHeight === null) return ''
    return (post.modelHeight * 0.0328084).toFixed(2)
  }

  const getPound = () => {
    if (post.modelWeight === null) return ''
    return (post.modelWeight * 2.20462).toFixed(2)
  }

  return (
    <>
      <Box height={20} />
      <Typography fontSize={14} fontWeight={500}>
        model info.
      </Typography>
      {post.modelHeight !== null && post.modelHeight !== 0 && (
        <>
          <Box height={4} />
          <Stack direction="row" alignItems="center">
            <Typography width={50} fontSize={13} color="#707070">
              height.
            </Typography>
            <Typography fontSize={14}>{post.modelHeight} cm</Typography>
            <Typography fontSize={12}>&nbsp;/&nbsp;</Typography>
            <Typography fontSize={14}>{getFeet()} ft</Typography>
          </Stack>
        </>
      )}
      {post.modelWeight !== null && post.modelWeight !== 0 && (
        <>
          <Box height={4} />
          <Stack direction="row" alignItems="center">
            <Typography width={50} fontSize={13} color="#707070">
              weight.
            </Typography>
            <Typography fontSize={14}>{post.modelWeight} kg</Typography>
            <Typography fontSize={12}>&nbsp;/&nbsp;</Typography>
            <Typography fontSize={14}>{getPound()} lbs</Typography>
          </Stack>
        </>
      )}
      {modelSizes.length !== 0 ? (
        <>
          <Box height={4} />
          <Stack direction="row">
            <Typography width={50} fontSize={13} color="#707070">
              size.
            </Typography>
            <Stack direction="row" flex={1} flexWrap="wrap">
              {modelSizes.map((size) => {
                return (
                  <Typography wordBreak="keep-all" fontSize={14} key={size}>
                    {size}
                  </Typography>
                )
              })}
            </Stack>
          </Stack>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

const PreviewProductLocs = ({ loc }) => {
  const [ref, size] = useComponentSize()
  const { product, x, y } = loc

  const productName =
    product.name.length <= 15 ? product.name : `${product.name.slice(0, 13)}...`

  const { price, discountedPrice } = product

  const isDiscount = price !== discountedPrice

  const rate = isDiscount
    ? (((price - discountedPrice) / price) * 100).toFixed(0)
    : 0

  const tagXLocation = () => {
    const width = size.width === 0 ? IMAGE_SIZE / 2 : size.width
    let X = IMAGE_SIZE * x - REAL_TAG_DOT_SIZE
    const HALF = IMAGE_SIZE / 2
    if (X > HALF) X += REAL_TAG_DOT_SIZE * 2
    else X -= width

    const isUnderflow = X < 0
    const isOverflow = X + width > IMAGE_SIZE

    if (isUnderflow) return 0
    else if (isOverflow) return IMAGE_SIZE - width
    else return X
  }

  const tagYLocation = () => {
    const height = size.height
    let Y = IMAGE_SIZE * y - height / 2

    const isUnderflow = Y - height / 2 < 0
    const isOverflow = Y + height > IMAGE_SIZE

    if (isUnderflow) return 0
    else if (isOverflow) return IMAGE_SIZE - height
    else return Y
  }

  return (
    <Stack
      position="absolute"
      left={tagXLocation()}
      top={tagYLocation()}
      borderRadius="5px"
      bgcolor="#f2f2f2D9" // #f2f2f2 opacity 0.85
      padding={0.8}
      direction="row"
      gap={1}
      alignItems="center"
      ref={ref}
    >
      <Image src={product?.imgs?.[0]} width={30} height={30} />
      <Stack direction="column">
        <Typography fontSize={13} wordbreak="keep-all">
          {productName}
        </Typography>
        <Typography
          fontSize={isDiscount ? 12 : 14}
          color={isDiscount ? '#a3a3a3' : null}
          sx={{ textDecoration: isDiscount ? 'line-through' : null }}
          fontWeight={isDiscount ? null : 700}
          wordbreak="keep-all"
        >
          ${product.price.toLocaleString()}
        </Typography>
        {isDiscount && (
          <Stack direction="row" gap={0.4}>
            <Typography fontSize={14} fontWeight={700} wordbreak="keep-all">
              ${discountedPrice.toLocaleString()}
            </Typography>
            <Typography fontSize={14} color={'#f44336'} wordbreak="keep-all">
              {`${rate}% off`}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

/**
 * Product Modal
 */
export const PreviewProductModal = ({
  id,
  open,
  onClose,
  products,
  approve,
}) => {
  const navigation = useNavigate()

  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState(null)

  useEffect(() => {
    ;(async () => {
      setLoading(false)
      try {
        const response = await getProduct(id)
        setProduct(response.product)
        setLoading(true)
      } catch (error) {
        setProduct(null)
        setLoading(false)
      }
    })()
  }, [])

  if (!loading && !product) return <></>

  return (
    <IModal
      open={open}
      onClose={onClose}
      buttonGroup={
        <Box sx={{ gap: '10px', display: 'flex' }}>
          <Button
            width="56px"
            height="36px"
            bg="#BACAFF"
            color="#003bff"
            bold
            borderRadius="10px"
            onClick={() => {
              const find = products.filter((p) => p.id === id)[0]
              approve('APPROVED', [find])
            }}
          >
            승인
          </Button>
          <Button
            width="56px"
            height="36px"
            bg="#BAFFD5"
            color="#00A52D"
            bold
            borderRadius="10px"
            onClick={() => {
              navigation(`/product/add?type=modify&pid=${id}`)
            }}
          >
            수정
          </Button>
          <Button
            width="56px"
            height="36px"
            bg="#FFF0F0"
            color="#FF0000"
            bold
            borderRadius="10px"
            onClick={() => {
              const find = products.filter((p) => p.id === id)[0]
              approve('REJECT', [find])
            }}
          >
            반려
          </Button>
        </Box>
      }
      content={<PreviewProduct product={product} />}
    />
  )
}

const PreviewProduct = ({ product }) => {
  const { sizeGuide, description } = product

  const DESCRIPTION = sizeGuide?.[sizeGuide.length - 1]
  const table = sizeGuide?.filter((e) => e !== DESCRIPTION)
  const leftSide = table.map((e) => e[0].split(':')[1])
  const rightSide = table.map((e) => e.slice(1, e.length))
  const columns = []

  for (let i = 0; i < rightSide[0].length; i += 1) {
    const column = []
    for (let j = 0; j < rightSide.length; j += 1) {
      const origin = rightSide[j][i]
      const spliter = origin.split(':')
      const SIZE = spliter[0]
      const VALUE = spliter[1]
      if (j === 0) column.push(SIZE)
      column.push(VALUE)
    }
    columns.push(column)
  }

  let moreFeatures = product.textiles
  let lining = moreFeatures.filter((e) => e.includes('Lining'))?.[0]
  let stretchiness = moreFeatures.filter((e) => e.includes('Stretchiness'))?.[0]
  let transparency = moreFeatures.filter((e) => e.includes('Transparency'))?.[0]
  let texture = moreFeatures.filter((e) => e.includes('Texture'))?.[0]
  let fit = moreFeatures.filter((e) => e.includes('Fit'))?.[0]
  let thickness = moreFeatures.filter((e) => e.includes('Thickness'))?.[0]

  const MORE_FEATURE_TAG_WIDTH = 43 * 3
  const DIVIDER_THICK = 6

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box width={430}>
        <Swiper>
          {product?.imgs?.map((img, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={img ?? ''}
                  alt="snap"
                  style={{
                    width: '430px',
                    height: '430px',
                    objectFit: 'cover',
                  }}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Box>
      <Box sx={{ height: '10px' }} />
      <Typography sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}>
        {product.seller.name}
      </Typography>
      <Typography sx={{ fontWeight: 700 }}>{product.name}</Typography>

      {product.discountRateUS ? (
        <>
          <Typography
            sx={{
              fontSize: 14,
              textDecoration: 'line-through',
            }}
          >
            ${product.price}
          </Typography>
          <Box display="flex" gap={1}>
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}>
              ${product.discountedPrice}
            </Typography>
            <Typography sx={{ fontWeight: 700, color: 'red' }}>
              {product.discountRateUS}% off
            </Typography>
          </Box>
        </>
      ) : (
        <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
          ${product.price}
        </Typography>
      )}

      <Box sx={{ height: '20px' }} />
      <Divider sx={{ borderBottomWidth: DIVIDER_THICK }} />
      <Box sx={{ height: '20px' }} />
      <Typography
        sx={{
          color: '#a3a3a3',
          fontWeight: 700,
          fontSize: 14,
          wordWrap: 'break-word',
        }}
      >
        DESCRIPTION
      </Typography>
      <Box sx={{ height: '5px' }} />
      <Typography sx={{ fontSize: 14, whiteSpace: 'pre-line' }}>
        {description}
      </Typography>
      <Box sx={{ height: '20px' }} />
      <Divider sx={{ borderBottomWidth: DIVIDER_THICK }} />
      <Box sx={{ height: '20px' }} />
      <Typography sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}>
        CARE INSTRUCTION
      </Typography>
      <Box sx={{ height: '10px' }} />
      <Typography
        sx={{ fontSize: 14, wordWrap: 'break-word', whiteSpace: 'pre-line' }}
      >
        {product.careInstruction}
      </Typography>
      <Box sx={{ height: '20px' }} />
      <Divider sx={{ borderBottomWidth: DIVIDER_THICK }} />
      <Box sx={{ height: '20px' }} />
      <Typography sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}>
        SIZE GUIDE
      </Typography>
      {product.sizeGuideImgs[0] && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={product.sizeGuideImgs[0]} alt="sizeGuide" width={215} />
        </Box>
      )}
      <Box sx={{ height: '10px' }} />
      <>
        <Stack direction="row">
          <Box sx={{ flexDirection: 'column' }}>
            {['SIZE', ...leftSide].map((l, index) => {
              return (
                <Box
                  key={`${l}_${index}`}
                  sx={{
                    border: '1px solid #e4e4e4',
                    bgcolor: '#f2f2f2',
                    width: 100,
                    padding: '5px',
                    height: 36,
                  }}
                >
                  <Typography textAlign={'center'}>{l}</Typography>
                </Box>
              )
            })}
          </Box>
          <Stack
            direction="row"
            sx={{
              overflowX: 'scroll',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}
          >
            {columns.map((col, index) => {
              return (
                <Stack direction="column" key={index}>
                  {col.map((c, idx) => {
                    return (
                      <Box
                        key={`${c}_${index}_${idx}`}
                        sx={{
                          border: '1px solid #e4e4e4',
                          bgcolor: idx === 0 ? '#f2f2f2' : 'white',
                          width: 100,
                          padding: '5px',
                          height: 36,
                        }}
                      >
                        <Typography textAlign={'center'}>{c}</Typography>
                      </Box>
                    )
                  })}
                </Stack>
              )
            })}
          </Stack>
        </Stack>
        <Box
          sx={{
            border: '1px solid #e4e4e4',
            width: 430,
            padding: '5px',
            height: 36,
          }}
        >
          <Typography textAlign="center">
            The size may differ by 1~3cm
          </Typography>
        </Box>
      </>

      <Box sx={{ height: '20px' }} />
      <Divider sx={{ borderBottomWidth: DIVIDER_THICK }} />
      <Box sx={{ height: '20px' }} />
      <Typography sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}>
        MORE FEATURES
      </Typography>
      <Box sx={{ height: '10px' }} />
      <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
        {product?.materialMixingRate && (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Box sx={{ width: MORE_FEATURE_TAG_WIDTH }}>
              <Typography
                sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}
              >
                MaterialMixingRate
              </Typography>
            </Box>
            <Box
              sx={{
                width: 43 * 7,
                flexWrap: 'wrap',
                flexDirection: 'row',
                display: 'flex',
                gap: '10px',
              }}
            >
              <Typography fontWeight={700} paddingX={0.5}>
                {product.materialMixingRate}
              </Typography>
            </Box>
          </Box>
        )}
        {product.seasons.length !== 0 && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: MORE_FEATURE_TAG_WIDTH }}>
              <Typography
                sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}
              >
                Season
              </Typography>
            </Box>
            <Box
              sx={{
                width: 43 * 7,
                flexWrap: 'wrap',
                flexDirection: 'row',
                display: 'flex',
                gap: '10px',
              }}
            >
              {product.seasons.map((s, index) => (
                <ProductFeature id={s.value} text={s.value} key={index} />
              ))}
            </Box>
          </Box>
        )}
        {lining?.split(':')[1] && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: MORE_FEATURE_TAG_WIDTH }}>
              <Typography
                sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}
              >
                Lining
              </Typography>
            </Box>
            <Box>
              <ProductFeature text={lining?.split(':')[1]} />
            </Box>
          </Box>
        )}
        {stretchiness?.split(':')[1] && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: MORE_FEATURE_TAG_WIDTH }}>
              <Typography
                sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}
              >
                Stretchiness
              </Typography>
            </Box>
            <Box>
              <ProductFeature text={stretchiness?.split(':')[1]} />
            </Box>
          </Box>
        )}
        {transparency?.split(':')[1] && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: MORE_FEATURE_TAG_WIDTH }}>
              <Typography
                sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}
              >
                Transparency
              </Typography>
            </Box>
            <Box>
              <ProductFeature text={transparency?.split(':')[1]} />
            </Box>
          </Box>
        )}
        {texture?.split(':')[1] && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: MORE_FEATURE_TAG_WIDTH }}>
              <Typography
                sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}
              >
                Texture
              </Typography>
            </Box>
            <Box>
              <ProductFeature text={texture?.split(':')[1]} />
            </Box>
          </Box>
        )}
        {fit?.split(':')[1] && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: MORE_FEATURE_TAG_WIDTH }}>
              <Typography
                sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}
              >
                Fit
              </Typography>
            </Box>
            <Box>
              <ProductFeature text={fit?.split(':')[1]} />
            </Box>
          </Box>
        )}
        {thickness?.split(':')[1] && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: MORE_FEATURE_TAG_WIDTH }}>
              <Typography
                sx={{ color: '#a3a3a3', fontWeight: 700, fontSize: 14 }}
              >
                Thickness
              </Typography>
            </Box>
            <Box>
              <ProductFeature text={thickness?.split(':')[1]} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const ProductFeature = ({ text }) => {
  return (
    <Box
      sx={{
        bgcolor: 'black',
        padding: '5px 10px',
        borderRadius: '10px',
      }}
    >
      <Typography sx={{ color: 'white', fontSize: 12, fontWeight: 700 }}>
        {text}
      </Typography>
    </Box>
  )
}

/**
 * Seller Modal
 */
export const PreviewSellerModal = ({
  id,
  open,
  onClose,
  sellers,
  clickApproveBtn,
  setPreview,
  setChecked,
  handleOpenReject,
}) => {
  const [loading, setLoading] = useState(false)
  const [seller, setSeller] = useState(null)

  useEffect(() => {
    ;(async () => {
      setLoading(false)
      try {
        const response = await getSeller(id)
        setSeller(response.data)
        setLoading(true)
      } catch (error) {
        setLoading(false)
      }
    })()
  }, [])

  if (!loading && !seller) return <></>

  return (
    <IModal
      open={open}
      onClose={onClose}
      buttonGroup={
        <Box sx={{ gap: '10px', display: 'flex' }}>
          <Button
            width="56px"
            height="36px"
            bg="#bacaff"
            color="#003bff"
            bold
            borderRadius="10px"
            onClick={() => {
              const find = sellers.filter((s) => s.id)[0]
              clickApproveBtn([find])
              setPreview(false)
            }}
          >
            승인
          </Button>
          <Button
            width="56px"
            height="36px"
            bg="#fff0f0"
            color="#ff0000"
            bold
            borderRadius="10px"
            onClick={() => {
              const find = sellers.filter((s) => s.id)[0]
              setChecked([find])
              handleOpenReject()
              setPreview(false)
            }}
          >
            반려
          </Button>
        </Box>
      }
      content={<PreviewSeller seller={seller} />}
    />
  )
}

const PreviewSeller = ({ seller }) => {
  const getType = (type) => {
    switch (type) {
      case 'INDIVIDUAL_NORMAL':
        return '일반 사업자'
      case 'INDIVIDUAL_EASE':
        return '간이 사업자'
      case 'CORPORATE':
        return '법인 사업자'
      default:
        return ''
    }
  }

  const base64String = arrayBufferToBase64(seller.bankInfo.accountImgData.data)
  const accountImg = `data:image/jpeg;base64,${base64String}`

  return (
    <Box>
      <PreviewSellerRow
        title="사업자 유형"
        text={getType(seller?.businessInfo?.type)}
      />
      <Box sx={{ height: '20px' }} />
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
        사업자 정보
      </Typography>
      <Box sx={{ height: '20px' }} />
      <Box sx={{ paddingLeft: '40px' }}>
        <PreviewSellerRow
          title="대표자명"
          text={seller?.businessInfo?.representativeName}
        />
        <Box sx={{ height: '20px' }} />
        <PreviewSellerRow
          title="사업자등록증 상호명"
          text={seller?.businessInfo?.businessName}
        />
        <Box sx={{ height: '20px' }} />
        <PreviewSellerRow
          title="사업자 등록번호"
          text={seller?.businessInfo?.businessNumber}
        />
        <Box sx={{ height: '20px' }} />
        <PreviewSellerRow
          title="개업연월일"
          text={seller?.businessInfo?.openingDate}
        />
        <Box sx={{ height: '20px' }} />
        <PreviewSellerRow
          title="업태"
          text={seller?.businessInfo?.businessConditions}
        />
        <Box sx={{ height: '20px' }} />
        <PreviewSellerRow
          title="사업장주소"
          text={seller?.businessInfo?.businessLocation}
        />
      </Box>
      <Box sx={{ height: '20px' }} />
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
        사업자 등록증
      </Typography>
      <Box sx={{ height: '20px' }} />
      <Image
        src={seller?.businessInfo?.businessLicenseImg}
        alt=""
        width={430}
        height={430}
      />
      <Box sx={{ height: '20px' }} />
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>정산 정보</Typography>
      <Box sx={{ height: '20px' }} />
      <Box sx={{ paddingLeft: '40px' }}>
        <PreviewSellerRow title="은행" text={seller?.bankInfo?.bankName} />
        <Box sx={{ height: '20px' }} />
        <PreviewSellerRow
          title="예금주명"
          text={seller?.bankInfo?.accountHolder}
        />
        <Box sx={{ height: '20px' }} />
        <PreviewSellerRow
          title="계좌번호"
          text={seller?.bankInfo?.accountNumber}
        />
        <Box sx={{ height: '20px' }} />
        <PreviewSellerRow title="이메일 주소" text={seller?.bankInfo?.email} />
      </Box>
      <Box sx={{ height: '20px' }} />
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>통장 사본</Typography>
      <Box sx={{ height: '20px' }} />
      <Image src={accountImg} alt="" width={430} height={430} />
    </Box>
  )
}

const PreviewSellerRow = ({ title, text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>{title}</Typography>
      <Box width={230}>
        <Typography sx={{ fontSize: 14 }}>{text}</Typography>
      </Box>
    </Box>
  )
}

/**
 * FAQ Modal
 */
export const PreviewFAQModal = ({ id, open, onClose, buttonGroup }) => {
  const [loading, setLoading] = useState(false)
  const [faq, setFaq] = useState(null)

  useEffect(() => {
    setLoading(false)
    ;(async () => {
      try {
        const data = await getNotice(id)
        setFaq(data)
        setLoading(true)
      } catch (error) {}
    })()
  }, [])

  if (!loading) return <></>

  return (
    <IModal
      open={open}
      onClose={onClose}
      buttonGroup={buttonGroup}
      content={<PreviewFAQ faq={faq} />}
    />
  )
}

const PreviewFAQ = ({ faq }) => {
  return (
    <Box>
      <Box sx={{ height: '20px' }} />
      <Typography sx={{ fontWeight: 700 }}>{faq.title}</Typography>
      <Typography sx={{ color: '#707070' }}>{faq.type}</Typography>
      <Box sx={{ height: '20px' }} />
      <Divider />
      <Box sx={{ height: '20px' }} />
      <ReactQuill
        theme="bubble"
        modules={MODULE_QUILL}
        name="content"
        value={faq.content}
        readOnly
      />
      <Box sx={{ height: '40px' }} />
      {faq.imgs.map((img, index) => (
        <Image
          key={img}
          src={img}
          width={'100%'}
          style={{
            marginBottom: index !== faq.imgs.length - 1 ? '10px' : '0',
          }}
          alt="faq"
        />
      ))}
      <Box sx={{ height: '20px' }} />
    </Box>
  )
}

const MODULE_QUILL = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
}

function arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
