import { Divider } from '@mui/material'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import PromotionListTable from '../../components/promotion/PromotionListTable'
import { getPromotions, setOrderNumber } from '../../api/promotion'
import Navigation from '../../components/common/navigation'
import { CheckSnackbar } from '../../components/Snackbar'
import { CampaignType } from './promoRegister'

const PromotionAdminPage = () => {
  // top, post, product
  const [option, setOption] = useState({
    type: null,
    keyword: '',
    count: 0,
    page: 1,
  })

  const [promos, setPromos] = useState([])

  useEffect(() => {
    ;(async () => {
      const data = await getPromotions(option)
      setPromos(data.data)
      setOption((prev) => ({ ...prev, count: data.count }))
    })()
  }, [option.page, option.type])

  const search = async () => {
    const data = await getPromotions(option)
    setPromos(data.data)
    setOption((prev) => ({ ...prev, count: data.count }))
  }

  const promoType = [
    {
      name: '전사프로모션',
      value: CampaignType.Global,
      onClick: () => {
        if (option.type === CampaignType.Global) {
          setOption({ ...option, type: null, page: 1 })
        } else {
          setOption({ ...option, type: CampaignType.Global, page: 1 })
        }
      },
    },
    {
      name: '브랜드',
      value: CampaignType.Brand,
      onClick: () => {
        if (option.type === CampaignType.Brand) {
          setOption({ ...option, type: null, page: 1 })
        } else {
          setOption({ ...option, type: CampaignType.Brand, page: 1 })
        }
      },
    },
    {
      name: '모음전',
      value: CampaignType.Showcase,
      onClick: () => {
        if (option.type === CampaignType.Showcase) {
          setOption({ ...option, type: null, page: 1 })
        } else {
          setOption({ ...option, type: CampaignType.Showcase, page: 1 })
        }
      },
    },
  ]

  const storeItems = async () => {
    const { success } = await setOrderNumber({
      promotionIds: promos.map((p) => p.id),
      orderNumbers: promos.map((p) => p.orderNumber),
      statuses: promos.map((p) => p.status),
    })
    if (success) {
      setOpen(true)
    } else {
      setOpenErr(true)
    }
  }

  const [open, setOpen] = useState(false)
  const [openErr, setOpenErr] = useState(false)

  return (
    <DefaultLayout isLoggedIn>
      <CheckSnackbar open={open} setOpen={setOpen} icon={true} />
      <CheckSnackbar
        open={openErr}
        setOpen={setOpenErr}
        icon={true}
        color="red"
        message="기획전 순서가 정확히 입력되지 않았습니다."
      />
      <Box sx={boxStyle}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            기획전
          </p>
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox>
            <p>기획전 타입 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={1}>
              {promoType.map(({ name, value, onClick }, idx) =>
                value === option.type ? (
                  <Button
                    key={idx}
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                ) : (
                  <Button
                    key={idx}
                    color="#707070"
                    bg="white"
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                )
              )}
            </Box>
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p>기획전 검색 :</p>
          </LeftBox>

          <InputTextField
            width="500px"
            label=""
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            onChange={(e) => {
              setOption((prev) => ({ ...prev, keyword: e.target.value }))
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                search()
              }
            }}
          />
        </OptionContainer>
        <Box height={50} />
        <Box width={1400}>
          <PromotionListTable
            items={promos}
            setItems={setPromos}
            checked={[]}
            storeItems={storeItems}
          />
          <Navigation
            page={option.page}
            count={option.count}
            setPage={(page) => {
              setOption((prev) => ({ ...prev, page: page }))
            }}
          />
        </Box>
      </Box>
    </DefaultLayout>
  )
}
export default PromotionAdminPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

const LeftBox = ({ children, ai }) => (
  <Box
    width={100}
    display="flex"
    justifyContent={ai ? ai : 'center'}
    flexDirection="column"
    alignItems="end"
    marginRight={3}
    fontWeight={700}
    color="#707070"
  >
    {children}
  </Box>
)

const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)
