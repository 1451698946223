import { FormControlLabel, styled, useRadioGroup } from '@mui/material'

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  marginRight: '60px',
  '.MuiFormControlLabel-label': checked
    ? {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px',
      }
    : {
        color: '#707070',
        fontSize: '14px',
      },
}))

export function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup()

  let checked = false

  if (radioGroup) {
    checked = radioGroup.value === props.value
  }

  return <StyledFormControlLabel checked={checked} {...props} />
}
