import { Box, Typography } from '@mui/material'
import Image from './Image'

const OverwriteImage = ({
  src,
  alt,
  width,
  height,
  borderRadius,
  padding,
  margin,
  onClick,
  cursor,
  border,
  boxSizing,
  hoverStyle,
  onMouseEnter,
  onMouseLeave,
  onError,
  text,
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-block', width, height }}>
      <Image
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        src={src}
        alt={alt}
        width={width}
        height={height}
        borderRadius={borderRadius}
        border={border}
        padding={padding}
        margin={margin}
        boxSizing={boxSizing}
        cursor={cursor}
        hoverStyle={hoverStyle}
        onClick={onClick}
        onError={onError}
      />
      {text && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#ff0000', // 텍스트 색상
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // 배경 투명도 조정
            width: '100%',
            height: '100%',
            borderRadius,
            border,
          }}
        >
          <Typography fontWeight="bold">{text}</Typography>
        </Box>
      )}
    </Box>
  )
}

export default OverwriteImage
