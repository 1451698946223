import DefaultLayout from '../global/DefaultLayout'
import logo from '../static/bysnap.png'
import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { login } from '../api/auth'
import { useEffect, useState } from 'react'
import { isValidEmail, isValidPwd } from '../utils'
import { InputTextField } from '../element/InputTextField'
import { Text } from '../element/Text'
import { Button } from '../element/Button'
import { getSellerFromLocalStorage } from '../api/storage'
import { getToken } from '../api/cookie'

const HomePage = () => {
  const navigation = useNavigate()
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')

  useEffect(() => {
    const seller = getSellerFromLocalStorage()
    const token = getToken()
    if (Object.keys(token).length !== 0 && seller) {
      navigation('/snap')
    }
  }, [])

  const loginHandler = async () => {
    if (!isValidEmail(email)) {
      alert('올바르지 않은 이메일 형식입니다.')
      return
    }

    if (!isValidPwd(pwd)) {
      alert('올바르지 않은 비밀번호 형식입니다.')
      return
    }

    const { success, path } = await login(email, pwd)
    if (success) {
      navigation(path)
    } else {
      alert('로그인에 실패하였습니다.')
    }
  }

  const isIncorrectEmailForm = () => {
    if (email && !isValidEmail(email)) {
      return { message: '잘못된 이메일 형식입니다.', value: true }
    }

    return { message: '', value: false }
  }

  const isIncorrectPwdForm = () => {
    if (pwd && !isValidPwd(pwd)) {
      return { message: '잘못된 비밀번호 형식입니다.', value: true }
    }

    return { message: '', value: false }
  }

  return (
    <DefaultLayout>
      <Container
        align="center"
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box display="flex" justifyContent="center">
          <img src={logo} alt="logo" style={{ height: '60px' }} />

          <Text bold size="30px" padding="10px">
            Admin
          </Text>
        </Box>

        <Text color="#707070">바이스냅 관리자들을 위한 페이지입니다.</Text>

        <Box padding="37px" />

        <Box
          sx={{
            width: 460,
            textAlign: 'left',
          }}
        >
          <Text bold size="18px">
            EMAIL
          </Text>

          <InputTextField
            error={isIncorrectEmailForm().value}
            helperText={isIncorrectEmailForm().message}
            label="이메일 주소"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </Box>

        <Box
          sx={{
            width: 460,
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <Text bold size="18px">
            PASSWORD
          </Text>

          <InputTextField
            error={isIncorrectPwdForm().value}
            helperText={isIncorrectPwdForm().message}
            label="비밀번호"
            id="password"
            type="password"
            onChange={(e) => {
              setPwd(e.target.value)
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                loginHandler()
              }
            }}
          />
        </Box>

        <Box padding="40px" />

        <Button
          variant="contained"
          width="460px"
          height="50px"
          onClick={loginHandler}
        >
          로그인
        </Button>
      </Container>
    </DefaultLayout>
  )
}

export default HomePage
