import React from 'react'

import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import { getProducts } from '../../api/product'

const useTestLogic = () => {
  const [page, setPage] = React.useState(1)
  const [keyword, setKeyword] = React.useState('')
  const [products, setProducts] = React.useState([])
  const [selects, setSelects] = React.useState([])
  const [count, setCount] = React.useState(0)

  React.useEffect(() => {
    searchProducts()
  }, [page])

  const changeKeyword = (e) => setKeyword(e.target.value)

  const searchProducts = async () => {
    const { data, count } = await getProducts(
      page,
      null,
      keyword,
      'SELLING',
      'APPROVED',
      null,
      null,
      null
    )

    setProducts(data)
    setCount(count)
  }

  const addSelect = (product) => {
    const check = selects.map((p) => p.id)
    if (check.includes(product.id)) return
    setSelects((prev) => [...prev, product])
  }

  const removeSelect = (product) => {
    setSelects((prev) => prev.filter((p) => p.id !== product.id))
  }

  const getExcel = () => {
    const HEADER = [
      [
        'id',
        'title',
        'description',
        'availability',
        'condition',
        'price',
        'link',
        'image_link',
        'brand',
        'quantity_to_sell_on_facebook',
        'sale_price',
      ],
    ]

    const rows = selects.map((s) => {
      return {
        id: s.id,
        title: s.name,
        description: s.description,
        availability: 'in stock',
        condition: 'new',
        price: s.price,
        link: `https://bysnap-app.com/products/${s.id}/${s.name}`,
        image_link: s.imgs[0],
        brand: s.seller.name,
        quantity_to_sell_on_facebook: 100,
        sale_price: s.price !== s.discountedPrice ? s.discountedPrice : null,
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(rows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Items')
    XLSX.utils.sheet_add_aoa(worksheet, HEADER, {
      origin: 'A1',
    })

    const excelFile = XLSX.writeFile(workbook, 'insta_shop.xlsx')

    try {
      FileSaver.saveAs(excelFile, 'insta_shop.xlsx')
    } catch (error) {
      console.log(error)
    }
  }

  return {
    value: { keyword, products, selects, page, count },
    handler: {
      keyword: changeKeyword,
      search: searchProducts,
      add: addSelect,
      remove: removeSelect,
      excel: getExcel,
      page: setPage,
    },
  }
}

export default useTestLogic
