import { Box } from '@mui/material'
import Header from './Header'
import NavigationTap from '../components/Navigation'
import CssBaseline from '@mui/material/CssBaseline'
import SubHeader from '../components/SubHeader'
import { useEffect, useState } from 'react'
import { getSellerFromLocalStorage } from '../api/storage'

const DefaultLayout = ({ children, isLoggedIn, onClickSubHeaderBtn }) => {
  const [seller, setSeller] = useState({
    name: '',
    isApproved: false,
    profileImg: null,
    sellerId: '',
  })
  useEffect(() => {
    setSeller(getSellerFromLocalStorage())
  }, [])

  return (
    <>
      <Header name={seller?.name} />
      {isLoggedIn && <SubHeader onClickSubHeaderBtn={onClickSubHeaderBtn} />}
      <CssBaseline>
        <Box
          sx={{
            marginTop: isLoggedIn ? '100px' : '50px',
            marginLeft: isLoggedIn ? '200px' : 0,
            paddingTop: '20px',
            minHeight: '95vh',
            backgroundColor: isLoggedIn ? '#E4E4E4' : 'white',
            minWidth: '1540px',
          }}
        >
          {children}
        </Box>

        {isLoggedIn && (
          <Box
            sx={{
              position: 'fixed',
              zIndex: 0,
              top: 0,
              left: 0,
              width: '200px',
              overflowX: 'scroll',
              height: '100vh',
            }}
          >
            <NavigationTap seller={seller} />
          </Box>
        )}
      </CssBaseline>
    </>
  )
}

export default DefaultLayout
