const SET_POSTS = 'POST/SET_POSTS'

export const getPostAction = (post) => ({
  type: SET_POSTS,
  post,
})

const initialState = {
  data: [],
  meta: { isNext: false, count: 0 },
  page: 1,
  keyword: '',
}

const post = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSTS:
      return action.post
    default:
      return state
  }
}

export default post
