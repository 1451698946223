import { Box, Divider, Typography } from '@mui/material'

import TransactionSellerTable from '../../components/transaction/SellerTable'
import DefaultLayout from '../../global/DefaultLayout'
import useSelectModal from '../../hooks/common/modal/useSelectModal'
import useTransactionList from '../../hooks/transaction/useTransactionList'
import { priceWrapper } from '../../utils'

const TransactionListPage = () => {
  const { checked, setChecked, sellers, onClickTransaction } =
    useTransactionList()

  const {
    modal: TransactionModal,
    openModal: openTransactionModal,
    closeModal: closetransactionModal,
  } = useSelectModal({
    mainTxt: '정산하시겠습니까?',
    leftTxt: '정산',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: () => {
      onClickTransaction(checked)
      closetransactionModal()
    },
  })

  const clickTransactionBtn = (checked) => {
    if (!checked || checked.length === 0) {
      return
    }
    setChecked(checked)
    openTransactionModal()
  }

  return (
    <DefaultLayout isLoggedIn>
      <TransactionModal />

      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>미정산 요약</Typography>
        <Box sx={{ height: '10px' }} />
        <Divider />
        <Box sx={{ height: '10px' }} />
        <Box sx={{ paddingLeft: '20px' }}>
          <SummaryRow
            title="미정산 건수"
            description={getYetTransactionCnt(sellers)}
          />
          <Box sx={{ height: '10px' }} />
          <SummaryRow
            title="미정산 셀러"
            description={getYetTransactionSellerCnt(sellers)}
          />
          <Box sx={{ height: '10px' }} />
          <SummaryRow
            title="미정산 금액"
            description={`${priceWrapper(
              getYetTransactionTotalPrice(sellers)
            )}원`}
          />
        </Box>
      </Box>
      <Box sx={{ height: '10px' }} />
      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ height: '10px' }} />
        <Typography sx={{ fontWeight: 700 }}>미정산 정보</Typography>
        <Box sx={{ height: '20px' }} />
        <Divider />

        <TransactionSellerTable
          clickTransactionBtn={clickTransactionBtn}
          sellers={sellers}
          checked={checked}
          setChecked={setChecked}
        />
      </Box>
    </DefaultLayout>
  )
}

export default TransactionListPage

const SummaryRow = ({ title, description }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <Box sx={{ width: '100px' }}>
        <Typography sx={{ fontWeight: 700, color: '#707070' }}>
          {title}
        </Typography>
      </Box>
      <Typography sx={{ fontWeight: 700, color: '#ff0000', fontSize: 18 }}>
        {description}
      </Typography>
    </Box>
  )
}

const getYetTransactionCnt = (sellers) => {
  return sellers
    .filter((seller) => !seller.settled)
    .reduce((pre, cur) => pre + cur.settlement.unsettledCnt, 0)
}

const getYetTransactionSellerCnt = (sellers) => {
  return sellers.filter((seller) => !seller.settled).length
}

const getYetTransactionTotalPrice = (sellers) => {
  return sellers
    .filter((seller) => !seller.settled)
    .reduce((pre, cur) => pre + cur.settlement.unsettledAmount, 0)
}
