import { Box, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { getSettlementItem } from '../../api/order'
import Navigation from '../../components/common/navigation'
import DetailTable from '../../components/transaction/DetailTable'
import DefaultLayout from '../../global/DefaultLayout'
import { priceWrapper } from '../../utils'
import { Button } from '../../element/Button'
import { createSettlement } from '../../api/seller'

const TransactionDetailPage = () => {
  const { state } = useLocation()
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [count, setCount] = useState(0)
  const [isSettled, setSettled] = useState(false)

  const clickTransactionBtn = async () => {
    const { success } = await createSettlement([state.id])
    if (success) {
      setSettled(success)
      return
    }

    alert('오류가 발생했습니다.')
  }

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getSettlementItem(state.id, page)
      if (!success) {
        setData([])
        return
      }
      setData(data.items)
      setCount(data?.count ?? 0)
    })()
  }, [page])

  return (
    <DefaultLayout isLoggedIn>
      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>셀러 요약</Typography>
        <Box sx={{ height: '10px' }} />
        <Divider />
        <Box sx={{ height: '20px' }} />
        <Box sx={{ paddingLeft: '20px' }}>
          <SummaryRow title="셀러명" description={state?.name} />
          <Box sx={{ height: '10px' }} />
          <SummaryRow
            title="판매 건수"
            description={state?.settlement?.unsettledCnt}
          />
          <Box sx={{ height: '10px' }} />
          <SummaryRow
            title="판매 금액"
            description={priceWrapper(state?.settlement?.unsettledAmount)}
          />
          <Box sx={{ height: '10px' }} />
          <SummaryRow
            title="반품 건수"
            description={state?.settlement?.refundAmount}
            color="#ff0000"
          />
          <Box sx={{ height: '10px' }} />
          <SummaryRow
            title="반품 금액"
            description={priceWrapper(state?.settlement?.refundCnt ?? 0)}
            color="#ff0000"
          />
          <Box sx={{ height: '10px' }} />
          <SummaryRow
            title="정산 예정 금액"
            description={priceWrapper(state?.settlement?.refundAmount)}
          />
          <Box sx={{ height: '10px' }} />
          <SummaryRow
            title="정산 후 반품금액"
            description={priceWrapper(
              (state?.settlement?.unsettledAmount ?? 0) -
                (state?.settlement?.refundAmount ?? 0)
            )}
          />
          <Box sx={{ height: '20px' }} />

          {isSettled ? (
            <Box sx={{ fontSize: '20px', fontWeight: 'bold' }}>정산 완료</Box>
          ) : (
            <Button
              bg="black"
              borderRadius={2}
              size="large"
              height="40px"
              onClick={clickTransactionBtn}
            >
              정산 하기
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ height: '10px' }} />
      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ height: '10px' }} />
        <Typography sx={{ fontWeight: 700 }}>정산 정보</Typography>
        <Box sx={{ height: '20px' }} />
        <Divider />
        <DetailTable data={data} />
        {data.length !== 0 && (
          <Navigation page={page} setPage={setPage} count={count} />
        )}
      </Box>
    </DefaultLayout>
  )
}

export default TransactionDetailPage

const SummaryRow = ({ title, description, color = '#000000' }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <Box sx={{ width: '140px' }}>
        <Typography sx={{ fontWeight: 700, color: '#707070' }}>
          {title}
        </Typography>
      </Box>
      <Typography sx={{ fontWeight: 700, color: color, fontSize: 18 }}>
        {description}
      </Typography>
    </Box>
  )
}
