import { Box, Divider, Grid } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { InputTextField } from '../../element/InputTextField'

const SizeInfo = ({
  catInput,
  midCatInput,
  product,
  setProduct,
  url,
  options,
}) => {
  const sizes = getSizes(product.option)

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          {catInput} - {midCatInput}
        </p>
      </Box>
      <Divider sx={{ height: 8 }} />
      <Grid
        container
        spacing={4}
        justify="flex-end"
        alignItems="start"
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={1.2} sx={{ display: 'flex' }}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              fontWeight: 'bold',
            }}
          >
            사이즈 이미지
          </p>
        </Grid>
        <Grid item xs={10}>
          <img alt="" src={url} height={300} />
        </Grid>
        <Grid item xs={1.2} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display="flex">
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              사이즈 입력
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Box>
          <Box>
            <p
              style={{
                fontSize: '13px',
                color: '#707070',
              }}
            >
              (숫자만 입력)
            </p>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <TableContainer component={Paper} sx={{ display: 'flex' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ color: '#707070', fontWeight: 'bold', minWidth: 80 }}
                  >
                    사이즈 (cm)
                  </TableCell>

                  {options.map((option, idx) =>
                    option === 'A' ? (
                      <TableCell
                        key={idx}
                        align="center"
                        sx={{
                          height: '81px',
                          color: '#707070',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <p>A</p>
                        <p
                          style={{
                            fontSize: '14px',
                            color: '#FF0000',
                            fontWeight: 'bold',
                            marginLeft: 2,
                          }}
                        >
                          *
                        </p>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={idx}
                        align="center"
                        sx={{ color: '#707070', fontWeight: 'bold' }}
                      >
                        {option}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {sizes.map((size, idx) => (
                  <TableRow key={idx}>
                    <TableCell align="center" component="th" scope="row">
                      {size}
                    </TableCell>

                    {options.map((option, idx) => (
                      <TableCell key={idx} align="center">
                        <InputTextField
                          label=" 입력"
                          value={getOptValue(
                            getSizeTable(product.sizeGuide),
                            size,
                            option
                          )}
                          onChange={(e) => {
                            if (!/^(\d+(\.\d*)?|)$/.test(e.target.value)) {
                              return
                            }

                            const sizeListIdx = product.sizeGuide.findIndex(
                              (list) => {
                                const findSize = list[0].split(':')[1]
                                return size === findSize
                              }
                            )

                            const sizeList = product.sizeGuide[sizeListIdx]

                            const matchedSizeIdx = sizeList.findIndex(
                              (op) => op.split(':')[0] === option
                            )
                            const matchedOption =
                              sizeList[matchedSizeIdx].split(':')[0]
                            sizeList[
                              matchedSizeIdx
                            ] = `${matchedOption}:${e.target.value}`
                            const sizeGuide = [...product.sizeGuide]

                            setProduct({ ...product, sizeGuide })
                          }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}

const getOptValue = (sizeTable, size, option) => {
  let val = ''
  if (sizeTable[size]) {
    val = sizeTable[size]
  }
  if (val && typeof val[option] === 'string') {
    val = val[option]
  }
  return val
}

export default SizeInfo

export const getSizes = (option) => {
  const sizeOption = option.options.filter((o) => o.type === 'size').pop()
  if (sizeOption) {
    return sizeOption.list
  }
  return []
}

const getSizeTable = (sizeGuide) => {
  sizeGuide = sizeGuide.filter((guide) => Array.isArray(guide))
  const obj = {}
  for (let i = 0; i < sizeGuide.length; ++i) {
    const guide = sizeGuide[i]
    const size = guide[0].split(':')[1]
    obj[size] = {}
    for (let i = 1; i < guide.length; ++i) {
      const [opt, val] = guide[i].split(':')
      obj[size][opt] = val
    }
  }
  return obj
}
