import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import DefaultLayout from '../../global/DefaultLayout'

import { useLocation } from 'react-router-dom'
import {
  createPromotion,
  findPromotion,
  updatePromotion,
} from '../../api/promotion'
import GlobalPromotionRegister from '../../components/promotion/GlobalRegister'
import PromotionRegister from '../../components/promotion/Register'

export const CampaignType = {
  Brand: 'BRAND',
  Showcase: 'SHOWCASE',
  Global: 'GLOBAL',
}

export const MAX_TOP_POST = 3
export const MAX_POST = 60
export const MAX_PRODUCT = 60

const PromotionRegisterPage = () => {
  const searchParams = new URLSearchParams(useLocation().search)
  const id = searchParams.get('id')

  const [campaign, setCampaign] = useState({
    type: null,
    title: '',
    subTitle: '',
    isDiscount: false,
    maxDiscountRate: '',
    startAt: dayjs().startOf('day'),
    endAt: dayjs(),
    thumbImg: '',
    topImg: '',
    top1Img: '',
    top2Img: '',
    mThumbImg: '',
    mTopImg: '',
    mTop1Img: '',
    mTop2Img: '',
    topPosts: [],
    posts: [],
    products: [],
    thumbBgColor: '',
    topBgColor: '',
    top1BgColor: '',
    top2BgColor: '',
    postTitle: '',
    productTitle: '',
    sellerTitle: '',
  })

  useEffect(() => {
    if (!id) {
      return
    }
    ;(async () => {
      const { success, data } = await findPromotion(id)
      if (success) {
        setCampaign({
          type: data.type,
          title: data.title,
          subTitle: data.subTitle,
          isDiscount: data.isDiscount,
          maxDiscountRate: data.maxDiscountRate,
          startAt: dayjs(data.startAt),
          endAt: dayjs(data.endAt),
          thumbImg: data.thumbImg,
          topImg: data.topImg,
          top1Img: data.top1Img,
          top2Img: data.top2Img,
          mThumbImg: data.mThumbImg,
          mTopImg: data.mTopImg,
          mTop1Img: data.mTop1Img,
          mTop2Img: data.mTop2Img,
          topPosts: data.topPosts,
          posts: data.posts,
          products: data.products,
          thumbBgColor: data.thumbBgColor,
          topBgColor: data.topBgColor,
          top1BgColor: data.top1BgColor,
          top2BgColor: data.top2BgColor,
          postTitle: data.postTitle,
          productTitle: data.productTitle,
          sellerTitle: data.sellerTitle,
        })
      }
    })()
  }, [])

  const reqPromo = id ? updatePromotion : createPromotion

  return (
    <DefaultLayout isLoggedIn>
      {campaign.type === CampaignType.Global ? (
        <GlobalPromotionRegister
          id={id}
          campaign={campaign}
          setCampaign={setCampaign}
          reqPromo={reqPromo}
        />
      ) : (
        <PromotionRegister
          id={id}
          campaign={campaign}
          setCampaign={setCampaign}
          reqPromo={reqPromo}
        />
      )}
    </DefaultLayout>
  )
}
export default PromotionRegisterPage
