import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getImagesUrl } from '../../api/image'
import { addNotice, editNotice, getNotice } from '../../api/notice'

export const UseNoticeType = {
  ADD: addNotice,
  EDIT: editNotice,
}

const useNotice = (method) => {
  const id = useLocation().pathname.split('/')[2]
  const navigation = useNavigate()

  const isForSeller = useLocation().search.includes('type=seller')

  const [form, setForm] = useState({
    type: isForSeller ? 'SELLER' : '',
    title: '',
    content: '',
    imgs: [],
  })

  const { type, title, content, imgs } = form

  const formHandler = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleType = (e) => formHandler('type', e.target.value)

  const handleTitle = (e) => formHandler('title', e.target.value)

  const handleContent = (e) => formHandler('content', e)

  const handleImgs = async (e) => {
    const files = e.target.files

    const formData = new FormData()
    formData.append('type', 'post')
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i])
    }

    const { success, data } = await getImagesUrl(formData)

    if (!success) return

    formHandler('imgs', [...imgs, ...data])
  }

  const submit = async () => {
    try {
      await UseNoticeType[method]({ ...form, noticeId: id })

      if (isForSeller) {
        navigation('/notice-seller')
      } else {
        navigation('/notice')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (method === 'ADD') return
    ;(async () => {
      try {
        const { imgs, type, title, content } = await getNotice(id)
        setForm({
          type,
          imgs,
          content,
          title,
        })
      } catch (error) {}
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {
    handleType,
    handleTitle,
    type,
    title,
    content,
    handleContent,
    handleImgs,
    submit,
    imgs,
    navigation,
    isForSeller,
  }
}

export default useNotice
