import { instance } from './instance'

export const getImageUrl = async (formData) => {
  try {
    const { data } = await instance.post(`/api/image/uploads`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      token: true,
    })
    return { success: true, data: data.data.pop()?.url }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getImagesUrl = async (formData) => {
  try {
    const { data } = await instance.post(`/api/image/uploads`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      token: true,
    })

    const images = data.data.map((e) => e.url)

    return { success: true, data: images }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}
