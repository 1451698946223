import { Box } from '@mui/material'
import Pagination from '@mui/material/Pagination'

export default function Navigation({ page, setPage, count }) {
  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '39px' }}>
      <Pagination
        sx={{ color: 'red', justifyContent: 'center' }}
        count={Math.ceil(count / 20)}
        variant="outlined"
        shape="rounded"
        size="large"
        page={page}
        onChange={handleChange}
      />
    </Box>
  )
}
