import { setToken } from './cookie'
import { instance } from './instance'
import { setSellerToLocalStorage } from './storage'

export const login = async (email, pwd) => {
  try {
    const {
      data: {
        accessToken,
        refreshToken,
        seller: { name, profileImg, id, isApproved },
      },
    } = await instance.post(`/api/auth/login-seller`, {
      email,
      password: pwd,
    })

    await setToken(accessToken, refreshToken)
    await setSellerToLocalStorage(name, isApproved, profileImg, id)

    return { success: true, path: 'snap' }
  } catch (error) {
    return { success: false }
  }
}

export const signUp = async (body) => {
  try {
    const data = await instance.post(`/api/auth/sign-seller`, body)
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getProfile = async () => {
  try {
    const data = await instance.get(`/api/user/profile`, { token: true })
  } catch (error) {
    console.error(error)
  }
}
