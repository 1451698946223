import { instance } from './instance'

export const updateInfo = async (body) => {
  try {
    await instance.post(`/api/seller/update-info`, body, { token: true })
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

export const getSellersForTransaction = async ({ page, size }) => {
  try {
    const query = { page, size }
    const queryStr = Object.keys(query)
      .filter((key) => query[key])
      .map((key) => `${key}=${query[key]}`)
      .join('&')
    const { data } = await instance.get(`/api/seller?${queryStr}`, {
      token: true,
    })

    return { data, success: true }
  } catch (error) {
    throw error
  }
}

export const getSellers = async (
  page,
  keyword,
  approveStatus,
  grade,
  size,
  nameSorted
) => {
  try {
    const query = { page, grade, approveStatus, keyword, size, nameSorted }

    const queryStr = Object.keys(query)
      .filter((key) => query[key])
      .map((key) => `${key}=${query[key]}`)
      .join('&')

    const { data } = await instance.get(
      `/api/seller/search/admin?${queryStr}`,
      {
        token: true,
      }
    )

    return data
  } catch (error) {
    throw error
  }
}

export const updateApproveStatus = async (
  sellerIds,
  approveStatus,
  rejectReason
) => {
  try {
    await instance.post(
      `/api/seller/approve/admin`,
      { sellerIds, approveStatus, rejectReason },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const getSeller = async (id) => {
  try {
    const data = await instance.get(`/api/seller/admin/${id}`, { token: true })
    return data
  } catch (error) {
    throw error
  }
}

export const createSettlement = async (sellerIds) => {
  try {
    const { data } = await instance.post(
      `/api/seller/set-settlement`,
      { sellerIds },
      {
        token: true,
      }
    )
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const updateGrade = async (sellerId, grade) => {
  try {
    await instance.post(
      `/api/seller/grade`,
      { sellerId, grade },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}
