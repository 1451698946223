import { useEffect, useState } from 'react'
import { createSettlement, getSellersForTransaction } from '../../api/seller'

const useTransactionList = () => {
  const [checked, setChecked] = useState([])
  const [sellers, setSellers] = useState([])

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getSellersForTransaction({
        page: 1,
      })
      if (success) {
        setSellers(data.items.map((item) => ({ ...item, settled: false })))
      }
    })()
  }, [])

  const onClickTransaction = async (checked) => {
    if (!checked || checked.length === 0) {
      return
    }

    const { success } = await createSettlement(checked.map((o) => o.id))
    if (!success) {
      alert('실패하였습니다.')
      return
    }

    checked.forEach((check) => {
      check.settled = true
      check.settlement.settledAt = new Date()
    })

    setSellers(sellers)
    setChecked([])
  }

  return { checked, setChecked, sellers, onClickTransaction }
}

export default useTransactionList
