import { getDate, priceWrapper } from '../../utils'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../element/Button'

const heads = [
  '판매자',
  '정산 주문건수',
  '미정산 금액 (원)',
  '반품 정산금액 (원)',
  '정산 후 반품금액 (원)',
  '정산 예정 금액 (원)',
  '정산 처리 일시',
  '정산',
  '',
]

const TransactionSellerTable = ({ sellers, clickTransactionBtn }) => {
  const navigation = useNavigate()
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sellers.map((seller, index) => {
          const {
            name,
            settlement: {
              unsettledCnt,
              unsettledAmount,
              refundAmount,
              settledAt,
            },
          } = seller

          const netUnsettledAmount = unsettledAmount - refundAmount

          return (
            <tr
              key={index}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td>{name}</Td>
              <Td>{priceWrapper(unsettledCnt)}</Td>
              <Td>{priceWrapper(unsettledAmount)}</Td>

              {refundAmount === 0 ? (
                <Td>0</Td>
              ) : (
                <Td style={{ color: 'red' }}>-{priceWrapper(refundAmount)}</Td>
              )}

              {netUnsettledAmount < 0 ? (
                <Td style={{ color: 'red', fontWeight: 'bold' }}>
                  -{priceWrapper(refundAmount)}
                </Td>
              ) : (
                <Td style={{ fontWeight: 'bold' }}>0</Td>
              )}

              <Td style={{ fontWeight: 'bold' }}>
                {netUnsettledAmount > 0 ? priceWrapper(netUnsettledAmount) : 0}
              </Td>

              <Td>{getDate(settledAt)}</Td>

              <Td>
                {seller.settled ? (
                  <></>
                ) : (
                  <Button
                    bold
                    bg="#BACAFF"
                    color="#003BFF"
                    borderRadius="10px"
                    onClick={async () => {
                      await clickTransactionBtn([seller])
                    }}
                  >
                    정산
                  </Button>
                )}
              </Td>

              <Td>
                {false ? (
                  <></>
                ) : (
                  <Button
                    bold
                    bg="white"
                    color="black"
                    border="1px solid"
                    borderRadius="10px"
                    onClick={async () => {
                      navigation('/transaction/detail', { state: seller })
                    }}
                  >
                    상세 내용
                  </Button>
                )}
              </Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default TransactionSellerTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})
