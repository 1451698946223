import { instance } from './instance'
import { createQueryString } from './utils'

export const getReturnsForList = async ({
  page,
  keyword,
  startAt,
  endAt,
  returnStatus,
  productStatus,
}) => {
  try {
    const queryObj = {
      page,
      keyword,
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
      status: returnStatus,
      productStatus,
    }

    const query = createQueryString(queryObj)

    const { data } = await instance.get(`/api/return/admin?${query}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getReturnsForRegister = async ({ page, size }) => {
  try {
    const queryObj = {
      page,
      size,
    }

    const query = createQueryString(queryObj)

    const { data } = await instance.get(`/api/return/admin/register?${query}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const admitReturns = async (returnIds) => {
  try {
    const { data } = await instance.post(
      `/api/return/admin/admit-return`,
      {
        returnIds,
      },
      {
        token: true,
      }
    )
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const changeReturnStatus = async (body) => {
  try {
    await instance.post(`/api/return/admin/status`, body, {
      token: true,
    })
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

export const calculateReturn = async (returnId) => {
  try {
    await instance.post(
      `/api/return/admin/refund`,
      { returnId },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}
