import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

export const TAG_IMAGE_SIZE = 500
export const TAG_DOT_SIZE = 20
export const REAL_TAG_DOT_SIZE = 10
export const PREVIEW_IMAGE_SIZE = 375

const ProductLocs = ({ loc, size = TAG_IMAGE_SIZE }) => {
  const { x, y } = loc
  return (
    <RadioButtonCheckedIcon
      sx={{
        width: TAG_DOT_SIZE,
        height: TAG_DOT_SIZE,
        position: 'absolute',
        left: x * size - TAG_DOT_SIZE / 2,
        top: y * size - TAG_DOT_SIZE / 2,
        color: 'white',
        backgroundColor: '#707070',
        borderRadius: TAG_DOT_SIZE,
        zIndex: 50,
      }}
    />
  )
}

export default ProductLocs
