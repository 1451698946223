import { instance } from './instance'

export const getCategory = async () => {
  try {
    const { data } = await instance.get(`/api/category`, { token: true })
    return { success: true, data: data.data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}
