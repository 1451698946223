export const CATEGORY_MAP = {
  OUTER: '아우터',
  TOP: '상의',
  'CO-ORD SETS': '셋업 의류',
  ACC: '액세서리',
  BAG: '가방',
  DRESS: '원피스',
  BOTTOM: '하의',
  'ONE-PIECE': '점프슈트 & 모노키니',
}
