import { Box, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import { MENU_LIST } from './Navigation'

const getTagTitle = (outerPath) => {
  let ret = ''
  MENU_LIST.forEach(({ list }) =>
    list.forEach(({ name, path }) => {
      if (path === outerPath) {
        ret = name
        return
      }
    })
  )
  return ret
}

const getPath = (path) => {
  if (path.includes('notice')) {
    if (path.split('/').length !== 2) {
      return { path: '/notice/add', existBtn: false, btnText: '공지사항 등록' }
    }
    return { path: '/notice/add', existBtn: true, btnText: '공지사항 등록' }
  }
  return { path: '', existBtn: false, btnText: '' }
}

const SubHeader = ({ onClickSubHeaderBtn }) => {
  const location = useLocation()
  const navigation = useNavigate()
  const { path, existBtn, btnText } = getPath(location.pathname)
  return (
    <Box
      sx={{
        border: '1px solid #e4e4e4',
        backgroundColor: '#ffffff',
        paddingX: '20px',
        paddingY: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        zIndex: 100,
        top: 50,
        left: 200,
        width: '100%',
        overflowX: 'scroll',
      }}
    >
      <Box>
        <Box textAlign={'left'}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            {getTagTitle(location.pathname)}
          </p>
        </Box>
      </Box>
      {existBtn && (
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'black',
              position: 'fixed',
              zIndex: 100,
              top: 60,
              right: 30,
              width: '120px',
              ':hover': { backgroundColor: '#555755' },
            }}
            onClick={() => {
              if (onClickSubHeaderBtn) {
                onClickSubHeaderBtn()
              } else {
                navigation(path)
              }
            }}
          >
            <p>{btnText}</p>
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default SubHeader
