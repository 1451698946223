import 'swiper/css'

import { Box, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Button } from '../../element/Button'
import Image from '../../element/Image'
import { IModal } from './modal'
import { ReturnStatus } from '../return/return-register-table'

export const ReturnDetailModal = ({ detail, onClose, approve }) => {
  const { onView, returnItem } = detail
  const {
    id,
    returnItems,
    status,
    order: { nickname },
  } = returnItem

  return (
    <IModal
      key={id}
      open={onView}
      onClose={onClose}
      buttonGroup={
        <>
          {status === ReturnStatus.Request && (
            <Button
              height="40px"
              bg="#BACAFF"
              color="#003BFF"
              bold
              borderRadius="10px"
              onClick={() => {
                approve([detail.returnItem])
              }}
            >
              <p>승인</p>
            </Button>
          )}
        </>
      }
      content={
        <PreviewReturnDetailImage
          returnItems={returnItems}
          buyerName={nickname}
        />
      }
    />
  )
}

const IMAGE_SIZE = 430
export const PreviewReturnDetailImage = ({ returnItems, buyerName }) => {
  return (
    <Box>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '20px' }} />
        <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
          {buyerName}
        </Typography>
      </Box>
      <Box sx={{ height: 10 }} />
      {returnItems.map((item, index) => {
        const { reason, imgs } = item
        return (
          <Box key={index}>
            <Swiper>
              {imgs.map((img, idx) => (
                <SwiperSlide key={idx}>
                  <Box
                    width={IMAGE_SIZE}
                    height={IMAGE_SIZE}
                    position="relative"
                  >
                    <Image
                      src={img}
                      alt="return"
                      width={IMAGE_SIZE}
                      height={IMAGE_SIZE}
                      position="absolute"
                    />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
            <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
              {reason}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}
