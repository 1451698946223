import { SortType, getDate, getResizeUrl, priceWrapper } from '../../utils'

import { Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../element/Button'
import Image from '../../element/Image'
import { ProductSort } from './utils'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

const heads = [
  '승인',
  '수정',
  '스토어명',
  '상품번호',
  '대표이미지',
  '상품명',
  '판매상태',
  '승인상태',
  '판매가',
  '할인가',
  '해외판매가',
  '해외할인가',
  '할인율',
  '해외할인율',
  '옵션여부',
  '등록일',
]

export const APPROVE_STATUS_MAP = {
  REQUEST: {
    name: '승인요청',
    color: 'black',
  },
  WAITING: {
    name: '승인대기',
    color: 'blue',
  },
  RE_WAITING: {
    name: '재승인대기',
    color: 'green',
  },
  APPROVED: {
    name: '승인완료',
    color: 'black',
  },
  REJECT: {
    name: '승인반려',
    color: 'red',
  },
}

const ProductTable = ({
  products,
  checked,
  setChecked,
  approve,
  clickHandler,
  sort,
  setSort,
  page,
  setPage,
}) => {
  const buttonRef = useRef([])
  const isAllChecked = products.every((product) =>
    checked.map((o) => o.id).includes(product.id)
  )

  const navigation = useNavigate()

  const rowHandler = (e, id) => {
    for (let i = 0; i < buttonRef.current?.length; i += 1) {
      let ref = buttonRef.current[i]
      if (ref?.contains(e.target)) {
        return
      }
    }
    clickHandler(id)
  }

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  setChecked([])
                } else {
                  setChecked([...products])
                }
              }}
            />
          </Th>
          {heads.map((head, idx) => (
            <Th key={idx}>
              {Head(
                head,
                sort.sortType,
                sort.productSort,
                setSort,
                page,
                setPage
              )}
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => {
          return (
            <tr
              key={product.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
              onClick={(e) => {
                rowHandler(e, product.id)
              }}
            >
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    ref={(el) => (buttonRef.current[index * 4] = el)}
                    checked={checked.map((o) => o.id).includes(product.id)}
                    onClick={(e) => {
                      if (checked.map((o) => o.id).includes(product.id)) {
                        const idx = checked.findIndex(
                          (o) => o.id === product.id
                        )
                        checked.splice(idx, 1)
                      } else {
                        checked.push(product)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Box>
              </Td>
              <Td
                style={{ width: '80px' }}
                ref={(el) => {
                  buttonRef.current[index * 4 + 1] = el
                }}
              >
                <Button
                  width="56px"
                  height="36px"
                  bg="#BACAFF"
                  color="#003bff"
                  bold
                  borderRadius="10px"
                  onClick={() => {
                    approve('APPROVED', [product])
                  }}
                >
                  승인
                </Button>
              </Td>

              <Td
                style={{ width: '80px' }}
                ref={(el) => {
                  buttonRef.current[index * 4 + 2] = el
                }}
              >
                <Button
                  width="56px"
                  height="36px"
                  bg="#BAFFD5"
                  color="#00A52D"
                  bold
                  borderRadius="10px"
                  onClick={() => {
                    // navigation(`/product/add/${product.id}`)
                    navigation(`/product/add?type=modify&pid=${product.id}`)
                  }}
                >
                  수정
                </Button>
              </Td>

              <Td>{product.seller.name}</Td>
              <Td>{product.productNumber}</Td>
              <Td>
                <Image
                  alt="product"
                  src={getResizeUrl(product.imgs[0], 320, false)}
                  width="75px"
                  height="75px"
                  borderRadius={'10px'}
                  border="1px solid #E4E4E4"
                  padding="2px"
                />
              </Td>
              <Td width={250}>{product.name}</Td>
              <Td>{wrapSellStat(product.sellStatus, product.approveStatus)}</Td>
              <Td>
                <p
                  style={{
                    fontSize: 14,
                    color: APPROVE_STATUS_MAP[product.approveStatus].color,
                    fontWeight: 'bold',
                  }}
                >
                  {APPROVE_STATUS_MAP[product.approveStatus].name}
                </p>
              </Td>
              <Td>₩{priceWrapper(product.sellingPrice)}</Td>
              <Td>₩{priceWrapper(product.discountedSellingPrice)}</Td>
              <Td>${product.price}</Td>
              <Td>
                {product.price === product.discountedPrice
                  ? ''
                  : `${product.discountedPrice}`}
              </Td>
              <Td>{product.discountRate}%</Td>
              <Td>
                {Math.round(
                  100 - (product.discountedPrice / product.price) * 100
                )}
                %
              </Td>
              <Td width={'60px'}>{product.existOption ? 'Y' : 'N'}</Td>
              <Td>{getDate(product.createdAt)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ProductTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const wrapSellStat = (status, approveStatus) => {
  if (approveStatus !== 'APPROVED') {
    return ''
  }

  switch (status) {
    case 'SELLING':
      return '판매중'
    case 'WAITING':
      return '판매대기'
    case 'ENDING':
      return '판매종료'
    case 'SOLDOUT':
      return '품절'
    default:
      return ''
  }
}

const Head = (name, sortType, pSort, setSort, page, setPage) => {
  const sortList = [
    '상품번호',
    '판매가',
    '할인가',
    '해외판매가',
    '해외할인가',
    '할인율',
    '리뷰개수',
    '등록일',
    '승인상태',
  ]
  if (!sortList.includes(name)) {
    return <p style={{ color: '#707070' }}>{name}</p>
  }

  const onClick = () => {
    let type = SortType.DESC

    if (sortType === SortType.DESC) {
      type = SortType.ASC
    }

    let pSort = convertNameToProductSort(name)

    setSort({ productSort: pSort, sortType: type })
    if (page !== 1) {
      setPage(1)
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
    >
      <p style={{ color: '#707070' }}>{name}</p>
      <Box display="flex" flexDirection="column" gap={0}>
        <ArrowDropUp
          sx={{
            marginBottom: -1,
            ...onFocusStyle(name, sortType, pSort, true),
          }}
        />
        <ArrowDropDown
          sx={{ marginTop: -1, ...onFocusStyle(name, sortType, pSort, false) }}
        />
      </Box>
    </Box>
  )
}

const onFocusStyle = (name, sortType, pSort, isUp) => {
  const style = { color: '#e4e4e4' }
  if (
    pSort === convertNameToProductSort(name) &&
    ((sortType === SortType.ASC && isUp) ||
      (sortType === SortType.DESC && !isUp))
  ) {
    style['color'] = '#000000'
  }

  return style
}

const convertNameToProductSort = (name) => {
  let pSort
  switch (name) {
    case '상품번호':
      pSort = ProductSort.ProductNumber
      break
    case '판매가':
      pSort = ProductSort.ProductSellingPrice
      break
    case '할인가':
      pSort = ProductSort.ProductDiscountedSellingPrice
      break
    case '해외판매가':
      pSort = ProductSort.ProductPrice
      break
    case '해외할인가':
      pSort = ProductSort.ProductDiscountedPrice
      break
    case '리뷰개수':
      pSort = ProductSort.ProductReviewsCnt
      break
    case '등록일':
      pSort = ProductSort.ProductCreatedAt
      break
    case '할인율':
      pSort = ProductSort.ProductDiscountRate
      break
    case '승인상태':
      pSort = ProductSort.ProductApproveStatus
      break
    default:
      throw new Error('error')
  }
  return pSort
}
