import { copyTextById, getDate, getResizeUrl } from '../../utils'

import { ContentCopy } from '@mui/icons-material'
import {
  Box,
  ButtonBase,
  Checkbox,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { removePromotion } from '../../api/promotion'
import SelectModal from '../../components/SelectModal'
import { Button } from '../../element/Button'
import Image from '../../element/Image'
import CustomSwitch from '../common/Switch'

const TYPE = {
  GLOBAL: '전사프로모션',
  BRAND: '브랜드',
  SHOWCASE: '모음전',
}

const heads = [
  '',
  '사용',
  '순서',
  '아이디',
  '기획전 타입',
  '썸네일 이미지',
  '프로모션 기획전명',
  '진행기간',
  '등록일시',
  '',
  '',
]

const PromotionListTable = ({
  items,
  setItems,
  checked,
  setChecked,
  storeItems,
}) => {
  const navigation = useNavigate()

  const changeOrderNumber = (e, item) => {
    if (isEnd(item.endAt)) {
      return
    }

    const inputVal = e.target.value
    if (!/^\d{0,2}$/.test(inputVal)) {
      return
    }
    const number = Number(inputVal)
    item.orderNumber = number === 0 ? null : Number(inputVal)
    setItems([...items])
  }

  const clickActive = (e, item) => {
    item.status = item.status === 'ACTIVE' ? 'UNUSED' : 'ACTIVE'
    setItems([...items])
  }

  const [modal, setModal] = useState({
    on: false,
    onClick: () => {},
  })

  const remove = async (item) => {
    const { success } = await removePromotion([item.id])
    if (!success) {
      alert('삭제에 실패하였습니다.')
      return
    }

    const filtered = items.filter((i) => i.id !== item.id)
    setItems(filtered)
    setModal({ on: false, onClick: () => {} })
  }

  const [snackbar, setSnackBar] = useState(false)

  const copyCouponCode = (id) => {
    copyTextById(id)
    setSnackBar(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar(false)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        message="기획전 id가 복사되었습니다."
      />
      <SelectModal
        open={modal.on}
        handleClose={() => {
          setModal((prev) => ({ ...prev, on: false }))
        }}
        clickModalBtn={modal.onClick}
        mainTxt="정말 삭제하시겠습니까?"
        leftTxt="삭제"
        rightTxt="취소"
        leftBg="black"
        rightBg="red"
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          <p style={{ color: '#707070' }}>*10번 기획전부터 대기중인 기획전.</p>
        </Box>
        <Button width={80} height={40} borderRadius="6px" onClick={storeItems}>
          저장
        </Button>
      </Box>
      <Table>
        <thead>
          <tr>
            {heads.map((head, idx) => (
              <Th key={idx}>{head}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            const isChecked = checked.map((o) => o.id).includes(item.id)

            return (
              <tr
                key={item.id}
                style={{ borderBottom: '1px solid #ddd', height: '120px' }}
              >
                <Td width={50}>
                  <Checkbox
                    checked={isChecked}
                    onClick={() => {
                      if (isChecked) {
                        const idx = checked.findIndex((o) => o.id === item.id)
                        checked.splice(idx, 1)
                      } else {
                        checked.push(item)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Td>
                <Td width={100}>
                  {isEnd(item.endAt) ? (
                    '종료'
                  ) : (
                    <CustomSwitch
                      color="success"
                      checked={item.status === 'ACTIVE'}
                      onClick={(e) => {
                        clickActive(e, item)
                      }}
                    />
                  )}
                </Td>
                <Td width={100}>
                  <StyledInput
                    value={
                      isEnd(item.endAt)
                        ? ''
                        : item.orderNumber
                        ? item.orderNumber
                        : ''
                    }
                    onChange={(e) => changeOrderNumber(e, item)}
                  />
                </Td>
                <Td width={200}>
                  <Stack direction="row" gap="4px">
                    <Typography id={`coupon_code_${item.id}`} fontSize={14}>
                      {item.id}
                    </Typography>
                    <ButtonBase
                      onClick={() => copyCouponCode(`coupon_code_${item.id}`)}
                    >
                      <ContentCopy sx={{ color: '#00a52d' }} />
                    </ButtonBase>
                  </Stack>
                </Td>
                <Td width={120}>{TYPE[item.type]}</Td>
                <Td width={150}>
                  <Image
                    alt="product"
                    src={getResizeUrl(item.thumbImg, 320, true)}
                    width="100px"
                    height="100px"
                    border="1px solid #E4E4E4"
                    padding="2px"
                  />
                </Td>
                <Td width={400}>{item.title}</Td>
                <Td width={200}>
                  {getDate(item.startAt, true)} ~ {getDate(item.endAt, true)}
                </Td>
                <Td width={150}>{getDate(item.createdAt)}</Td>
                <Td>
                  <Button
                    color="blue"
                    bg="#BACAFF"
                    borderRadius="10px"
                    onClick={() => {
                      navigation(`/promo/register?id=${item.id}`)
                    }}
                  >
                    수정
                  </Button>
                </Td>
                <Td>
                  <Button
                    color="red"
                    bg="#FFF0F0"
                    borderRadius="10px"
                    onClick={() => {
                      setModal({
                        on: true,
                        onClick: () => {
                          remove(item)
                        },
                      })
                    }}
                  >
                    삭제
                  </Button>
                </Td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default PromotionListTable

const Table = styled('table')`
  width: 100%;
  border-top: 2px solid black;
`

const Th = styled('th')`
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  height: 48px;
  vertical-align: middle;
  text-align: center;
`

const Td = styled('td')`
  width: ${(props) => props.width}px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const StyledInput = styled.input`
  width: 40px;
  height: 40px;
  border: none;
  border-bottom: 4px solid;
  outline: none;
  padding-left: 14px;

  font-size: 16px; /* 폰트 크기 */
  font-weight: bold; /* 폰트 두께 */
  font-family: Arial, sans-serif; /* 폰트 패밀리 */
  text-align: left; /* 텍스트 정렬 */
`

const isEnd = (endAt) => {
  const now = Date.now()
  const elevenHoursInMillis = 13 * 60 * 60 * 1000

  const dateMinusElevenHours = new Date(now - elevenHoursInMillis)
  return new Date(endAt).getTime() < dateMinusElevenHours.getTime()
}
