import { instance } from './instance'

export const getUsers = async (page, keyword, socialType, grade, isActive) => {
  try {
    const query = { page, grade, socialType, keyword, isActive }

    const queryStr = Object.keys(query)
      .filter((key) => query[key])
      .map((key) => `${key}=${query[key]}`)
      .join('&')

    const { data } = await instance.get(`/api/user/search/admin?${queryStr}`, {
      token: true,
    })

    return data
  } catch (error) {
    throw error
  }
}

export const updateGrade = async (userIds, grade) => {
  try {
    await instance.post(
      `/api/user/grade/admin`,
      { userIds, grade },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}
