import { getDate, getResizeUrl } from '../../utils'

import styled from 'styled-components'
import Image from '../../element/Image'
import { Box, Checkbox } from '@mui/material'
import OverwriteImage from '../../element/overwriteImage'

const heads = ['ID', '스냅사진', '브랜드', '태그상품', '스타일 태그', '등록일']

const ItemSearchTable = ({ items, checked, setChecked }) => {
  const isAllChecked = items.every((item) =>
    checked.map((o) => o.id).includes(item.id)
  )

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  const filtered = checked.filter(
                    (c) => !items.map((i) => i.id).includes(c.id)
                  )
                  console.log(filtered)
                  setChecked([...filtered])
                } else {
                  const filtered = items.filter(
                    (i) => !checked.map((c) => c.id).includes(i.id)
                  )
                  setChecked((prev) => [...prev, ...filtered])
                }
              }}
            />
          </Th>

          {heads.map((head, idx) => (
            <Th key={idx}>{head}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const isChecked = checked.map((o) => o.id).includes(item.id)

          return (
            <tr
              key={item.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td width={50}>
                <Checkbox
                  checked={isChecked}
                  onClick={() => {
                    if (isChecked) {
                      const idx = checked.findIndex((o) => o.id === item.id)
                      checked.splice(idx, 1)
                    } else {
                      checked.push(item)
                    }
                    setChecked([...checked])
                  }}
                />
              </Td>
              <Td width={100}>{item.id}</Td>

              <Td width={150}>
                <Image
                  alt="product"
                  src={getResizeUrl(item.imgs[0], 320, false)}
                  width="100px"
                  height="100px"
                  border="1px solid #E4E4E4"
                  padding="2px"
                />
              </Td>

              <Td width={100}>{item.seller.name}</Td>

              <Td>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {item.products?.length === 0 ? (
                    <></> ? (
                      item.products.length <= 2
                    ) : (
                      <Box>
                        {item.products?.map((p, idx) => (
                          <OverwriteImage
                            key={idx}
                            alt="p"
                            src={p.imgs[0]}
                            text={p.sellStatus === 'SOLDOUT' ? 'soldout' : ''}
                          />
                        ))}
                      </Box>
                    )
                  ) : (
                    <>
                      <Box>
                        {item.products?.slice(0, 2).map((p, idx) => (
                          <OverwriteImage
                            key={idx}
                            alt="p"
                            src={p.imgs[0]}
                            width="75px"
                            height="75px"
                            borderRadius={'10px'}
                            border="1px solid #E4E4E4"
                            padding="2px"
                            text={p.sellStatus === 'SOLDOUT' ? 'soldout' : ''}
                          />
                        ))}
                      </Box>

                      <Box>
                        {item.products?.slice(2, 4).map((p, idx) => (
                          <OverwriteImage
                            key={idx}
                            alt="p"
                            src={p.imgs[0]}
                            width="75px"
                            height="75px"
                            borderRadius={'10px'}
                            border="1px solid #E4E4E4"
                            padding="2px"
                            text={p.sellStatus === 'SOLDOUT' ? 'soldout' : ''}
                          />
                        ))}
                      </Box>
                    </>
                  )}
                </Box>
              </Td>

              <Td width={170}>{item.tags.map((tag) => tag.tag).join(', ')}</Td>

              <Td width={100}>{getDate(item.createdAt)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ItemSearchTable

const Table = styled('table')`
  width: 100%;
  border-top: 2px solid black;
`

const Th = styled('th')`
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  height: 48px;
  vertical-align: middle;
  text-align: center;
`

const Td = styled('td')`
  width: ${(props) => props.width}px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`
