import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import Navigation from '../../components/common/navigation'
import DefaultLayout from '../../global/DefaultLayout'
import useNotice from '../../hooks/notice/useNoticeList'
import PartnersNoticeTable from '../../components/notice/partners-notice-table'

const PartnersNoticePage = () => {
  const navigation = useNavigate()

  const { notices, setNotices, clickHandler, page, setPage, count } =
    useNotice()

  return (
    <DefaultLayout
      isLoggedIn
      onClickSubHeaderBtn={() => navigation('/notice/add?type=seller')}
    >
      <Box sx={boxStyle}>
        <Box sx={{ fontSize: '18px', fontWeight: 'bold' }}>공지목록</Box>
        <Divider sx={{ height: 10 }} />

        <PartnersNoticeTable
          notices={notices}
          setNotices={setNotices}
          clickHandler={clickHandler}
        />
        <Navigation page={page} setPage={setPage} count={count} />
      </Box>
    </DefaultLayout>
  )
}

export default PartnersNoticePage

const boxStyle = {
  marginX: '10px',
  paddingX: '100px',
  marginTop: '10px',
  paddingTop: '40px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}
