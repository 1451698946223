import { Grid, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { getCategory } from '../../api/category'
import { CustomDropDownField } from './CustomDropdownField'

const SelectCategory = ({ category, setOption }) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    ;(async () => {
      const categories = (await getCategory()).data
      const filtered = categories // .filter((c) => c.parent === null)
      setCategories(filtered)
    })()
  }, [])

  const onChange = (_, value) => {
    setOption((prev) => ({
      ...prev,
      category: categories.find((c) => c.korName === value),
    }))
  }

  const bigCategory = categories.filter((c) => !c.parent)
  const midCategory = categories.filter(
    (c) => c?.parent?.id === (category?.parent?.id ?? category.id)
  )

  return (
    <>
      <Grid item xs={1.6}>
        <p
          style={{
            fontSize: '14px',
            color: '#707070',
            fontWeight: 'bold',
          }}
        >
          대 카테고리
        </p>
      </Grid>
      <Grid item xs={10}>
        <CustomDropDownField
          fullWidth
          disableClearable
          size="small"
          value={category?.parent?.korName ?? category.korName}
          onChange={onChange}
          ListboxProps={{
            style: {
              fontSize: '14px',
            },
          }}
          options={['', ...bigCategory.map((o) => o.korName)]}
          filterOptions={(options, { inputValue }) => {
            return options.filter(
              (option) => option !== '' && option.includes(inputValue)
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              label="카테고리 선택"
            />
          )}
        />
      </Grid>
      <Grid item xs={1.6}>
        <p
          style={{
            fontSize: '14px',
            color: '#707070',
            fontWeight: 'bold',
          }}
        >
          중 카테고리
        </p>
      </Grid>
      <Grid item xs={10}>
        <CustomDropDownField
          fullWidth
          disableClearable
          size="small"
          value={category.parent ? category.korName : ''}
          onChange={onChange}
          ListboxProps={{
            style: {
              fontSize: '14px',
            },
          }}
          options={['', ...midCategory.map((c) => c.korName)]}
          filterOptions={(options, { inputValue }) => {
            return options.filter(
              (option) => option !== '' && option.includes(inputValue)
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              label="카테고리 선택"
            />
          )}
        />
      </Grid>
    </>
  )
}

export default SelectCategory
