export const getToken = () => {
  try {
    const token = {}
    for (const entries of document.cookie.split(';')) {
      if (!entries) {
        return {}
      }
      const [key, value] = entries.split('=')
      token[key.trim()] = value.trim()
    }
    return token
  } catch (error) {
    return {}
  }
}

export const setToken = async (access, refresh) => {
  document.cookie = `access=${access}`
  document.cookie = `refresh=${refresh}`
}

export const clearToken = async () => {
  document.cookie = 'access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}
