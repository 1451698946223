import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function useCustomNavigation() {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const initPage = Number(searchParams.get('page'))
  const [_page, _setPage] = useState(initPage)

  const updateQuery = (entries) => {
    const searchParams = new URLSearchParams(location.search)

    for (const { key, value } of entries) {
      searchParams.set(key, value)
    }

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
  }

  useEffect(() => {
    const useQuery = new URLSearchParams(location.search)
    const page = useQuery.get('page')
    if (page) {
      _setPage(Number(page))
    }
  }, [location.search])

  const setPage = (page) => {
    if (page !== _page) {
      updateQuery([{ key: 'page', value: page }])
    }
  }

  return { page: _page, setPage }
}

export default useCustomNavigation
