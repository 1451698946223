import { Box, Divider, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { btnState } from './AddProductOption'
import React from 'react'

const item = (name, value) => ({
  name,
  value,
})

const season = [
  item('봄', 1),
  item('여름', 2),
  item('가을', 3),
  item('겨울', 4),
]
const lining = [item('있음', 'YES'), item('없음', 'NO'), item('기모', 'FLEECE')]

const stretch = [
  item('좋음', 'HIGH'),
  item('보통', 'NORMAL'),
  item('없음', 'NO'),
]
const show = [
  item('없음', 'NONE'),
  item('있음', 'YES'),
  item('약간', 'SLIGHTLY'),
]
const feel = [
  item('부드러움', 'SOFT'),
  item('보통', 'NORMAL'),
  item('까칠함', 'ROUGH'),
]
const fit = [
  item('오버핏', 'OVERSIZED'),
  item('정사이즈', 'REGULAR'),
  item('타이트', 'TIGHT'),
]
const thickness = [
  item('도톰', 'THICK'),
  item('보통', 'NORMAL'),
  item('얇음', 'THIN'),
]

const lists = [
  [item('안감', 'Lining'), lining],
  [item('신축성', 'Stretchiness'), stretch],
  [item('비침', 'Transparency'), show],
  [item('촉감', 'Texture'), feel],
  [item('핏', 'Fit'), fit],
  [item('두께', 'Thickness'), thickness],
]

const ProductMaterial = ({ product, setProduct }) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <p
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          소재 정보 입력
        </p>
      </Box>
      <Divider sx={{ height: 8, marginBottom: 2 }} />

      <Grid
        container
        spacing={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <>
          <Grid item xs={1}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              계절
            </p>
          </Grid>
          <Grid item xs={11}>
            {season.map(({ name, value }, innerIdx) => (
              <Button
                key={innerIdx}
                value={value}
                onClick={() => {
                  const idx = product.seasonIds.findIndex((id) => id === value)
                  if (idx === -1) {
                    product.seasonIds.push(value)
                  } else {
                    product.seasonIds.splice(idx, 1)
                  }
                  setProduct({ ...product })
                }}
                variant={btnState(product.seasonIds.includes(value)).variant}
                sx={{
                  borderRadius: 3,
                  marginRight: 1,
                  width: '100px',
                  height: '40px',
                  ...btnState(product.seasonIds.includes(value)).sx,
                }}
              >
                {name}
              </Button>
            ))}
          </Grid>
        </>

        {lists.map((list, idx) => (
          <React.Fragment key={idx}>
            <Grid item xs={1}>
              <p
                style={{
                  fontSize: '14px',
                  color: '#707070',
                  fontWeight: 'bold',
                }}
              >
                {list[0].name}
              </p>
            </Grid>
            <Grid item xs={11}>
              {list[1].map(({ name, value }, innerIdx) => (
                <Button
                  key={innerIdx}
                  value={value}
                  onClick={() => {
                    const type = list[0].value
                    const data = `${type}:${value}`

                    const tag = product.tags
                      .filter((tag) => tag.split(':')[0] === type)
                      .pop()

                    if (tag) {
                      const idx = product.tags.findIndex((t) => t === tag)
                      product.tags.splice(idx, 1)
                    }

                    if (tag !== data) {
                      product.tags.push(data)
                    }

                    setProduct({ ...product })
                  }}
                  variant={
                    btnState(product.tags.includes(`${list[0].value}:${value}`))
                      .variant
                  }
                  sx={{
                    borderRadius: 3,
                    marginRight: 1,
                    width: '100px',
                    height: '40px',
                    ...btnState(
                      product.tags.includes(`${list[0].value}:${value}`)
                    ).sx,
                  }}
                >
                  {name}
                </Button>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </>
  )
}

export default ProductMaterial
