import React from 'react';
import styled from 'styled-components';
import { COUPON_TEXT } from '../../pages/reward/couponIssued/logic';
import { getDate } from '../../utils';

const heads = [
  '발급일시',
  '분류',
  '쿠폰코드',
  '코드명',
  '할인율',
  '최대할인',
  '등록일',
  '만료일',
  '기한',
  '발급유저',
]

const CouponListTable = ({ coupons }) => {
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {coupons.map((coupon) => {
          const {
            id,
            createdAt,
            registeredAt,
            endAt,
            code,
            discountAmount,
            maxDiscountAmount,
            monthOfTerm,
            type,
            user,
            couponType,
          } = coupon

          return (
            <tr
              key={id}
              style={{
                borderBottom: '1px solid #ddd',
                height: '100px',
              }}
            >
              <Td>{getDate(createdAt)}</Td>
              <Td>{COUPON_TEXT[couponType]}</Td>
              <Td>{code}</Td>
              <Td>{type}</Td>
              <Td width="30px">{discountAmount * 100}%</Td>
              <Td>{maxDiscountAmount} $</Td>
              <Td>{getDate(registeredAt)}</Td>
              <Td>{getDate(endAt)}</Td>
              <Td>{monthOfTerm}개월</Td>
              <Td>{user ? user.nickname : ''}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default CouponListTable

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled.td`
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  width: ${(props) => props.width || 'auto'};
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
`
