import { ContentCopy } from '@mui/icons-material'
import { ButtonBase, Snackbar, Stack, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { Button } from '../../element/Button'
import { COUPON_TEXT } from '../../pages/reward/couponIssued/logic'
import { copyTextById, getDate } from '../../utils'

const HEADER = [
  '발행일시',
  '분류',
  '쿠폰 코드',
  '코드명',
  '할인율',
  '최대할인',
  '발급수량',
  '쿠폰 발급 기간',
  '쿠폰 유효 기간',
  '',
]

const CouponIssuedTable = ({ coupons, handler }) => {
  const [snackbar, setSnackBar] = React.useState(false)

  const copyCouponCode = (id) => {
    copyTextById(id)
    setSnackBar(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackBar(false)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        message="쿠폰코드가 복사되었습니다."
      />
      <Table>
        <thead>
          <tr>
            {HEADER.map((h, i) => (
              <Th key={`coupon_table_${i}`}>{h}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon) => {
            return (
              <tr style={{ padding: '10px' }} key={coupon.id}>
                <Td>{getDate(coupon.createdAt)}</Td>
                <Td>{COUPON_TEXT[coupon.couponType]}</Td>
                <Td>
                  <Stack direction="row" gap="10px" justifyContent="center">
                    <Typography id={`coupon_code_${coupon.code}`}>
                      {coupon.code}
                    </Typography>
                    <ButtonBase
                      onClick={() =>
                        copyCouponCode(`coupon_code_${coupon.code}`)
                      }
                    >
                      <ContentCopy sx={{ color: '#00a52d' }} />
                    </ButtonBase>
                  </Stack>
                </Td>
                <Td>{coupon.name}</Td>
                <Td>{coupon.discountAmount * 100}%</Td>
                <Td>$ {coupon.maxDiscountAmount.toLocaleString()}</Td>
                <Td>
                  {coupon.issuedCnt.toLocaleString()} /
                  {coupon.issueTotalCnt.toLocaleString()}
                </Td>
                <Td>
                  {getDate(coupon.issueStartAt, null, -13)} ~
                  <br />
                  {getDate(coupon.issueEndAt, null, -13)}
                </Td>
                <Td>
                  {getDate(coupon.validStartAt, null, -13)} ~
                  <br />
                  {getDate(coupon.validEndAt, null, -13)}
                </Td>
                <Td>
                  <Stack direction="row" gap="10px" justifyContent="center">
                    <Button bg="#00A52D" onClick={handler.id(coupon.id)}>
                      수정
                    </Button>
                    <Button bg="#FF0000" onClick={handler.delete(coupon.id)}>
                      삭제
                    </Button>
                  </Stack>
                </Td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default CouponIssuedTable

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`

const Th = styled.th`
  text-align: center;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
`

const Td = styled.td`
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  width: ${(props) => props.width || 'auto'};
  padding: 20px 0px;
  border-bottom: 1px solid #e4e4e4;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
`
