import { useEffect, useState } from 'react'
import { getUsers, updateGrade } from '../../api/user'

const useUserList = (keyword, socialType, grade, isActive) => {
  const [users, setUsers] = useState([])

  const [count, setCount] = useState(0)
  const [checked, setChecked] = useState([])
  const [allCount, setAllCount] = useState({ active: 0, all: 0 })

  const [page, setPage] = useState(1)

  const searchUsers = async () => {
    const { data, count, allCount, activeUserCount } = await getUsers(
      page,
      keyword,
      socialType,
      grade,
      isActive
    )

    setUsers(data)
    setCount(count)

    return { allCount, activeUserCount }
  }

  const initUsers = async () => {
    const { allCount, activeUserCount } = await searchUsers(
      page,
      keyword,
      socialType,
      grade
    )

    setAllCount({ active: activeUserCount, all: allCount })
  }

  useEffect(() => {
    if (allCount.all === 0) {
      initUsers()
    } else {
      searchUsers()
    }
    setChecked([])
  }, [page])

  const clickSearchBtn = async () => {
    await searchUsers(1, keyword, socialType, grade)
    setPage(1)
  }

  const approve = async (grade, checked) => {
    if (checked.length === 0) {
      return
    }
    const { success } = await updateGrade(
      checked.map((o) => o.id),
      grade
    )

    if (!success) {
      return
    }

    for (const user of checked) {
      user.grade = grade
    }

    setUsers([...users])
    setChecked([])
  }

  return {
    checked,
    approve,
    clickSearchBtn,
    count,
    users,
    setChecked,
    allCount,
    page,
    setPage,
  }
}

export default useUserList
