import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import styled from 'styled-components'
import { isValidDate } from '../../pages/transaction/sellList'

export default function BasicDatePicker({ date, setDate }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={['DatePicker', 'DatePicker']}
        sx={{ height: '100%', display: 'flex' }}
      >
        <DatePicker
          sx={style}
          value={date.startAt}
          onChange={(e) => {
            if (!isValidDate(e, date.endAt)) {
              return
            }

            setDate({ ...date, startAt: e.startOf('day') })
          }}
        />

        <P>부터</P>

        <DatePicker
          sx={style}
          value={date.endAt}
          onChange={(e) => {
            if (!isValidDate(date.startAt, e)) {
              return
            }

            setDate({ ...date, endAt: e.endOf('day') })
          }}
        />

        <P>까지</P>
      </DemoContainer>
    </LocalizationProvider>
  )
}

const style = { width: '300px' }

const P = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
`
