import { Divider, Grid, Radio, RadioGroup, SvgIcon } from '@mui/material'
import Box from '@mui/material/Box'
import Navigation from '../../components/common/navigation'
import { PreviewPostModal } from '../../components/modal/modal'
import PostTable from '../../components/post/post-table'
import { Button } from '../../element/Button'
import DefaultLayout from '../../global/DefaultLayout'
import useSelectModal from '../../hooks/common/modal/useSelectModal'
import usePostList from '../../hooks/post/usePostList'
import { InputTextField } from '../../element/InputTextField'
import { boxStyle } from '../product/productList'
import { Text } from '../../element/Text'
import SearchIcon from '@mui/icons-material/Search'
import { MyFormControlLabel } from '../../components/common/MyFormControlLabel'
import SelectBrand from '../../components/common/SelectBrand'

const BOOST_STATUS = [
  { name: '전체', value: '' },
  { name: '부스트', value: true },
]

const PostListPage = () => {
  const {
    posts,
    checked,
    setChecked,
    approve,
    clickHandler,
    setPage,
    totalCtn,
    preview,
    page,
    id,
    setPreview,
    setKeyword,
    option,
    setOption,
  } = usePostList()

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value)
    } else {
      setKeyword(option.keyword)
    }
  }

  const {
    modal: HideModal,
    openModal: openHideModal,
    closeModal: closeHideModal,
  } = useSelectModal({
    mainTxt: '숨김하시겠습니까?',
    leftTxt: '숨김',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: () => {
      approve('HIDE', checked, true)
      closeHideModal()
    },
  })

  const {
    modal: DeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useSelectModal({
    mainTxt: '삭제하시겠습니까?',
    leftTxt: '삭제',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: () => {
      approve('DEL', checked)
      closeDeleteModal()
    },
  })

  const {
    modal: BoostModal,
    openModal: openBoostModal,
    closeModal: closeBoostModal,
  } = useSelectModal({
    mainTxt: '부스트하시겠습니까?',
    leftTxt: '부스트',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: () => {
      approve('BOOST', checked, true)
      closeBoostModal()
    },
  })

  return (
    <DefaultLayout isLoggedIn>
      <HideModal />
      <DeleteModal />
      <BoostModal />

      <Box sx={boxStyle}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          상품 검색
        </p>
        <Divider sx={{ height: 16 }} />
        <Grid
          container
          spacing={3}
          sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}
        >
          <>
            <Grid item xs={1.6}>
              <Text size="14px" color="#707070" bold>
                검색어
              </Text>
            </Grid>
            <Grid item xs={10}>
              <InputTextField
                width="450px"
                label=""
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setOption({ ...option, keyword: e.target.value })
                }}
              />
            </Grid>
          </>

          <SelectBrand setValue={setOption} />

          <>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <Text size="14px" color="#707070" bold>
                판매상태
              </Text>
            </Grid>
            <Grid item xs={10}>
              <RadioGroup row defaultValue="all">
                {BOOST_STATUS.map(({ name, value }, idx) => (
                  <MyFormControlLabel
                    key={idx}
                    value="request"
                    control={
                      <Radio
                        onClick={() => {
                          setOption({
                            ...option,
                            isBoosted: value,
                          })
                        }}
                        size="small"
                        value={value}
                        checked={value === option.isBoosted}
                        sx={{
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                      />
                    }
                    label={name}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </>

          <Grid item xs={1.6}></Grid>
          <Grid item xs={10}>
            <Button
              size="large"
              bg="black"
              bold
              borderRadius={2}
              height="40px"
              onClick={handleKeyPress}
            >
              <SvgIcon component={SearchIcon} inheritViewBox />
              <p style={{ marginLeft: '10px' }}>상품 검색</p>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
          marginTop: '20px',
        }}
      >
        <Button
          height="40px"
          bg="#BACAFF"
          color="#003BFF"
          bold
          borderRadius="10px"
          sx={{ marginRight: '20px' }}
          onClick={openHideModal}
        >
          <p>선택 가리기</p>
        </Button>

        <Button
          height="40px"
          bg="#fff0f0"
          color="#ff0000"
          bold
          borderRadius="10px"
          sx={{ marginRight: '20px' }}
          onClick={openDeleteModal}
        >
          <p>선택 삭제</p>
        </Button>

        <Button height="40px" bold borderRadius="10px" onClick={openBoostModal}>
          <p>선택 부스트</p>
        </Button>

        <Divider sx={{ height: 16 }} />
        <PostTable
          posts={posts}
          checked={checked}
          setChecked={setChecked}
          approve={approve}
          clickHandler={clickHandler}
        />
        <Navigation page={page} setPage={setPage} count={totalCtn} />
      </Box>
      {preview && (
        <PreviewPostModal
          key={id}
          id={id}
          open={preview}
          onClose={() => {
            setPreview(false)
          }}
          posts={posts}
          approve={approve}
          setPreview={setPreview}
        />
      )}
    </DefaultLayout>
  )
}

export default PostListPage
