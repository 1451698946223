import styled from 'styled-components'
import Checkbox from '@mui/material/Checkbox'
import { Box } from '@mui/material'
import { getDate } from '../../utils'
import { Button } from '../../element/Button'

export const ReturnStatus = {
  Request: 'REQUEST',
  Admit: 'ADMIT',
  Denied: 'DENIED',
  Settled: 'SETTLED',
}

const heads = [
  '',
  '판매일',
  '주문번호',
  '구매자',
  '반품신청일',
  '반품주문번호',
  '반품사유',
  '해외결제가',
  '반송비용',
  '환불금액',
  '',
  '',
]

const ReturnRegisterTable = ({
  returns,
  checked,
  setChecked,
  clickApprove,
  clickDetail,
}) => {
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {returns.map((returnItem) => {
          const {
            id,
            returnNumber,
            refundItemsAmount,
            returnProcessingFee,
            issueType,
            status,
            order,
          } = returnItem

          return (
            <tr
              key={id}
              style={{
                borderBottom: '1px solid #ddd',
                height: '120px',
                fontWeight: 'bold',
              }}
            >
              <Td width={30}>
                <Box textAlign={'center'}>
                  <Checkbox
                    checked={checked.map((o) => o.id).includes(id)}
                    onClick={(e) => {
                      if (checked.map((o) => o.id).includes(id)) {
                        const idx = checked.findIndex((o) => o.id === id)
                        checked.splice(idx, 1)
                      } else {
                        checked.push(returnItem)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Box>
              </Td>
              <Td width="150px">{getDate(order.createdAt)}</Td>
              <Td>{order.orderNumber}</Td>
              <Td>{order.nickname}</Td>
              <Td width="150px">{getDate(returnItem.createdAt)}</Td>
              <Td>{returnNumber}</Td>
              <Td>{getIssueMsg(issueType)}</Td>
              <Td>{refundItemsAmount}</Td>
              <Td>{returnProcessingFee}</Td>
              <Td>{refundItemsAmount - returnProcessingFee}</Td>
              <Td
                width={150}
                onClick={() => {
                  clickDetail(returnItem)
                }}
              >
                <P>반품상품확인</P>
              </Td>
              {status === ReturnStatus.Request && (
                <Td width={150}>
                  <Button
                    height="40px"
                    bg="#BACAFF"
                    color="#003BFF"
                    bold
                    borderRadius="10px"
                    onClick={() => {
                      clickApprove([returnItem])
                    }}
                  >
                    <p>반품 승인하기</p>
                  </Button>
                </Td>
              )}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ReturnRegisterTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const P = styled('p')({
  cursor: 'pointer',
  display: 'inline',
  borderBottom: '1px solid',
  color: 'red',
})

const getIssueMsg = (issueType) => {
  switch (issueType) {
    case 'BUYER':
      return '구매자 귀책'
    case 'SELLER':
      return '판매자 귀책'
    case 'BOTH':
      return '판매자+구매자 귀칙'
    default:
      return ''
  }
}
