export const getSellerFromLocalStorage = () => {
  try {
    let seller = localStorage.getItem('seller')
    seller = JSON.parse(seller)

    return seller
  } catch (error) {
    return {}
  }
}

export const setSellerToLocalStorage = async (
  sellerName,
  isApproved,
  profileImg,
  sellerId
) => {
  const seller = {
    name: sellerName,
    isApproved,
    profileImg,
    sellerId,
  }
  localStorage.setItem('seller', JSON.stringify(seller))
}

export const clearLocalStorage = async () => {
  localStorage.clear()
}
