import { useEffect, useState } from 'react'
import { boostPosts, getPosts, hidePosts, removePosts } from '../../api/post'
import { useDispatch, useSelector } from 'react-redux'
import { getPostAction } from '../../redux/reducers/post'
import { getSellers } from '../../api/seller'

const usePostList = () => {
  const [id, setId] = useState('')
  const [preview, setPreview] = useState(false)

  const [posts, setPosts] = useState([])
  const [totalCtn, setTotalCtn] = useState(0)
  const [checked, setChecked] = useState([])

  const dispatch = useDispatch()

  const post = useSelector((state) => state.post)
  const page = post.page
  const [option, setOption] = useState({
    isBoosted: '',
    keyword: '',
    brand: '',
    brandOptions: [],
  })

  const setPage = (page) => {
    if (page !== post.page) {
      dispatch(getPostAction({ ...post, page }))
    }
  }

  const setKeyword = (keyword) => {
    dispatch(getPostAction({ ...post, keyword, page: 1 }))
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await getSellers(1, null, null, null, 100, true)
      const brands = data.map((o) => o.name)
      setOption({
        ...option,
        brandOptions: brands,
      })
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getPosts({
        page: post.page,
        keyword: post.keyword,
        isBoosted: option.isBoosted,
        brand: option.brand,
      })
      if (success) {
        setPosts(data.data)
        if (totalCtn !== data.count) {
          setTotalCtn(data.count)
        }
      }
    })()
    setChecked([])
  }, [post])

  const clickHandler = (id) => {
    setId(id)
    setPreview(true)
  }

  const approve = async (state, checked, isBoolean) => {
    if (checked.length === 0) {
      return
    }

    const fetchPosts =
      state === 'HIDE'
        ? hidePosts
        : state === 'BOOST'
        ? boostPosts
        : removePosts

    const { success } = await fetchPosts(
      checked.map((o) => o.id),
      isBoolean
    )

    if (!success) {
      return
    }

    if (state === 'HIDE') {
      for (const post of checked) {
        post.isHide = isBoolean
      }
      setPosts([...posts])
    } else if (state === 'BOOST') {
      for (const post of checked) {
        post.isBoosted = isBoolean
      }
      setPosts([...posts])
    } else {
      const filtered = posts.filter((post) => !checked.includes(post))
      setPosts([...filtered])
    }

    setChecked([])
  }

  return {
    posts,
    checked,
    setChecked,
    approve,
    clickHandler,
    setPage,
    totalCtn,
    preview,
    page,
    id,
    setPreview,
    setKeyword,
    option,
    setOption,
  }
}

export default usePostList
