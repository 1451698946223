import { Divider, Snackbar, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import SelectModal from '../../components/SelectModal'
import Navigation from '../../components/common/navigation'
import { Button } from '../../element/Button'
import DefaultLayout from '../../global/DefaultLayout'
import { admitReturns, getReturnsForRegister } from '../../api/return'
import ReturnRegisterTable, {
  ReturnStatus,
} from '../../components/return/return-register-table'
import { ReturnDetailModal } from '../../components/modal/return-modal'

const ReturnRegisterListPage = () => {
  const [checked, setChecked] = useState([])
  const [returns, setReturns] = useState([])
  const [option, setOption] = useState({
    page: 1,
    count: 0,
  })
  const [orderItems, setOrderItems] = useState([])

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getReturnsForRegister(option)
      if (success) {
        setReturns(data.data)
        if (option.count !== data.count) {
          setOption({ ...option, count: data.count })
        }
      }
    })()

    setChecked([])
  }, [option.page])

  const [modal, setModal] = useState({
    on: false,
    mainTxt: '',
    leftTxt: '',
    rightTxt: '',
    leftBg: '',
    rightBg: '',
    clickLeft: () => {},
  })

  const closeModal = () => {
    setModal({ ...modal, on: false })
    setChecked([])
  }

  const clickApprove = (checked) => {
    if (checked.length === 0) {
      return
    }

    setModal({
      on: true,
      mainTxt: '반품승인 처리하시겠습니까?',
      leftTxt: '승인',
      rightTxt: '취소',
      leftBg: 'black',
      rightBg: 'red',
      clickLeft: async () => {
        const { success } = await admitReturns(checked.map((o) => o.id))

        if (!success) {
          return
        }

        for (const check of checked) {
          check.status = ReturnStatus.Admit
        }

        setOrderItems([...orderItems])
        setChecked([])
        closeModal()
      },
    })
  }

  const [snackbar, setSnackbar] = useState({
    on: false,
    message: '',
  })

  const [detail, setDetail] = useState({
    onView: false,
    returnItem: null,
  })

  const clickDetail = (returnItem) => {
    setDetail({ onView: true, returnItem })
  }

  return (
    <DefaultLayout isLoggedIn>
      <>
        {detail.onView && (
          <ReturnDetailModal
            detail={detail}
            onClose={() =>
              setDetail({ ...detail, onView: false, returnItem: null })
            }
            approve={clickApprove}
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbar.on}
          onClose={() => setSnackbar(false)}
          message={snackbar.message}
          autoHideDuration={3000}
        />
        <SelectModal
          open={modal.on}
          handleClose={closeModal}
          clickModalBtn={modal.clickLeft}
          mainTxt={modal.mainTxt}
          leftTxt={modal.leftTxt}
          rightTxt={modal.rightTxt}
          leftBg={modal.leftBg}
          rightBg={modal.rightBg}
        />
      </>

      <Box sx={boxStyle}>
        <Typography sx={{ fontWeight: 700 }}>판매 목록</Typography>

        <Box display="flex" gap={2} marginTop={3}>
          <Button
            height="40px"
            bg="#BACAFF"
            color="#003BFF"
            bold
            borderRadius="10px"
            onClick={() => {
              clickApprove(checked)
            }}
          >
            <p>반품 승인하기</p>
          </Button>
        </Box>

        <Divider sx={{ height: 30 }} />

        <ReturnRegisterTable
          returns={returns}
          setReturns={setReturns}
          checked={checked}
          setChecked={setChecked}
          clickApprove={clickApprove}
          clickDetail={clickDetail}
        />

        <Navigation
          page={option.page}
          setPage={(value) => {
            setOption({ ...option, page: value })
          }}
          count={option.count}
        />
      </Box>
    </DefaultLayout>
  )
}
export default ReturnRegisterListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}
