import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import Navigation from '../../components/common/navigation'
import DefaultLayout from '../../global/DefaultLayout'
import useTestLogic from './logic'

const InstaPage = () => {
  const { value, handler } = useTestLogic()

  return (
    <DefaultLayout isLoggedIn>
      <Stack bgcolor="#ffffff" height="100%">
        <Stack padding={5}>
          <Typography fontWeight={700} fontSize={20}>
            상품 검색
          </Typography>
          <Box height={20} />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={1}>
              <TextField
                sx={{ width: 450 }}
                value={value.keyword}
                onChange={handler.keyword}
              />
              <Button variant="contained" onClick={handler.search}>
                검색
              </Button>
            </Stack>
            <Button variant="contained" onClick={handler.excel}>
              Excel 추출하기
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Grid container padding={5}>
          <Grid item xs={6}>
            <Typography fontWeight={500} fontSize={20}>
              검색 목록
            </Typography>
            <ProductTable products={value.products} onClick={handler.add} />
            <Navigation
              page={value.page}
              setPage={handler.page}
              count={value.count}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight={500} fontSize={20}>
              선택 상품
            </Typography>
            <ProductTable products={value.selects} onClick={handler.remove} />
          </Grid>
        </Grid>
      </Stack>
    </DefaultLayout>
  )
}

export default InstaPage

const ProductTable = ({ products, onClick }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {/* <TableCell>id</TableCell> */}
          <TableCell width={100}>이미지</TableCell>
          <TableCell>상품명</TableCell>
          <TableCell>상품 설명</TableCell>
          <TableCell width={120}>판매가/할인가</TableCell>
          <TableCell width={100}>브랜드</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((p) => (
          <TableRow onClick={() => onClick(p)} key={p.id}>
            {/* <TableCell>{p.id}</TableCell> */}
            <TableCell>
              <img src={p.imgs[0]} alt="상품 이미지" width={40} height={40} />
            </TableCell>
            <TableCell>{p.name}</TableCell>
            <TableCell>
              <Typography
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
              >
                {p.description}
              </Typography>
            </TableCell>
            <TableCell>
              {p.price}/{p.discountedPrice}
            </TableCell>
            <TableCell>{p.seller.name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
