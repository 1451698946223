import { getDate, getResizeUrl, priceWrapper } from '../../utils'

import { Typography } from '@mui/material'
import styled from 'styled-components'
import Image from '../../element/Image'

const heads = [
  '반품여부',
  '판매/반품일',
  '주문번호',
  '상품주문번호',
  '대표이미지',
  '상품명',
  '구매자',
  '해외판매가',
  '정산/반품금액',
]

const DetailTable = ({ data }) => {
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <Typography>{head}</Typography>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((order, idx) => {
          const isReturn = order.status === 'RETURN_REQUEST'

          return (
            <tr
              key={idx}
              style={{ borderBottom: '1px solid #ddd', height: '100px' }}
            >
              <Td>{isReturn ? 'Y' : 'N'}</Td>
              <Td>{getDate(order?.createdAt)}</Td>
              <Td>{order.order.orderNumber}</Td>
              <Td>{order.order.orderItemNumber}</Td>
              <Td>
                <Image
                  alt="notice"
                  src={getResizeUrl(order.product.imgs[0], 320, false)}
                  width="75px"
                  height="75px"
                  borderRadius={'10px'}
                  border="1px solid #E4E4E4"
                  padding="2px"
                />
              </Td>
              <Td>{order?.product?.name}</Td>
              <Td>{order?.order?.nickname}</Td>
              <Td>{priceWrapper(order?.domesticPrice ?? 0)}</Td>
              <Td>{priceWrapper(order?.transactionPrice ?? 0)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default DetailTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})
