import { Box, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../../element/Button'
import Image from '../../element/Image'
import { getDate, getResizeUrl } from '../../utils'

const heads = [
  'ID',
  '이메일',
  '닉네임',
  '사진',
  '내용',
  '성별',
  '태그상품',
  '태그상품번호',
  '등록일',
  '',
  '',
  '',
  '',
]

const PostTable = ({ posts, checked, setChecked, approve, clickHandler }) => {
  const REF_COUNT = 5
  const buttonRef = useRef([])

  const isAllChecked = posts.every((post) =>
    checked.map((o) => o.id).includes(post.id)
  )

  const rowHandler = (e, id) => {
    for (let i = 0; i < buttonRef.current?.length; i += 1) {
      let ref = buttonRef.current[i]
      if (ref?.contains(e.target)) {
        return
      }
    }
    clickHandler(id)
  }

  const navigation = useNavigate()

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  setChecked([])
                } else {
                  setChecked([...posts])
                }
              }}
            />
          </Th>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {posts.map((post, index) => {
          const [contentLine1, ...line] = post.content.split(' ')
          const contentLine2 = line.join('')
          return (
            <tr
              key={post.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
              onClick={(e) => {
                rowHandler(e, post.id)
              }}
            >
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    ref={(el) => (buttonRef.current[index * REF_COUNT] = el)}
                    checked={checked.map((o) => o.id).includes(post.id)}
                    onClick={(e) => {
                      if (checked.map((o) => o.id).includes(post.id)) {
                        const idx = checked.findIndex((o) => o.id === post.id)
                        checked.splice(idx, 1)
                      } else {
                        checked.push(post)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Box>
              </Td>

              <Td width="150">{post.id}</Td>
              <Td width="100">{post.seller.user.email}</Td>

              <Td width="100">{post.seller.name}</Td>

              <Td>
                <Image
                  alt="post"
                  src={getResizeUrl(post.imgs[0], 320, false)}
                  width="75px"
                  height="75px"
                  borderRadius={'10px'}
                  border="1px solid #E4E4E4"
                  padding="2px"
                  onError={(e) => {
                    e.currentTarget.src = post.imgs[0]
                  }}
                />
              </Td>

              <Td
                style={{
                  width: '250px',
                  height: '100px',
                }}
              >
                <PBox>{contentLine1}</PBox>
                <PBox>{contentLine2}</PBox>
              </Td>

              <Td
                style={{
                  width: '40px',
                }}
              >
                {post.gender ? (
                  post.gender
                ) : (
                  <Typography color="red" fontWeight="bold" fontSize="10px">
                    성별X
                  </Typography>
                )}
              </Td>

              <Td>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {post.products?.length === 0 ? (
                    <></> ? (
                      post.products.length <= 2
                    ) : (
                      <Box>
                        {post.products?.map((p, idx) => (
                          <Image
                            key={idx}
                            alt="p"
                            src={p.imgs[0]}
                            onError={(e) => {
                              e.currentTarget.src = p.imgs[0]
                            }}
                          />
                        ))}
                      </Box>
                    )
                  ) : (
                    <>
                      <Box>
                        {post.products?.slice(0, 2).map((p, idx) => (
                          <Image
                            key={idx}
                            alt="p"
                            src={getResizeUrl(p.imgs[0], 320, false)}
                            width="75px"
                            height="75px"
                            borderRadius={'10px'}
                            border="1px solid #E4E4E4"
                            padding="2px"
                            onError={(e) => {
                              e.currentTarget.src = p.imgs[0]
                            }}
                          />
                        ))}
                      </Box>

                      <Box>
                        {post.products?.slice(2, 4).map((p, idx) => (
                          <Image
                            key={idx}
                            alt="p"
                            src={getResizeUrl(p.imgs[0], 320, false)}
                            width="75px"
                            height="75px"
                            borderRadius={'10px'}
                            border="1px solid #E4E4E4"
                            padding="2px"
                            onError={(e) => {
                              e.currentTarget.src = p.imgs[0]
                            }}
                          />
                        ))}
                      </Box>
                    </>
                  )}
                </Box>
              </Td>

              <Td width="100">
                {post.products.map((p) => p.productNumber).join(', ')}
              </Td>
              <Td width="150">{getDate(post.createdAt)}</Td>

              <Td
                style={{ width: '80px' }}
                ref={(el) => {
                  buttonRef.current[index * REF_COUNT + 1] = el
                }}
              >
                <Button
                  bold
                  borderRadius="10px"
                  onClick={() => {
                    approve('BOOST', [post], !post.isBoosted)
                  }}
                >
                  <p>{post.isBoosted ? '취소' : '부스트'}</p>
                </Button>
              </Td>

              <Td
                style={{ width: '80px' }}
                ref={(el) => {
                  buttonRef.current[index * REF_COUNT + 2] = el
                }}
              >
                <Button
                  height="36px"
                  bg="#BACAFF"
                  color="#003BFF"
                  bold
                  borderRadius="10px"
                  onClick={() => {
                    approve('HIDE', [post], !post.isHide)
                  }}
                >
                  <p>{post.isHide ? '해제' : '가리기'}</p>
                </Button>
              </Td>

              <Td
                style={{ width: '80px' }}
                ref={(el) => {
                  buttonRef.current[index * REF_COUNT + 3] = el
                }}
              >
                <Button
                  bg="#BAFFD5"
                  color="#00A52D"
                  bold
                  borderRadius="10px"
                  onClick={() => {
                    navigation(`/snap/add/${post.id}`)
                  }}
                >
                  <p>수정</p>
                </Button>
              </Td>

              <Td
                style={{ width: '80px' }}
                ref={(el) => {
                  buttonRef.current[index * REF_COUNT + 4] = el
                }}
              >
                <Button
                  width="56px"
                  height="36px"
                  bg="#fff0f0"
                  color="#ff0000"
                  bold
                  borderRadius="10px"
                  onClick={() => {
                    approve('DEL', [post])
                  }}
                >
                  <p>삭제</p>
                </Button>
              </Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default PostTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const PBox = styled('div')({
  width: '250px',
  boxSizing: 'border-box',
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
