import SearchIcon from '@mui/icons-material/Search'
import { Divider, Grid, SvgIcon } from '@mui/material'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useState } from 'react'
import Navigation from '../../components/common/navigation'
import UserTable from '../../components/user/user-table'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import { Text } from '../../element/Text'
import DefaultLayout from '../../global/DefaultLayout'
import useSelectModal from '../../hooks/common/modal/useSelectModal'
import useUserList from '../../hooks/user/useUserList'
import { MyFormControlLabel } from '../../components/common/MyFormControlLabel'

const REGISTER_PATH = [
  { name: '전체', value: '' },
  { name: '애플', value: 'APPLE' },
  { name: '구글', value: 'GOOGLE' },
  { name: '이메일', value: 'DEFAULT' },
]

const GRADE = [
  { name: '전체', value: '' },
  { name: '일반', value: 'NORMAL' },
  { name: '블랙리스트', value: 'BLACKLIST' },
]

const ACTIVE = [
  { name: '전체', value: '' },
  { name: '가입자', value: true },
]

const UserListPage = () => {
  const [grade, setGrade] = useState('')
  const [socialType, setSocialType] = useState('')
  const [isActive, setIsActive] = useState('')
  const [keyword, setKeyword] = useState('')

  const {
    checked,
    approve,
    clickSearchBtn,
    count,
    users,
    setChecked,
    allCount,
    page,
    setPage,
  } = useUserList(keyword, socialType, grade, isActive)

  const clickBlacklistBtn = () => {
    if (checked.length === 0) {
      return
    }

    openBlackListModal()
  }

  const {
    modal: BlackListModal,
    openModal: openBlackListModal,
    closeModal: closeBlackListModal,
  } = useSelectModal({
    mainTxt: '블랙리스트에 추가하시겠습니까?',
    leftTxt: '추가',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: () => {
      approve('BLACKLIST', checked)
      closeBlackListModal()
    },
  })

  return (
    <DefaultLayout isLoggedIn>
      <BlackListModal />

      <Box sx={boxStyle}>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={1.6}>
            <Text color="#707070" bold>
              유저 검색
            </Text>
          </Grid>

          <Grid item xs={10}>
            <InputTextField
              width="300px"
              label="아이디 또는 닉네임 입력"
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
            />
          </Grid>

          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <Text color="#707070" bold>
              가입 경로
            </Text>
          </Grid>

          <Grid item xs={10}>
            <RadioGroup row defaultValue="all">
              {REGISTER_PATH.map(({ name, value }, idx) => (
                <MyFormControlLabel
                  key={idx}
                  value={value}
                  control={
                    <Radio
                      onClick={() => {
                        setSocialType(value)
                      }}
                      size="small"
                      value={value}
                      checked={value === socialType}
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                  }
                  label={name}
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <Text color="#707070" bold>
              등급
            </Text>
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex' }}>
            <RadioGroup row defaultValue="all">
              {GRADE.map(({ name, value }, idx) => (
                <MyFormControlLabel
                  key={idx}
                  value={value}
                  control={
                    <Radio
                      onClick={() => {
                        setGrade(value)
                      }}
                      size="small"
                      value={value}
                      checked={value === grade}
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                  }
                  label={name}
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <Text color="#707070" bold>
              활성이용자
            </Text>
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex' }}>
            <RadioGroup row defaultValue="all">
              {ACTIVE.map(({ name, value }, idx) => (
                <MyFormControlLabel
                  key={idx}
                  value={value}
                  control={
                    <Radio
                      onClick={() => {
                        setIsActive(value)
                      }}
                      size="small"
                      value={value}
                      checked={value === isActive}
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                  }
                  label={name}
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={1.6}></Grid>
          <Grid item xs={10}>
            <Button
              size="large"
              height="50px"
              borderRadius={2}
              onClick={clickSearchBtn}
            >
              <SvgIcon component={SearchIcon} inheritViewBox />
              <Text size="16px" color="white" margin="0 0 0 10px" bold>
                유저 검색
              </Text>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={boxStyle}>
        <Box display="flex" gap={3}>
          <Text size="18px" bold>
            총 이용자 수 : {allCount.all} 명
          </Text>

          <Text size="18px" bold>
            활성 이용자 수 : {allCount.active} 명
          </Text>
        </Box>

        <Divider sx={{ height: 16 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
          <Button
            variant="contained"
            bg="#fff0f0"
            color="#ff0000"
            bold
            borderRadius={2}
            height="44px"
            onClick={clickBlacklistBtn}
          >
            <p>블랙리스트 설정</p>
          </Button>
        </Box>
        <Divider sx={{ height: 16 }} />

        <UserTable
          users={users}
          checked={checked}
          setChecked={setChecked}
          approve={approve}
        />
        <Navigation page={page} setPage={setPage} count={count} />
      </Box>
    </DefaultLayout>
  )
}

export default UserListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}
