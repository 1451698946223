import React from 'react'
import { editPost } from '../../api/post'
import { TAG_DOT_SIZE } from '../../components/snap/ProductLocs'
import { ALL_TAG_MAP } from '../../fixture/snap_tag'
import usePost from '../../hooks/post/usePost'
import { POST_IMAGE_SIZE } from './EditPost'

const useEditPost = (id) => {
  const { loading, post, opponentTag, handler } = usePost(id)

  // loc 움직이는 인덱스 확인용
  const [locPid, setLocPid] = React.useState('')

  // 이미지 좌표 확인 Ref
  const imageRef = React.useRef([])

  const _checkTag = (tag) => post.tags?.includes(tag)

  const genderStyle = {
    getStyle: (val) =>
      post.gender === val
        ? { backgroundColor: 'black', color: '', borderColor: '' }
        : { backgroundColor: '', color: '#707070', borderColor: '#707070' },
    getVariantStyle: (val) => (post.gender === val ? 'contained' : 'outlined'),
  }

  const style = {
    getVariantStyle: (tag) => (_checkTag(tag) ? 'contained' : 'outlined'),
    getBackgroundColor: (tag) => (_checkTag(tag) ? 'black' : ''),
    getForegroundColor: (tag) => (_checkTag(tag) ? '' : '#707070'),
    getDisable: (tag) => {
      const isInOpponent = opponentTag.some(
        (t) => t.opponentTag.tag === ALL_TAG_MAP[tag]
      )
      const isInCheck = _checkTag(ALL_TAG_MAP[tag])
      return isInOpponent && !isInCheck
    },
  }

  const getProductLocsByUrl = (index) => {
    const result = post?.productLocs?.filter((e) => e.imgIndex === index)

    return result
  }

  const dragStart = (pid) => () => {
    setLocPid(pid)
  }

  // calculate x, y
  const _getValidLocXY = (point) => {
    point = Math.max(0 + TAG_DOT_SIZE / 2, point)
    point = Math.min(point, POST_IMAGE_SIZE - TAG_DOT_SIZE / 2)
    return point / POST_IMAGE_SIZE
  }

  const dragEnd = (imageIndex) => (e) => {
    const findLocIndex = post?.productLocs.findIndex(
      (e) => e.pid === locPid && e.imgIndex === imageIndex
    )
    const findLoc = post.productLocs[findLocIndex]

    const { x, y } = imageRef.current[imageIndex].getBoundingClientRect()
    const { clientX, clientY } = e

    let locX = clientX - x
    let locY = clientY - y

    locX = _getValidLocXY(locX)
    locY = _getValidLocXY(locY)

    findLoc.x = locX
    findLoc.y = locY

    post.productLocs.splice(findLocIndex, 1, findLoc)

    setLocPid('')
  }

  const modify = async () => {
    const response = await editPost(id, {
      content: post.content,
      imgs: post.imgs,
      tags: post.tags,
      productIds: [...new Set(post.products.map((p) => p.id))],
      productLocs: post.productLocs,
      gender: post.gender,
      modelWeight: parseFloat(post.modelWeight),
      modelHeight: parseFloat(post.modelHeight),
      modelSizes: post.modelSizes,
    })
    return response
  }

  handler.getProductLocsByUrl = getProductLocsByUrl
  handler.dragStart = dragStart
  handler.dragEnd = dragEnd
  handler.modify = modify

  return {
    loading,
    post,
    opponentTag,
    handler,
    style,
    imageRef,
    genderStyle,
  }
}

export default useEditPost
