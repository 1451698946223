import { Divider, Snackbar, SvgIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import {
  calculateReturn,
  changeReturnStatus,
  getReturnsForList,
} from '../../api/return';
import RadioSelect from '../../components/RadioSelect';
import DatePickerValue from '../../components/common/DataPicker';
import Navigation from '../../components/common/navigation';
import ReturnListTable from '../../components/return/return-list-table';
import { Button } from '../../element/Button';
import { InputTextField } from '../../element/InputTextField';
import DefaultLayout from '../../global/DefaultLayout';
import useSelectModal from '../../hooks/common/modal/useSelectModal';

const RETURN_STATUS = [
  { name: '전체', value: '' },
  { name: '반품요청', value: 'REQUEST' },
  { name: '배송승인', value: 'ADMIT' },
  { name: '반품거절', value: 'DENIED' },
  { name: '정산완료', value: 'SETTLED' },
]

const RETURN_ITEM_STATUS = [
  { name: '전체', value: '' },
  { name: '반품수거중', value: 'SHIPPING' },
  { name: '센터보관완료', value: 'RECEIVED_COMPLETE' },
  { name: '미입고', value: 'NOT_IN' },
  { name: '파트너반송', value: 'PARTNER_RETURN' },
]

export const PRODUCT_STATUS = [
  { name: '전체', value: '' },
  { name: '검수대기', value: 'DEFAULT' },
  { name: '재판매가능', value: 'CAN_SELLING' },
  { name: '재판매불가', value: 'NOT_SELLING' },
]

const SETTLED_PAYMENT_STATUS = [
  { name: '전체', value: '' },
  { name: '정산대기', value: false },
  { name: '정산완료', value: true },
]

const ReturnListPage = () => {
  const [checked, setChecked] = useState([])
  const [returns, setReturns] = useState([])
  const [option, setOption] = useState({
    page: 1,
    returnStatus: '',
    returnItemStatus: '',
    productStatus: '',
    resellStatus: '',
    startAt: dayjs().startOf('day'),
    endAt: dayjs(),
    count: 0,
  })

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getReturnsForList(option)
      if (success) {
        setReturns(data.data)
        if (option.count !== data.count) {
          setOption({ ...option, count: data.count })
        }
      }
    })()

    setChecked([])
  }, [option.page])

  const clickSearchBtn = async () => {
    const { success, data } = await getReturnsForList({ ...option, page: 1 })

    if (success) {
      setReturns(data.data)
      setOption({ ...option, page: 1 })
      if (option.count !== data.count) {
        setOption({ ...option, count: data.count })
      }
    }
  }

  const [focusDate, setFocusDate] = useState('')

  const checkDateBtnList = [
    {
      name: '오늘',
      onClick: () => {
        setOption({
          ...option,
          startAt: dayjs().startOf('day'),
          endAt: dayjs(),
        })

        setFocusDate('오늘')
      },
    },
    {
      name: '1주일',
      onClick: () => {
        setOption({
          ...option,
          startAt: dayjs().subtract(7, 'day'),
          endAt: dayjs(),
        })
        setFocusDate('1주일')
      },
    },
    {
      name: '1개월',
      onClick: () => {
        setOption({
          ...option,
          startAt: dayjs().subtract(1, 'month'),
          endAt: dayjs(),
        })
        setFocusDate('1개월')
      },
    },
    {
      name: '3개월',
      onClick: () => {
        setOption({
          ...option,
          startAt: dayjs().subtract(3, 'month'),
          endAt: dayjs(),
        })
        setFocusDate('3개월')
      },
    },
  ]

  // handler
  const changeStatus = async (returnItemIds, itemStatuses, productStatuses) => {
    const { success } = await changeReturnStatus({
      returnItemIds,
      itemStatuses,
      productStatuses,
    })

    if (!success) return

    let updated = [...returns]

    for (let i = 0; i < updated.length; i++) {
      const update = updated[i]
      let items = update.returnItems

      for (let j = 0; j < items.length; j++) {
        const item = items[j]

        const findIndex = returnItemIds.indexOf(item.id)
        if (findIndex === -1) continue

        items[j].status = itemStatuses[findIndex]
        items[j].returnProductStatus = productStatuses[findIndex]
      }

      updated[i] = { ...updated[i], returnItems: items }
    }

    setReturns(updated)
  }

  const calculate = async (id) => {
    const isOk = window.confirm('정산처리 하시겠습니까?')

    if (!isOk) return

    const { success } = await calculateReturn(id)

    if (!success) return alert('정산에 실패했습니다.')

    let updated = [...returns]
    for (let i = 0; i < updated.length; i++) {
      const update = updated[i]
      if (update.id !== id) continue

      updated[i].status = 'SETTLED'
    }
    setReturns(updated)
  }

  const {
    modal: CollectModal,
    openModal: openCollectModal,
    closeModal: closeCollectModal,
  } = useSelectModal({
    mainTxt: '선택 상품을 수거완료 처리하시겠습니까?',
    leftTxt: '수거완료',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: async () => {
      if (checked.filter((o) => o.status !== 'SHIPPING').length !== 0) {
        alert('반품수거중 상태에서만 수거완료 처리를 할 수 있습니다.')
        closeCollectModal()
        return
      }
      await changeStatus(
        checked.map((c) => c.id),
        Array(checked.length).fill('RECEIVED_COMPLETE'),
        checked.map((c) => c.returnProductStatus)
      )

      setChecked([])
      closeCollectModal()
    },
  })

  const {
    modal: AvaliableSaleModal,
    openModal: openAvaliableSaleModal,
    closeModal: closeAvaliableSaleModal,
  } = useSelectModal({
    mainTxt: '선택 상품을 판매가능 처리하시겠습니까?',
    leftTxt: '판매가능',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: async () => {
      if (
        checked.filter((o) => o.returnProductStatus !== 'DEFAULT').length !== 0
      ) {
        alert('검수대기 상태에서만 판매가능 처리를 할 수 있습니다.')
        closeCollectModal()
        return
      }
      await changeStatus(
        checked.map((c) => c.id),
        checked.map((c) => c.status),
        Array(checked.length).fill('CAN_SELLING')
      )
      setChecked([])
      closeAvaliableSaleModal()
    },
  })

  const {
    modal: NotAvaliableSaleModal,
    openModal: openNotAvaliableSaleModal,
    closeModal: closeNotAvaliableSaleModal,
  } = useSelectModal({
    mainTxt: '선택 상품을 판매불가 처리하시겠습니까?',
    leftTxt: '판매불가',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: async () => {
      if (
        checked.filter((o) => o.returnProductStatus !== 'DEFAULT').length !== 0
      ) {
        alert('검수대기 상태에서만 수거완료 처리를 할 수 있습니다.')
        closeCollectModal()
        return
      }
      await changeStatus(
        checked.map((c) => c.id),
        checked.map((c) => c.status),
        Array(checked.length).fill('NOT_SELLING')
      )
      setChecked([])
      closeNotAvaliableSaleModal()
    },
  })

  const {
    modal: PartnerReturnModal,
    openModal: openPartnerReturnModal,
    closeModal: closePartnerReturnModal,
  } = useSelectModal({
    mainTxt: '선택 상품을 파트너스 반품 처리하시겠습니까?',
    leftTxt: '입고',
    rightTxt: '취소',
    leftBg: 'black',
    rightBg: 'red',
    submit: async () => {
      if (
        checked.filter((o) => o.status !== 'RECEIVED_COMPLETE').length !== 0
      ) {
        alert('반품수거중 상태에서만 수거완료 처리를 할 수 있습니다.')
        closeCollectModal()
        return
      }
      await changeStatus(
        checked.map((c) => c.id),
        Array(checked.length).fill('PARTNER_RETURN'),
        checked.map((c) => c.returnProductStatus)
      )
      setChecked([])
      closePartnerReturnModal()
    },
  })

  const [snackbar, setSnackbar] = useState({
    on: false,
    message: '',
  })

  return (
    <DefaultLayout isLoggedIn>
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbar.on}
          onClose={() => setSnackbar(false)}
          message={snackbar.message}
          autoHideDuration={3000}
        />
        <CollectModal />
        <AvaliableSaleModal />
        <NotAvaliableSaleModal />
        <PartnerReturnModal />
      </>

      <Box sx={boxStyle}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            날짜/상품별 조회
          </p>

          <Box width={30} />

          <p
            style={{
              marginTop: 'auto',
              color: '#707070',
            }}
          >
            * 조회 기간 선택 시 결제 기준으로 적용됩니다.
          </p>
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox ai="start">
            <p
              style={{
                color: '#707070',
              }}
            >
              조회기간 <span style={{ color: 'red' }}>*</span> :
            </p>
          </LeftBox>

          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={1}>
              {checkDateBtnList.map(({ name, onClick }, idx) =>
                focusDate === name ? (
                  <Button
                    key={idx}
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                ) : (
                  <Button
                    key={idx}
                    color="#707070"
                    bg="white"
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                )
              )}
            </Box>
            <DatePickerValue date={option} setDate={setOption} />
          </Box>
        </OptionContainer>

        <p
          style={{
            marginLeft: 130,
            color: '#ff0000',
          }}
        >
          조회기간은 최대 1년 이내 기간으로 입력해주세요.
        </p>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              검색 :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label=""
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            onChange={(e) => {
              setOption({ ...option, keyword: e.target.value })
            }}
          />
        </OptionContainer>

        <RadioSelect
          label="반품상태"
          list={RETURN_ITEM_STATUS}
          setState={(value) => {
            setOption({ ...option, returnStatus: value })
          }}
          state={option.returnStatus}
        />

        <RadioSelect
          label="상품상태"
          list={PRODUCT_STATUS}
          setState={(value) => {
            setOption({ ...option, productStatus: value })
          }}
          state={option.productStatus}
        />

        {/* <RadioSelect
          label="재판매여부"
          list={SETTLED_PAYMENT_STATUS}
          setState={(value) => {
            setOption({ ...option, resellStatus: value })
          }}
          state={option.resellStatus}
        /> */}

        <OptionContainer>
          <Button
            size="large"
            bold
            borderRadius={2}
            height="40px"
            sx={{
              marginLeft: '130px',
            }}
            onClick={clickSearchBtn}
          >
            <SvgIcon component={SearchIcon} inheritViewBox />
            <p style={{ marginLeft: '10px' }}>검색</p>
          </Button>
        </OptionContainer>
      </Box>

      <Box sx={boxStyle}>
        <Typography sx={{ fontWeight: 700 }}>판매 목록</Typography>

        <Box display="flex" gap={2} marginTop={3}>
          <Button
            height="40px"
            bg="#E4E4E4"
            color="black"
            bold
            borderRadius="10px"
            onClick={openCollectModal}
          >
            <p>선택 수거완료 처리</p>
          </Button>

          <Button
            height="40px"
            bg="#BACAFF"
            color="#003BFF"
            bold
            borderRadius="10px"
            onClick={openAvaliableSaleModal}
          >
            <p>선택 판매가능 처리</p>
          </Button>

          <Button
            height="40px"
            bg="#FFF0F0"
            color="#FF0000"
            bold
            borderRadius="10px"
            onClick={openNotAvaliableSaleModal}
          >
            <p>선택 판매불가 처리</p>
          </Button>

          <Button
            height="40px"
            bg="#BAFFD5"
            color="#00A52D"
            bold
            borderRadius="10px"
            onClick={openPartnerReturnModal}
          >
            <p>선택 파트너스반품 처리</p>
          </Button>
        </Box>

        <Divider sx={{ height: 30 }} />

        <ReturnListTable
          returns={returns}
          setReturns={setReturns}
          checked={checked}
          setChecked={setChecked}
          changeStatus={changeStatus}
          calculate={calculate}
        />

        <Navigation
          page={option.page}
          setPage={(value) => {
            setOption({ ...option, page: value })
          }}
          count={option.count}
        />
      </Box>
    </DefaultLayout>
  )
}
export default ReturnListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

export const LeftBox = ({ children, ai }) => (
  <Box
    width={100}
    display="flex"
    justifyContent="right"
    alignItems={ai ? ai : 'center'}
    marginRight={3}
  >
    {children}
  </Box>
)

export const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)

export const isValidDate = (startAt, endAt) => {
  if (startAt && endAt) {
    const start = new Date(startAt)
    const end = new Date(endAt)

    if (start > end) {
      alert('조회 기간 끝나는 날짜가 더 이를 수 없습니다.')
      return false
    }

    if (end - start > 1000 * 60 * 60 * 24 * 365) {
      alert('조회기간은 최대 1년 이내로 선택해주세요.')
      return false
    }

    return true
  }
}
