import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import CouponIssuedFormPartner from '../../components/reward/coupon-issued-form-partner'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import { COUPON_TEXT, COUPON_TYPE } from '../../pages/reward/couponIssued/logic'

const CouponIssuedForm = ({ form, handler, category, id, brands }) => {
  console.log(form.brand)

  return (
    <>
      <OptionContainer
        left={<Typography color="#707070">쿠폰 코드명:</Typography>}
        right={
          <InputTextField
            width="300px"
            value={form.name}
            name="name"
            onChange={handler.form}
          />
        }
      />
      <Stack direction="row" alignItems="center" gap="60px">
        <OptionContainer
          left={<Typography color="#707070">발급수량:</Typography>}
          right={
            <Stack direction="row" alignItems="center" gap="10px">
              <InputTextField
                width="300px"
                value={form.count}
                name="count"
                onChange={handler.form}
              />
              <Typography color="#707070">개</Typography>
            </Stack>
          }
        />
        <OptionContainer
          left={<Typography color="#707070">할인율:</Typography>}
          right={
            <Stack direction="row" alignItems="center" gap="10px">
              <InputTextField
                width="300px"
                value={form.discount}
                name="discount"
                onChange={handler.form}
              />
              <Typography color="#707070">%</Typography>
            </Stack>
          }
        />
        <OptionContainer
          left={<Typography color="#707070">최대 할인금액:</Typography>}
          right={
            <Stack direction="row" alignItems="center" gap="10px">
              <InputTextField
                width="300px"
                value={form.maxDiscount}
                name="maxDiscount"
                onChange={handler.form}
              />
              <Typography color="#707070">USD</Typography>
            </Stack>
          }
        />
      </Stack>
      <OptionContainer
        left={<Typography color="#707070">쿠폰 코드:</Typography>}
        right={
          <Stack direction="row" alignItems="end" gap="10px">
            <InputTextField
              width="300px"
              value={form.code}
              name="code"
              onChange={handler.form}
            />
            <Typography
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'blue',
              }}
              onClick={handler.random}
            >
              생성
            </Typography>
          </Stack>
        }
      />
      <OptionContainer
        left={<Typography color="#707070">쿠폰 분류:</Typography>}
        right={
          <Stack direction="column">
            <Stack direction="row" gap="10px" height={60} alignItems="center">
              {Object.values(COUPON_TYPE).map((g, i) => (
                <Button
                  key={g}
                  fs={14}
                  width={90}
                  height={40}
                  name="type"
                  onClick={handler.type(g)}
                  value={g}
                  color={form.type === g ? '#fffff' : '#707070'}
                  bg={form.type === g ? '#000000' : '#ffffff'}
                  border="1px solid #e4e4e4"
                  bold={form.type === g}
                >
                  {COUPON_TEXT[g]}
                </Button>
              ))}
            </Stack>
            <OptionContainer
              left={<Typography color="#707070">기획전 id</Typography>}
              right={
                <InputTextField
                  width="300px"
                  value={form.promotionId ?? ''}
                  name="promotionId"
                  onChange={handler.form}
                />
              }
            />
            {form.type === COUPON_TYPE.category && (
              <Stack direction="row" gap="60px">
                <OptionContainer
                  left={<Typography color="#707070">대카테고리:</Typography>}
                  right={
                    <FormControl sx={{ minWidth: 300 }}>
                      <Select
                        labelId="big-category"
                        value={form.bigCategory ?? ''}
                        onChange={handler.category}
                        name="bigCategory"
                        disabled={form.type !== COUPON_TYPE.category}
                      >
                        {category.map((c) => {
                          return (
                            <MenuItem key={c.korName} value={c.id}>
                              {c.korName}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  }
                />
                <OptionContainer
                  left={<Typography color="#707070">중카테고리:</Typography>}
                  right={
                    <FormControl
                      sx={{ minWidth: 300 }}
                      disabled={!form?.bigCategory}
                    >
                      <Select
                        labelId="mid-category"
                        value={form.midCategory ?? ''}
                        onChange={handler.category}
                        name="midCategory"
                      >
                        {form.bigCategory &&
                          category
                            .filter((c) => c.id === form.bigCategory)[0]
                            ?.children.map((c) => {
                              return (
                                <MenuItem key={c.korName} value={c.id}>
                                  {c.korName}
                                </MenuItem>
                              )
                            })}
                      </Select>
                    </FormControl>
                  }
                />
              </Stack>
            )}
            {form.type === COUPON_TYPE.brand && (
              <OptionContainer
                left={<Typography color="#707070">브랜드 id</Typography>}
                right={
                  <CouponIssuedFormPartner
                    value={form.brand}
                    handler={handler.brand}
                    brands={brands}
                  />
                }
              />
            )}
          </Stack>
        }
      />
      <OptionContainer
        left={<Typography color="#707070">발급기간:</Typography>}
        right={
          <Stack direction="row" gap="20px">
            <Stack direction="row" gap="10px" alignItems="center">
              <DateTimePicker
                timeSteps={{ hours: 1, minutes: 1 }}
                value={form.issueStartAt}
                onChange={handler.date('issueStartAt')}
              />
              <Typography color="#707070">부터</Typography>
            </Stack>
            <Stack direction="row" gap="10px" alignItems="center">
              <DateTimePicker
                timeSteps={{ hours: 1, minutes: 1 }}
                value={form.issueEndAt}
                onChange={handler.date('issueEndAt')}
              />
              <Typography color="#707070">까지</Typography>
            </Stack>
          </Stack>
        }
      />
      <OptionContainer
        left={<Typography color="#707070">유효기간:</Typography>}
        right={
          <Stack direction="row" gap="20px">
            <Stack direction="row" gap="10px" alignItems="center">
              <DateTimePicker
                timeSteps={{ hours: 1, minutes: 1 }}
                value={form.validStartAt}
                onChange={handler.date('validStartAt')}
              />
              <Typography color="#707070">부터</Typography>
            </Stack>
            <Stack direction="row" gap="10px" alignItems="center">
              <DateTimePicker
                timeSteps={{ hours: 1, minutes: 1 }}
                value={form.validEndAt}
                onChange={handler.date('validEndAt')}
              />
              <Typography color="#707070">까지</Typography>
            </Stack>
          </Stack>
        }
      />
      <OptionContainer
        right={
          <Stack direction="row" gap="10px">
            <Button
              size="large"
              bold
              borderRadius={2}
              height="40px"
              onClick={handler.submit}
            >
              <p>{id !== null ? '수정하기' : '발급하기'}</p>
            </Button>
            {id && (
              <Button
                size="large"
                bold
                borderRadius={2}
                height="40px"
                onClick={handler.id(null)}
              >
                <p>취소</p>
              </Button>
            )}
          </Stack>
        }
      />
    </>
  )
}

export default CouponIssuedForm

const OptionContainer = ({ left, right }) => {
  return (
    <Stack paddingTop="20px" direction="row">
      <Box width={100} display="flex" marginRight={3} paddingTop="15px">
        {left}
      </Box>
      {right}
    </Stack>
  )
}
