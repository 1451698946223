import styled from 'styled-components'
import { getDate } from '../../utils'
import React from 'react'

const heads = [
  '발급일시',
  '크레딧 코드',
  '코드명',
  '크레딧',
  '잔여크레딧',
  '등록일',
  '만료일',
  '기한',
  '발급유저',
]

const CreditListTable = ({ credits }) => {
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {credits.map((credit) => {
          const {
            id,
            createdAt,
            registeredAt,
            endAt,
            code,
            monthOfTerm,
            name,
            user,
            amount,
            totalAmount,
          } = credit

          return (
            <tr
              key={id}
              style={{
                borderBottom: '1px solid #ddd',
                height: '100px',
              }}
            >
              <Td>{getDate(createdAt)}</Td>
              <Td>{code}</Td>
              <Td>{name}</Td>
              <Td>${totalAmount.toFixed(2)}</Td>
              <Td>${amount.toFixed(2)}</Td>
              <Td>{getDate(registeredAt)}</Td>
              <Td>{getDate(endAt)}</Td>
              <Td>{monthOfTerm}개월</Td>
              <Td>{user ? user.nickname : ''}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default CreditListTable

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled.td`
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  width: ${(props) => props.width || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
