import { instance } from './instance'

export const getNotices = async (page, keyword, type) => {
  try {
    const query = { page, type, keyword }

    const queryStr = Object.keys(query)
      .filter((key) => query[key])
      .map((key) => `${key}=${query[key]}`)
      .join('&')

    const { data } = await instance.get(`/api/notice/admin?${queryStr}`, {
      token: true,
    })

    return data
  } catch (error) {
    throw error
  }
}

export const removeNotice = async (id) => {
  try {
    await instance.delete(`/api/notice/${id}`, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const addNotice = async (body) => {
  try {
    await instance.post(`/api/notice`, body, { token: true })
  } catch (error) {
    throw error
  }
}

export const editNotice = async (body) => {
  try {
    await instance.patch(`/api/notice`, body, { token: true })
  } catch (error) {
    throw error
  }
}

export const getNotice = async (id) => {
  try {
    const { data } = await instance.get(`/api/notice/detail/${id}`, {
      token: true,
    })

    return data
  } catch (error) {
    throw error
  }
}
