import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { removeNotice } from '../../api/notice'
import { Button } from '../../element/Button'
import Image from '../../element/Image'
import { getDate } from '../../utils'

const heads = ['분류', '제목', '이미지', '등록일', '수정', '삭제']

const NoticeTable = ({ notices, setNotices, clickHandler }) => {
  const navigation = useNavigate()

  const buttonRef = useRef([])

  const rowHandler = (e, id) => {
    for (let i = 0; i < buttonRef.current?.length; i += 1) {
      let ref = buttonRef.current[i]
      if (ref?.contains(e.target)) {
        return
      }
    }
    clickHandler(id)
  }

  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {notices.map((notice, index) => {
          return (
            <tr
              key={notice.id}
              style={{ borderBottom: '1px solid #ddd', height: 120 }}
              onClick={(e) => rowHandler(e, notice.id)}
            >
              <Td width="200px" height="100px">
                {notice.type}
              </Td>
              <Td>{notice.title}</Td>
              <Td style={{ width: '200px' }}>
                <Image
                  alt="notice"
                  src={notice.imgs[0]}
                  width="75px"
                  height="75px"
                  borderRadius={'10px'}
                  border="1px solid #E4E4E4"
                  padding="2px"
                />
              </Td>
              <Td width="200px">{getDate(notice.createdAt)}</Td>
              <Td
                style={{ width: '150px' }}
                ref={(el) => {
                  buttonRef.current[index * 2] = el
                }}
              >
                <Button
                  height="36px"
                  bg="#BACAFF"
                  color="#003BFF"
                  bold
                  borderRadius="10px"
                  onClick={() => {
                    navigation(`/notice/${notice.id}`)
                  }}
                >
                  <p>수정</p>
                </Button>
              </Td>
              <Td
                style={{ width: '150px' }}
                ref={(el) => {
                  buttonRef.current[index * 2 + 1] = el
                }}
              >
                <Button
                  height="36px"
                  bg="#FFF0F0"
                  color="#FF0000"
                  bold
                  borderRadius="10px"
                  onClick={async () => {
                    await removeNotice(notice.id)
                    const filtered = notices.filter((n) => n !== notice)
                    setNotices([...filtered])
                  }}
                >
                  <p>삭제</p>
                </Button>
              </Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default NoticeTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})
