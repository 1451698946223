import { Box, FormControl, MenuItem, Select } from '@mui/material'

import Checkbox from '@mui/material/Checkbox'
import styled from 'styled-components'
import Image from '../../element/Image'
import { getDate } from '../../utils'

const heads = [
  '가입경로',
  '등급',
  '이메일 주소',
  '닉네임',
  '번호',
  '프로필 이미지',
  '광고성정보 수신동의여부',
  '생성일자',
]

const UserTable = ({ users, checked, setChecked, approve }) => {
  const isAllChecked = users.every((user) =>
    checked.map((o) => o.id).includes(user.id)
  )

  const handleGrade = (grade, user) => {
    approve(grade, [user])
  }

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  setChecked([])
                } else {
                  setChecked([...users])
                }
              }}
            />
          </Th>
          {heads.map((head, idx) => (
            <Th key={`${head}_${idx}`}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <tr
              key={user.id}
              style={{ borderBottom: '1px solid #ddd', height: '100px' }}
            >
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    checked={checked.map((o) => o.id).includes(user.id)}
                    onClick={(e) => {
                      if (checked.map((o) => o.id).includes(user.id)) {
                        const idx = checked.findIndex((o) => o.id === user.id)
                        checked.splice(idx, 1)
                      } else {
                        checked.push(user)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Box>
              </Td>

              <Td>{wrapperType(user.socialType)}</Td>
              <Td width="150px" height="100px">
                <FormControl sx={{ width: '120px', height: '40px' }}>
                  <Select
                    value={user.grade}
                    onChange={(e) => {
                      handleGrade(e.target.value, user)
                    }}
                  >
                    <MenuItem value="NORMAL">
                      <p>일반</p>
                    </MenuItem>
                    <MenuItem value="BLACKLIST">
                      <p>블랙리스트</p>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Td>
              <Td style={{ width: '300px' }}>{user.email}</Td>
              <Td style={{ width: '300px' }}>{user.nickname}</Td>
              <Td style={{ width: '200px' }}>
                {user.phoneNumber
                  ? `${user.phoneCountryCode} ${user.phoneNumber}`
                  : ''}
              </Td>
              <Td style={{ width: '100px' }}>
                <Image
                  alt="user"
                  src={getProfileImgSrc(user.profileImg)}
                  width="50px"
                  height="50px"
                />
              </Td>
              <Td>{user.agreement?.receiveEmail ? 'Y' : 'N'}</Td>
              <Td>{getDate(user.createdAt)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default UserTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const wrapperType = (socialType) => {
  if (socialType === 'APPLE') {
    return '애플'
  } else if (socialType === 'GOOGLE') {
    return '구글'
  }
  return '이메일'
}

export const getProfileImgSrc = (img) => {
  if (img) {
    return img
  }
  return 'https://i.namu.wiki/i/Bge3xnYd4kRe_IKbm2uqxlhQJij2SngwNssjpjaOyOqoRhQlNwLrR2ZiK-JWJ2b99RGcSxDaZ2UCI7fiv4IDDQ.webp'
}
