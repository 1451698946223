import { TextField } from '@mui/material'
import React from 'react'
import { CustomDropDownField } from '../common/CustomDropdownField'

const CouponIssuedFormPartner = ({ value, handler, brands }) => {
  const options = ['', ...brands.map((b) => b.name)]

  return (
    <CustomDropDownField
      disableClearable
      size="small"
      value={value?.name ?? ''}
      onChange={handler}
      ListboxProps={{ style: { fontSize: '14px' } }}
      options={options}
      filterOptions={(options, { inputValue }) => {
        return options.filter(
          (option) =>
            option && option.toLowerCase().includes(inputValue.toLowerCase())
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            style: { fontSize: '14px' },
          }}
          label="브랜드 선택"
        />
      )}
    />
  )
}

export default CouponIssuedFormPartner
