import { Radio, RadioGroup } from '@mui/material'
import { LeftBox, OptionContainer } from '../pages/transaction/sellList'
import { MyFormControlLabel } from './common/MyFormControlLabel'

const RadioSelect = ({
  label,
  list,
  state,
  setState,
  width,
  labelBold,
  left,
}) => {
  return (
    <OptionContainer>
      <LeftBox width={width} left={left}>
        <p
          style={{
            color: '#707070',
            fontWeight: labelBold ? 700 : 400,
          }}
        >
          {label} :
        </p>
      </LeftBox>
      <RadioGroup row defaultValue="all">
        {list.map(({ name, value }, idx) => (
          <MyFormControlLabel
            key={idx}
            value="request"
            control={
              <Radio
                onClick={() => {
                  setState(value)
                }}
                size="small"
                value={value}
                checked={value === state}
                sx={{
                  '&.Mui-checked': {
                    color: 'black',
                  },
                }}
              />
            }
            label={name}
          />
        ))}
      </RadioGroup>
    </OptionContainer>
  )
}

export default RadioSelect
