import { instance } from './instance'

export const getCoupons = async ({
  page,
  type,
  code,
  monthOfTerm,
  amount,
  size,
}) => {
  try {
    const queryObj = {
      page,
      type,
      code,
      monthOfTerm,
      amount,
      size,
    }

    let query = ''
    for (const [key, value] of Object.entries(queryObj)) {
      if (value || typeof value === 'boolean') {
        query += `&${key}=${value}`
      }
    }

    const { data } = await instance.get(`/api/coupon?${query}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const createCoupon = async ({ code, type, monthOfTerm }) => {
  try {
    await instance.post(
      `/api/coupon/create`,
      {
        code,
        type,
        monthOfTerm,
      },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

export const getCredits = async ({
  page,
  type,
  code,
  monthOfTerm,
  amount,
  size,
}) => {
  try {
    const queryObj = {
      page,
      type,
      code,
      monthOfTerm,
      amount,
      size,
    }

    let query = ''
    for (const [key, value] of Object.entries(queryObj)) {
      if (value || typeof value === 'boolean') {
        query += `&${key}=${value}`
      }
    }

    const { data } = await instance.get(`/api/credit?${query}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const createCredit = async ({ code, name, monthOfTerm, amount }) => {
  try {
    await instance.post(
      `/api/credit/generate`,
      {
        code,
        name,
        monthOfTerm,
        amount,
      },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

/**
 * 쿠폰 발급 api
 * @param {{code: string,
 * name: string,
 * issueTotalCnt: number,
 * discountAmount: number,
 * maxDiscountAmount: number,
 * couponType: string,
 * categoryId: number,
 * issueStartAt: string,
 * issueEndAt: string,
 * validStartAt: string,
 * validEndAt: string
 * promotionId: string
 * }} body
 * @returns {Promise<{success: boolean}>}
 */
export const createConditionCoupon = async (body) => {
  try {
    await instance.post(`/api/coupon/coupon-condition`, body, { token: true })
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

/**
 * 쿠폰 발급 api
 * @param {{code: string,
 * name: string,
 * issueTotalCnt: number,
 * discountAmount: number,
 * maxDiscountAmount: number,
 * couponType: string,
 * categoryId: number,
 * issueStartAt: string,
 * issueEndAt: string,
 * validStartAt: string,
 * validEndAt: string
 * couponConditionId: string
 * promotionId: string
 * }} body
 * @returns {Promise<{success: boolean}>}
 */
export const editConditionCoupon = async (body) => {
  try {
    await instance.put(`/api/coupon/coupon-condition`, body, { token: true })
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

export const getConditionCoupons = async (page) => {
  try {
    const { data } = await instance.get(`/api/coupon/condition?page=${page}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}

export const getConditionCoupon = async (id) => {
  try {
    const { data } = await instance.get(`/api/coupon/condition/${id}`, {
      token: true,
    })

    return { success: true, data: data.data }
  } catch (error) {
    return { success: false }
  }
}

export const deleteConditionCoupon = async (id) => {
  try {
    await instance.delete(
      `/api/coupon/coupon-condition?couponConditionId=${id}`,
      { token: true }
    )
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}
