import { Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { createCoupon, getCoupons } from '../../api/reward'
import Navigation from '../../components/common/navigation'
import CouponListTable from '../../components/reward/coupon-list-table'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'

const CouponListPage = () => {
  const [option, setOption] = useState({
    page: 1,
    type: '',
    code: '',
    amount: '',
    monthOfTerm: 1,
    count: 0,
  })
  const [coupons, setCoupons] = useState([])

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getCoupons(option)
      if (success) {
        setCoupons(data.data)
        if (option.count !== data.count) {
          setOption({ ...option, count: data.count })
        }
      }
    })()
  }, [option.page])

  const clickSearchBtn = async () => {
    const { success } = await createCoupon(option)
    if (!success) {
      alert('에러 발생')
    } else {
      alert('발급완료')
    }
  }

  const checkDateBtnList = [
    {
      name: '1개월',
      value: 1,
      onClick: () => {
        setOption({
          ...option,
          monthOfTerm: 1,
        })
      },
    },
    {
      name: '3개월',
      value: 3,
      onClick: () => {
        setOption({
          ...option,
          monthOfTerm: 3,
        })
      },
    },
    {
      name: '6개월',
      value: 6,
      onClick: () => {
        setOption({
          ...option,
          monthOfTerm: 6,
        })
      },
    },
    {
      name: '12개월',
      value: 12,
      onClick: () => {
        setOption({
          ...option,
          monthOfTerm: 12,
        })
      },
    },
  ]

  function generateRandomCode() {
    const code = generateRandomString(6)
    setOption({ ...option, code })
  }

  return (
    <DefaultLayout isLoggedIn>
      <Box sx={boxStyle}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            쿠폰 코드 생성
          </p>

          <Box width={30} />
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              코드명 :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label=""
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            value={option.type}
            onChange={(e) => {
              setOption({ ...option, type: e.target.value })
            }}
          />
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              쿠폰코드 :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label=""
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
            }}
            value={option.code}
            onChange={(e) => {
              setOption({ ...option, code: e.target.value })
            }}
          />

          <Box display="flex" flexDirection="column-reverse" marginLeft={1}>
            <p
              onClick={generateRandomCode}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'blue',
              }}
            >
              생성
            </p>
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              쿠폰기한 :
            </p>
          </LeftBox>

          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={1}>
              {checkDateBtnList.map(({ name, value, onClick }, idx) => (
                <Button
                  key={idx}
                  color={value === option.monthOfTerm ? 'black' : '#707070'}
                  bg="white"
                  border={
                    value === option.monthOfTerm
                      ? '1px solid #000000'
                      : '1px solid #e4e4e4'
                  }
                  borderRadius="6px"
                  onClick={onClick}
                  bold={value === option.monthOfTerm}
                >
                  {name}
                </Button>
              ))}
            </Box>
          </Box>
        </OptionContainer>

        <OptionContainer>
          <Button
            size="large"
            bold
            borderRadius={2}
            height="40px"
            sx={{
              marginLeft: '130px',
            }}
            onClick={clickSearchBtn}
          >
            <p style={{ marginLeft: '10px' }}>발급하기</p>
          </Button>
        </OptionContainer>
      </Box>

      <Box sx={boxStyle}>
        <Typography sx={{ fontWeight: 700 }}>쿠폰 정보</Typography>

        <Divider sx={{ height: 30 }} />
        <Divider sx={{ height: 30 }} />

        <CouponListTable coupons={coupons} />

        <Navigation
          page={option.page}
          setPage={(value) => {
            setOption({ ...option, page: value })
          }}
          count={option.count}
        />
      </Box>
    </DefaultLayout>
  )
}
export default CouponListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

export const LeftBox = ({ children, ai }) => (
  <Box
    width={100}
    display="flex"
    justifyContent="right"
    alignItems={ai ? ai : 'center'}
    marginRight={3}
  >
    {children}
  </Box>
)

export const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)

export const isValidDate = (startAt, endAt) => {
  if (startAt && endAt) {
    const start = new Date(startAt)
    const end = new Date(endAt)

    if (start > end) {
      alert('조회 기간 끝나는 날짜가 더 이를 수 없습니다.')
      return false
    }

    if (end - start > 1000 * 60 * 60 * 24 * 365) {
      alert('조회기간은 최대 1년 이내로 선택해주세요.')
      return false
    }

    return true
  }
}

export function generateRandomString(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
