import dressLongSleeve from '../../static/size/Dress(long sleeve).png'
import dressShortSleeve from '../../static/size/Dress(short sleeve).png'
import dressSleeveless from '../../static/size/Dress(sleeveless).png'
import dressTurtleNeck from '../../static/size/Dress(turtle neck).png'
import jacketLong from '../../static/size/Jacket(long).png'
import jacketShort from '../../static/size/Jacket(short).png'
import jumpsuit from '../../static/size/Jumpsuit.png'
import paddedVest from '../../static/size/Padded vest.png'
import pants from '../../static/size/Pants.png'
import pufferJacket from '../../static/size/Puffer jacket.png'
import shirts from '../../static/size/Shirts.png'
import shorts from '../../static/size/Shorts.png'
import skirt from '../../static/size/Skirt.png'
import tShirtSleeveless from '../../static/size/T shirt(sleeveless).png'
import tShirtTurtleNeck from '../../static/size/T shirt(turtle neck).png'
import tShirtShortSleeve from '../../static/size/T shirts(short sleeve).png'
import tShirtLongSleeve from '../../static/size/T shirts(long sleeve).png'
import cardigan from '../../static/size/outer_cardigan.png'

const option = ['A', 'B', 'C']
const option0 = [...option, 'D']
const option1 = [...option0, 'E']
const option2 = [...option1, 'F']
const option3 = [...option2, 'G']
const option4 = ['A', 'B', 'C', 'E']
const option5 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']
const option6 = ['A', 'B']

const wrapper = (url, options) => ({
  url,
  options,
})

export const getSizeObj = (categoryId) => {
  switch (categoryId) {
    case 5:
      return wrapper(cardigan, option2)
    case 6:
      return wrapper(jacketLong, option2)
    case 7:
      return wrapper(jacketShort, option2)
    case 8:
      return wrapper(pufferJacket, option2)
    case 9:
      return wrapper(paddedVest, option4)
    case 10:
      return wrapper(tShirtLongSleeve, option2)
    case 11:
      return wrapper(tShirtShortSleeve, option2)
    case 12:
      return wrapper(tShirtTurtleNeck, option3)
    case 13:
      return wrapper(tShirtSleeveless, option1)
    case 14:
      return wrapper(shirts, option2)
    case 15:
      return wrapper(dressShortSleeve, option2)
    case 16:
      return wrapper(dressLongSleeve, option3)
    case 17:
      return wrapper(dressSleeveless, option1)
    case 18:
      return wrapper(dressTurtleNeck, option3)
    case 19:
      return wrapper(pants, option1)
    case 20:
      return wrapper(shorts, option1)
    case 21:
      return wrapper(jumpsuit, option2)
    case 22:
      return wrapper(skirt, option)
    case 26:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775372103_CO-ORDSETS-Suit.jpg',
        option5
      )
    case 27:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775371251_CO-ORDSETS-Sportswear.jpg',
        option5
      )
    case 28:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775309107_ACC-Cap.jpg',
        option
      )
    case 29:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713774969241_ACC-Beanie.jpg',
        option6
      )
    case 30:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775364759_ACC-Hat.jpg',
        option6
      )
    case 31:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775369163_Bag-MiniBag.jpg',
        option
      )
    case 32:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775365701_Bag-Backpack.jpg',
        option
      )
    case 33:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775368315_Bag-MessengerBag.jpg',
        option0
      )
    case 34:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775370001_Bag-ToteBag.jpg',
        option
      )
    case 35:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775366517_Bag-ClutchBag.jpg',
        option
      )
    case 36:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1713775367457_Bag-DuffelBag.jpg',
        option
      )
    case 37:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1718349943793_CO-ORDSETS-Bikini.jpg',
        option1
      )
    case 38:
      return wrapper(
        'https://by-snap.s3.amazonaws.com/category/1718594239840_Top-Bikini.jpg',
        option
      )
    case 39:
      return wrapper(
        'https://bysnap.s3.amazonaws.com/category/1718599314814_BottomBikini2.jpg',
        option6
      )
    case 41:
      return wrapper(
        'https://bysnap.s3.amazonaws.com/category/1718599782102_One-piece-Jumpsuit.jpg',
        option2
      )
    case 42:
      return wrapper(
        'https://bysnap.s3.amazonaws.com/category/1718599781816_One-piece-Swimsuit.jpg',
        option3
      )
    default:
      return wrapper('', [])
  }
  // return img url
}
