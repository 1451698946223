import {
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { addShipment, cancelShipment } from '../../api/dhl'

import dayjs from 'dayjs'
import { useState } from 'react'
import { OUTER_SHIPPING_STATUS } from '../../pages/transaction/sellList'

/**
 * DHL Invoice Modal
 */
const TABLE_HEAD = ['주문번호', '구매자', '상품주문번호', '상품명', '판매일']

export const MakeInvoiceModal = ({
  open,
  onClose,
  checked,
  setChecked,
  orderItems,
  setOrderItems,
  setSnackbar,
}) => {
  const today = new Date().toISOString()
  const [form, setForm] = useState({
    date: dayjs(today.slice(0, 10)),
    kg: '',
    width: '', // 가로
    height: '', // 세로
    length: '', // 높이
  })

  const { date, kg, width, height, length } = form

  const dateHandler = (date) => setForm((prev) => ({ ...prev, date }))
  const kgHandler = (kg) => setForm((prev) => ({ ...prev, kg }))
  const widthHandler = (width) => setForm((prev) => ({ ...prev, width }))
  const heightHandler = (height) => setForm((prev) => ({ ...prev, height }))
  const lengthHandler = (length) => setForm((prev) => ({ ...prev, length }))

  const submit = async () => {
    const day = date.day()
    // 0: sunday, 6: saturday
    if (day === 0 || day === 6) {
      setSnackbar({ on: true, message: '주말은 선택할 수 없습니다.' })
      return
    }

    const SELECT = new Date(date.format('YYYY-MM-DD'))
    const TODAY = new Date()
    const DAY = 24 * 60 * 60 * 1000
    const DIFF = Math.ceil((SELECT.getTime() - TODAY.getTime()) / DAY)
    // not after day
    if (DIFF < 0) {
      setSnackbar({ on: true, message: '오늘 포함 이후를 선택해주세요.' })
      return
    }

    // after 10 day
    if (DIFF > 10) {
      setSnackbar({ on: true, message: '10일 이내로 선택해주세요.' })
      return
    }

    const bodyKg = parseFloat(kg)
    if (!bodyKg) {
      setSnackbar({ on: true, message: '올바른 무게 값이 아닙니다.' })
      return
    }

    const mWidth = parseFloat(width)
    const mHeight = parseFloat(height)
    const mLength = parseFloat(length)
    if (!mWidth && !mHeight && !mLength) {
      setSnackbar({ on: true, message: '올바른 사이즈 값이 아닙니다.' })
      return
    }

    const body = {
      orderItemIds: checked.map((c) => c.id),
      plannedShippingDate: date.format('YYYY-MM-DD'),
      weight: bodyKg,
      width: mWidth,
      height: mHeight,
      length: mLength,
    }

    try {
      // call api
      const { data } = await addShipment(body)

      // change status
      const checkedId = checked.map((c) => c.id)
      const newOrderItems = orderItems.map((o) => {
        if (!checkedId.includes(o.id)) return o
        const newOrderItem = {
          ...o,
          outerShippingStatus: OUTER_SHIPPING_STATUS[3].value,
          shipment: data,
        }
        return newOrderItem
      })
      setOrderItems(newOrderItems)
      setChecked([])
      setForm({
        date: dayjs(today.slice(0, 10)),
        kg: '',
        width: '', // 가로
        height: '', // 세로
        length: '', // 높이
      })
      onClose()
    } catch (error) {
      // error...
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '955px',
          height: '700px',
          bgcolor: 'white',
          borderRadius: '20px',
          boxSizing: 'border-box',
          padding: '25px',
          overflow: 'auto',
        }}
      >
        <Stack direction="column" gap="20px">
          <>
            <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
              선택 발송 정보 입력
            </Typography>
            <Divider />
            <Typography sx={{ fontSize: 12, color: '#707070' }}>
              선택된 발송 가능한 주문 건 목록입니다.
            </Typography>
          </>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{ fontWeight: 700, color: '#707070', fontSize: 14 }}
            >
              택배사
            </Typography>
            <Box sx={{ width: 30 }} />
            <Typography sx={{ fontWeight: 700 }}>DHL</Typography>
            <Box sx={{ width: 120 }} />
            <Typography sx={{ fontSize: 14, color: '#707070' }}>
              픽업날짜
            </Typography>
            <Typography sx={{ fontWeight: 700, color: '#ff0000' }}>
              *
            </Typography>
            <Box sx={{ width: 20 }} />
            <DatePicker
              views={['year', 'month', 'day']}
              value={date}
              onChange={(e) => dateHandler(e)}
              format={'YYYY-MM-DD'}
            />
            <TimePicker
              disabled
              defaultValue={dayjs(today.slice(0, 11) + '15:00')}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ color: '#707070', fontSize: 14 }}>
              무게
            </Typography>
            <Typography sx={{ fontWeight: 700, color: '#ff0000' }}>
              *
            </Typography>
            <Box sx={{ width: 30 }} />
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={kg}
              onChange={(e) => kgHandler(e.target.value)}
            />
            <Box sx={{ width: 10 }} />
            <Typography
              sx={{ fontWeight: 700, color: '#707070', fontSize: 14 }}
            >
              kg
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ color: '#707070', fontSize: 14 }}>
              사이즈
            </Typography>
            <Typography
              sx={{ fontWeight: 700, color: '#ff0000', fontSize: 14 }}
            >
              *
            </Typography>
            <Box sx={{ width: 20 }} />
            <Typography sx={{ color: '#707070', fontSize: 14 }}>
              가로
            </Typography>
            <Box sx={{ width: 10 }} />
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={width}
              onChange={(e) => widthHandler(e.target.value)}
            />
            <Box sx={{ width: 10 }} />
            <Typography
              sx={{ fontWeight: 700, color: '#707070', fontSize: 14 }}
            >
              cm
            </Typography>
            <Box sx={{ width: 20 }} />
            <Typography sx={{ color: '#707070', fontSize: 14 }}>
              세로
            </Typography>
            <Box sx={{ width: 10 }} />
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={height}
              onChange={(e) => heightHandler(e.target.value)}
            />
            <Box sx={{ width: 10 }} />
            <Typography
              sx={{ fontWeight: 700, color: '#707070', fontSize: 14 }}
            >
              cm
            </Typography>
            <Box sx={{ width: 20 }} />
            <Typography sx={{ color: '#707070', fontSize: 14 }}>
              높이
            </Typography>
            <Box sx={{ width: 10 }} />
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={length}
              onChange={(e) => lengthHandler(e.target.value)}
            />
            <Box sx={{ width: 10 }} />
            <Typography
              sx={{ fontWeight: 700, color: '#707070', fontSize: 14 }}
            >
              cm
            </Typography>
          </Stack>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((h, i) => {
                    return (
                      <TableCell
                        key={`involice_table_head_${i}`}
                        align="center"
                      >
                        {h}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {checked.map((order, i) => {
                  return (
                    <TableRow key={`invoice_table_row_${i}`}>
                      <TableCell align="center">
                        {order.order.orderNumber}
                      </TableCell>
                      <TableCell align="center">
                        {order.user.nickname}
                      </TableCell>
                      <TableCell align="center">
                        {order.orderItemNumber}
                      </TableCell>
                      <TableCell align="center">{order.product.name}</TableCell>
                      <TableCell align="center">
                        {order.createdAt.slice(0, 10)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" justifyContent="end" gap="40px">
            <Button
              variant="contained"
              sx={{
                color: '#f57979',
                backgroundColor: '#fff0f0',
                width: '150px',
                height: '44px',
                margin: '3px',
                ':hover': { backgroundColor: '#fff0f0' },
              }}
              onClick={onClose}
            >
              취소
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'black',
                width: '150px',
                height: '44px',
                margin: '3px',
                ':hover': { backgroundColor: 'black' },
              }}
              onClick={submit}
            >
              송장/인보이스 생성
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}

export const CancelInvoiceModal = ({
  open,
  onClose,
  cancelId,
  orderItems,
  setOrderItems,
}) => {
  const id = cancelId.current
  const findOrder = orderItems.filter((o) => o.id === id)[0]
  const selected = orderItems.filter(
    (o) => o?.shipment?.id === findOrder?.shipment?.id
  )

  const cancelShipmentAPI = async () => {
    if (!id) return

    try {
      await cancelShipment(id)
      const newOrderItems = orderItems.map((order) => {
        if (order?.shipment?.id === findOrder?.shipment?.id) {
          return {
            ...order,
            shipment: null,
            outerShippingStatus: OUTER_SHIPPING_STATUS[2].value,
          }
        } else return order
      })
      cancelId.current = ''
      setOrderItems(newOrderItems)
      onClose()
    } catch (error) {}
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '700px',
          height: '250px',
          bgcolor: 'white',
          borderRadius: '20px',
          boxSizing: 'border-box',
          padding: '25px',
          overflow: 'auto',
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-around"
          height="100%"
        >
          <Typography sx={{ fontSize: 30, fontWeight: 700 }}>
            송장 발송 취소
          </Typography>
          <Typography
            sx={{ fontSize: 20, color: '#707070', textAlign: 'center' }}
          >
            선택하신 상품 외 {selected.length - 1}개의 상품이 송장 발송
            취소됩니다.
            <br />
            그래도 진행 하시겠습니까?
          </Typography>
          <Stack direction="row" gap="10px">
            <Button
              size="large"
              borderradius={2}
              height="40px"
              variant="contained"
              sx={{
                width: 180,
                bgcolor: 'black',
                fontWeight: 700,
                ':hover': {
                  bgcolor: 'black',
                },
              }}
              onClick={onClose}
            >
              뒤로
            </Button>
            <Button
              size="large"
              borderradius={2}
              height="40px"
              variant="contained"
              sx={{
                width: 180,
                bgcolor: '#fff0f0',
                color: '#ff0000',
                fontWeight: 700,
                ':hover': {
                  bgcolor: '#fff0f0',
                },
              }}
              onClick={cancelShipmentAPI}
            >
              취소하기
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
