export const Text = ({
  children,
  color,
  size,
  bold,
  padding,
  margin,
  line,
  cursor,
}) => {
  return (
    <p
      style={{
        cursor: cursor ? 'pointer' : '',
        color: color || 'black',
        fontSize: size || '14px',
        padding,
        margin,
        fontWeight: bold ? 'bold' : '',
        textDecoration: line ? 'underline' : '',
      }}
    >
      {children}
    </p>
  )
}
