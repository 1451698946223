import { instance } from './instance'

export const addShipment = async (body) => {
  try {
    return await instance.post(`/api/dhl/shipments`, body, {
      token: true,
    })
  } catch (error) {
    throw error
  }
}

export const cancelShipment = async (id) => {
  try {
    const body = { orderItemIds: [id] }
    await instance.post(`/api/dhl/cancel-shipments`, body, { token: true })
  } catch (error) {
    throw error
  }
}

export const getInvoice = async (keys) => {
  keys = keys.map((key) => `keys=${key}`).join('&')
  try {
    const { data } = await instance.get(`/api/dhl/invoice?${keys}`, {
      token: true,
    })
    return data.data
  } catch (error) {
    throw error
  }
}
