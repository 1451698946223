import { Divider } from '@mui/material'
import { useRef, useState } from 'react'

import Box from '@mui/material/Box'
import { Button } from '../../element/Button'
import { InputTextField } from '../../element/InputTextField'
import addPhotoIcon from '../../static/add_snap.svg'
import BasicDatePicker from '../common/DataPicker'

import { useNavigate } from 'react-router-dom'
import { getImgUrls } from '../../api/promotion'
import RegisterSeller from '../../pages/promotion/RegisterSeller'
import {
  CampaignType,
  MAX_POST,
  MAX_PRODUCT,
  MAX_TOP_POST,
} from '../../pages/promotion/promoRegister'
import RegisterItemPage from '../../pages/promotion/registerPost'
import RegisterProduct from '../../pages/promotion/registerProduct'
import { CheckSnackbar } from '../Snackbar'
import DragDropItems from '../product/DragDropItems'
import {
  HoverImg,
  LeftBox,
  LeftText,
  OptionContainer,
  PreviewImage,
  boxStyle,
} from './Register'

const GlobalPromotionRegister = ({ campaign, setCampaign, reqPromo, id }) => {
  const navigation = useNavigate()

  const ref = useRef()

  const [onRegister, setOnRegister] = useState(null)

  const registerPromotion = async () => {
    const { success } = await reqPromo({
      promotionId: id,
      ...campaign,
      postIds: campaign.posts.map((p) => p.id),
      topPostIds: campaign.topPosts.map((p) => p.id),
      productIds: campaign.products.map((p) => p.id),
    })
    if (!success) {
      return alert('등록에 실패하였습니다')
    }

    setOpen(true)
    setTimeout(() => {
      navigation('/promo/admin')
    }, 500)
  }

  const promoType = [
    {
      name: '전사프로모션',
      value: CampaignType.Global,
      onClick: () => {
        setCampaign({ ...campaign, type: CampaignType.Global })
      },
    },
    {
      name: '브랜드',
      value: CampaignType.Brand,
      onClick: () => {
        setCampaign({ ...campaign, type: CampaignType.Brand })
      },
    },
    {
      name: '모음전',
      value: CampaignType.Showcase,
      onClick: () => {
        setCampaign({ ...campaign, type: CampaignType.Showcase })
      },
    },
  ]

  const [key, setKey] = useState('')
  const handleImgClick = (key) => {
    ref.current.click()
    setKey(key)
  }

  const handleImgChange = async (event) => {
    if (!event.target.files) {
      return alert('잘못된 이미지 형식입니다.')
    }

    const file = event.target.files[0]

    if (!/\.(jpg|jpeg|png|gif)$/.test(file.name.toLowerCase())) {
      return alert('잘못된 형식의 이미지입니다.')
    }

    const { success, data } = await getImgUrls([file])
    if (!success) {
      return alert('이미지 업로드에 실패하였습니다.')
    }

    const next = {}
    next[key] = data[0]
    setCampaign((prev) => ({ ...prev, ...next }))

    event.target.value = null
  }

  const [open, setOpen] = useState(false)

  return (
    <>
      <CheckSnackbar open={open} setOpen={setOpen} icon={true} />
      {onRegister === 'post' && (
        <RegisterItemPage
          type={onRegister}
          setOnRegister={setOnRegister}
          campaign={campaign}
          setCampaign={setCampaign}
        />
      )}
      {onRegister === 'product' && (
        <RegisterProduct
          type={onRegister}
          setOnRegister={setOnRegister}
          campaign={campaign}
          setCampaign={setCampaign}
        />
      )}
      {onRegister === 'seller' && (
        <RegisterSeller
          type={onRegister}
          setOnRegister={setOnRegister}
          campaign={campaign}
          setCampaign={setCampaign}
        />
      )}

      <Box sx={boxStyle} display={onRegister ? 'none' : ''}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            기본정보 등록
          </p>
        </Box>
        <Divider sx={{ height: 16 }} />
        <OptionContainer>
          <LeftBox>
            <p>기획전 타입 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={1}>
              {promoType.map(({ name, value, onClick }, idx) =>
                value === campaign.type ? (
                  <Button
                    key={idx}
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                ) : (
                  <Button
                    key={idx}
                    color="#707070"
                    bg="white"
                    border="1px solid #e4e4e4"
                    borderRadius="6px"
                    onClick={onClick}
                  >
                    {name}
                  </Button>
                )
              )}
            </Box>
          </Box>
        </OptionContainer>
        <OptionContainer>
          <LeftBox>
            <p>기획전 URL :</p>
          </LeftBox>

          <p>기획전 URL이 자동생성 됩니다.</p>
        </OptionContainer>
        <OptionContainer>
          <LeftBox>
            <p>기획전 기간 :</p>
          </LeftBox>

          <Box>
            <BasicDatePicker date={campaign} setDate={setCampaign} />
          </Box>
        </OptionContainer>
        <input
          type="file"
          accept=".jpg, .jpeg, .png, .gif"
          style={{ display: 'none' }}
          onChange={handleImgChange}
          ref={ref}
        />
        <RegisterImage
          type="thumbImg"
          title="썸네일 이미지"
          campaign={campaign}
          setCampaign={setCampaign}
          handleImgClick={handleImgClick}
          colorValue={campaign.thumbBgColor}
          setColorValue={(e) => {
            if (!isValidHex(e.target.value)) {
              return
            }
            setCampaign((prev) => ({
              ...prev,
              thumbBgColor: e.target.value,
            }))
          }}
        />
        <RegisterImage
          type="topImg"
          title="최상단 이미지"
          campaign={campaign}
          setCampaign={setCampaign}
          handleImgClick={handleImgClick}
          colorValue={campaign.topBgColor}
          setColorValue={(e) => {
            if (!isValidHex(e.target.value)) {
              return
            }
            setCampaign((prev) => ({
              ...prev,
              topBgColor: e.target.value,
            }))
          }}
        />
        <RegisterImage
          type="top1Img"
          title="상단 이미지1"
          campaign={campaign}
          setCampaign={setCampaign}
          handleImgClick={handleImgClick}
          colorValue={campaign.top1BgColor}
          setColorValue={(e) => {
            if (!isValidHex(e.target.value)) {
              return
            }
            setCampaign((prev) => ({
              ...prev,
              top1BgColor: e.target.value,
            }))
          }}
        />
        <RegisterImage
          type="top2Img"
          title="상단 이미지2"
          subTitle="(쿠폰 및 혜택정보)"
          campaign={campaign}
          setCampaign={setCampaign}
          handleImgClick={handleImgClick}
          colorValue={campaign.top2BgColor}
          setColorValue={(e) => {
            if (!isValidHex(e.target.value)) {
              return
            }
            setCampaign((prev) => ({
              ...prev,
              top2BgColor: e.target.value,
            }))
          }}
        />
        {campaign.type === CampaignType.Brand && (
          <OptionContainer>
            <LeftBox ai="start">
              <p>상단 스냅추가 :</p>
            </LeftBox>

            <Box display="flex" flexDirection="column" gap={2}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  gap: 3,
                  color: '#707070',
                }}
              >
                <Button
                  sx={{ marginLeft: '10px' }}
                  bg="white"
                  color="#707070"
                  border="1px solid #e4e4e4"
                  width={100}
                  height={40}
                  fs={14}
                  onClick={() => {
                    setOnRegister('top')
                  }}
                >
                  + 스냅추가
                </Button>

                <p>{MAX_TOP_POST}개 스냅 등록</p>
              </Box>

              <DragDropItems
                width={130}
                height={130}
                items={campaign.topPosts}
                setItems={(items) => {
                  setCampaign({ ...campaign, topPosts: items })
                }}
              />
            </Box>
          </OptionContainer>
        )}

        <OptionContainer>
          <LeftBox ai>
            <p>상품영역 제목 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box margin="0 10px">
              <InputTextField
                width="500px"
                multiline
                value={campaign.productTitle}
                label=""
                InputLabelProps={{
                  style: {
                    fontSize: '14px',
                  },
                }}
                onChange={(e) => {
                  setCampaign({ ...campaign, productTitle: e.target.value })
                }}
              />
            </Box>
          </Box>
        </OptionContainer>
        <OptionContainer>
          <LeftBox ai="start">
            <p>상품 등록 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                gap: 3,
                color: '#707070',
              }}
            >
              <Button
                sx={{ marginLeft: '10px' }}
                bg="white"
                color="#707070"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={14}
                onClick={() => {
                  setOnRegister('product')
                }}
              >
                + 상품추가
              </Button>

              <p>최대 {MAX_PRODUCT}개</p>

              <Button
                sx={{ marginLeft: '10px' }}
                bg="#FFF0F0"
                color="#FF0000"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={14}
                onClick={() => {
                  setCampaign((prev) => ({ ...prev, products: [] }))
                }}
              >
                전체삭제
              </Button>
            </Box>

            <DragDropItems
              width={130}
              items={campaign.products}
              setItems={(items) => {
                setCampaign({ ...campaign, products: items })
              }}
            />
          </Box>
        </OptionContainer>

        <OptionContainer>
          <LeftBox ai>
            <p>스냅영역 제목 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box margin="0 10px">
              <InputTextField
                width="500px"
                multiline
                value={campaign.postTitle}
                label=""
                InputLabelProps={{
                  style: {
                    fontSize: '14px',
                  },
                }}
                onChange={(e) => {
                  setCampaign({ ...campaign, postTitle: e.target.value })
                }}
              />
            </Box>
          </Box>
        </OptionContainer>
        <OptionContainer>
          <LeftBox ai>
            <p>스냅사진 등록 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                gap: 3,
                color: '#707070',
              }}
            >
              <Button
                sx={{ marginLeft: '10px' }}
                bg="white"
                color="#707070"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={14}
                onClick={() => {
                  setOnRegister('post')
                }}
              >
                + 스냅추가
              </Button>

              <Button
                sx={{ marginLeft: '10px' }}
                bg="#FFF0F0"
                color="#FF0000"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={14}
                onClick={() => {
                  setCampaign((prev) => ({ ...prev, posts: [] }))
                }}
              >
                전체삭제
              </Button>

              <p>최대 {MAX_POST}개</p>
            </Box>

            <DragDropItems
              width={130}
              height={130}
              items={campaign.posts}
              setItems={(items) => {
                setCampaign({ ...campaign, posts: items })
              }}
            />
          </Box>
        </OptionContainer>

        {/* <OptionContainer>
          <LeftBox ai>
            <p style={{ fontSize: '13px' }}>브랜드영역 제목 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box margin="0 10px">
              <InputTextField
                width="500px"
                value={campaign.sellerTitle}
                label=""
                InputLabelProps={{
                  style: {
                    fontSize: '14px',
                  },
                }}
                onChange={(e) => {
                  setCampaign({ ...campaign, sellerTitle: e.target.value })
                }}
              />
            </Box>
          </Box>
        </OptionContainer> */}
        {/* <OptionContainer>
          <LeftBox ai="start">
            <p>브랜드 등록 :</p>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                gap: 3,
                color: '#707070',
              }}
            >
              <Button
                sx={{ marginLeft: '10px' }}
                bg="white"
                color="#707070"
                border="1px solid #e4e4e4"
                width={100}
                height={40}
                fs={12}
                onClick={() => {
                  setOnRegister('seller')
                }}
              >
                + 브랜드추가
              </Button>

              <p>최대 10개</p>
            </Box>

            <DragDropItems
              width={130}
              items={campaign.sellers.map((seller) => ({
                ...seller,
                imgs: [seller.profileImg],
              }))}
              setItems={(items) => {
                setCampaign({ ...campaign, sellers: items })
              }}
            />
          </Box>
        </OptionContainer> */}
        <Box marginTop={10} display="flex" gap={3}>
          <Button
            color="#f57979"
            bg="#fff0f0"
            width="80px"
            height="50px"
            borderRadius="10px"
            bold
            onClick={() => {
              navigation('/promo/admin')
            }}
          >
            취소
          </Button>

          <Button
            width="110px"
            height="50px"
            borderRadius="10px"
            bold
            onClick={registerPromotion}
          >
            {id ? '수정하기' : '등록하기'}
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default GlobalPromotionRegister

const UpdateImg = ({ title, onClick, url }) => {
  return (
    <Box display="flex">
      <HoverImg onClick={onClick} alt="" src={addPhotoIcon} />
      <Box display="flex" flexDirection="column" justifyContent="end">
        <LeftText>{title}</LeftText>
        <LeftText>단일 이미지</LeftText>
      </Box>

      {url && url.length > 0 ? (
        <PreviewImage alt="" src={url} />
      ) : (
        <Box width="160px" height="130px" />
      )}
    </Box>
  )
}

const RegisterImage = ({
  type,
  title,
  subTitle,
  handleImgClick,
  colorValue,
  setColorValue,
  campaign,
}) => {
  return (
    <>
      <Box marginY="20px">
        <OptionContainer>
          <LeftBox ai="start">
            <Box>
              <p>{title} :</p>
              {subTitle && <p style={{ fontSize: '12px' }}>{subTitle}</p>}
            </Box>
          </LeftBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box margin="0 10px">
              <InputTextField
                placeholder="배경색상 입력"
                width="300px"
                value={colorValue}
                label=""
                InputLabelProps={{
                  style: {
                    fontSize: '14px',
                  },
                }}
                onChange={setColorValue}
              />
            </Box>

            <Box display="flex" gap={5}>
              <UpdateImg
                title="PC"
                url={campaign[type]}
                onClick={() => {
                  handleImgClick(type)
                }}
              />

              <UpdateImg
                title="MOBILE"
                url={
                  campaign['m' + type.charAt(0).toUpperCase() + type.slice(1)]
                }
                onClick={() => {
                  handleImgClick(
                    'm' + type.charAt(0).toUpperCase() + type.slice(1)
                  )
                }}
              />
            </Box>
          </Box>
        </OptionContainer>
      </Box>
    </>
  )
}

function isValidHex(color) {
  const hexRegex = /^$|^[0-9A-F]{1,6}$/i
  return hexRegex.test(color)
}
