export const ProductSort = {
  ProductNumber: 'PRODUCT_NUMBER',
  ProductSellStatus: 'PRODUCT_SELL_STATUS',
  ProductApproveStatus: 'PRODUCT_APPROVE_STATUS',
  ProductPrice: 'PRODUCT_PRICE',
  ProductSellingPrice: 'PRODUCT_SELLING_PRICE',
  ProductDiscountedPrice: 'PRODUCT_DISCOUNTED_PRICE',
  ProductDiscountedSellingPrice: 'PRODUCT_DISCOUNTED_SELLING_PRICE',
  ProductReviewsCnt: 'PRODUCT_REVIEWS_CNT',
  ProductCreatedAt: 'PRODUCT_CREATED_AT',
  ProductDiscountRate: 'PRODUCT_DISCOUNT_RATE',
}
